import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import SponsorFrontServiceCard from './sponsor/components/SponsorFrontServiceCard';
import SelectableList from './sponsor/components/SelectableList';
import ServiceSummaryBuyCardContent from './sponsor/components/ServiceSummaryBuyCardContent';
import { appState } from '@aim/common';

import { usePriceRanges } from './shared/priceRangeGqlHelper';
import { useSponsors } from './shared/sponsorGqlHelper';

import CircularProgress from '@material-ui/core/CircularProgress';

import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';

import {
  AimDialog,
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
  // theme,
} from '@aim/components';

import translation from './shared/translation';
import { isValid, parse } from 'date-fns';

const selectedStyle = ({ currentEventConfiguration }) => {
  return {
    border: `1.5px solid ${
      currentEventConfiguration && currentEventConfiguration.primaryColor
        ? currentEventConfiguration.primaryColor
        : 'black'
    }`,
    borderRadius: 10,
    // backgroundColor: theme.colors.greyScale.grey5,
  };
};

const PriceRangesList = () => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.priceRangeList(intl);

  const { list } = usePriceRanges();
  const { update } = useSponsors();

  //States
  const [eventPriceRanges, setEventPriceRanges] = useState([]);
  const [selected, setSelected] = useState();
  // const [user, setUser] = useState();
  const [participation, setParticipation] = useState();
  const [currentEventConfiguration, setCurrentEventConfiguration] = useState();
  const [currentPriceRange, setCurrentPriceRange] = useState(0);
  const [selectedPriceRange, setSelectedPriceRange] = useState(0);
  const [selectPriceRangeDialog, setSelectPriceRangeDialog] = useState({
    isOpen: false,
  });

  //Effects
  // useEffect(() => {
  //   const userSubscription = appState.user.subscribe(async (user) => {
  //     setUser(user);
  //     setParticipation(user?.userAndParticipation?.participation);
  //   });

  //   return () => {
  //     userSubscription.unsubscribe();
  //   };
  // }, []);

  useEffect(() => {
    getParticipation();
  }, []);

  useEffect(() => {
    if (participation) {
      fetchSponsorPriceRanges();
    }
  }, [participation]);

  useEffect(() => {
    const eventConfiguration = appState.eventConfiguration.subscribe(
      (_eventConfiguration) => {
        setCurrentEventConfiguration(_eventConfiguration);
      }
    );

    return () => {
      eventConfiguration.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (participation) {
      setCurrentPriceRange(participation?.sponsor?.priceRange?.id);
      setSelected(participation?.sponsor?.priceRange?.id);
      setSelectedPriceRange(participation?.sponsor?.priceRange?.price || 0);
    }
  }, [participation]);

  //Functions
  const getParticipation = async () => {
    setParticipation(
      appState.user.getValue()?.userAndParticipation?.participation
    );
    // setUser(appState.user.getValue());
  };

  const fetchSponsorPriceRanges = async () => {
    try {
      const response = await list(eventId);

      const priceRangeUnsorted = response.sponsorPriceRanges.items;
      if (priceRangeUnsorted?.length === 0) {
        // issue reference #344
        history.push(`/events/${eventId}/${sponsorId}/my-services`);
      } else {
        const priceRangeSorted = priceRangeUnsorted
          .map((x) => ({
            ...x,
            selected: participation?.sponsor?.priceRange?.id === x.id,
          }))
          .filter((pr) => isValid(parse(pr.start, 'yyyy-MM-dd', new Date())))
          .sort((a, b) => (a.price < b.price ? -1 : 1))
          .reverse();

        setEventPriceRanges(priceRangeSorted);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onClickSelect = () => {
    setSelectPriceRangeDialog({ isOpen: true });
  };

  const onClickConfirmSelect = async () => {
    if (selected !== participation?.sponsor?.priceRange?.id) {
      //set price range to sponsor
      const input = {
        id: participation?.sponsor?.id,
        sponsorPriceRangeId: selected,
      };
      await update(input);
      if (participation) {
        const nextUserAndParticipation = {
          ...appState.user.getValue()?.userAndParticipation,
          participation: {
            ...participation,
            sponsor: {
              ...participation?.sponsor,
              priceRange: {
                ...participation?.sponsor?.priceRange,
                id: selected,
              },
            },
          },
        };
        appState.user.next({
          ...appState.user.getValue(),
          userAndParticipation: nextUserAndParticipation,
        });
      }
    }

    history.push(`/events/${eventId}/${sponsorId}/my-services`);
  };

  const selectedConditionBordered = (id) => id === selected?.id;

  const ItemList = ({
    name,
    id,
    start,
    price,
    description,
    backgroundColor,
    frontendNameColor,
    freeStaffMembers,
    disabled,
    selected,
  }) => {
    return (
      <SponsorFrontServiceCard
        title={name}
        bordered={selectedConditionBordered(id)}
        showCardContent
        buttonBackgroundColor={backgroundColor}
        buttonTextColor={frontendNameColor}
        disabled={disabled}
        selected={selected}
      >
        <ServiceSummaryBuyCardContent
          i18n={i18n}
          title={name}
          day={start}
          price={Number(price / 100).toFixed(2)}
          description={description}
          freeStaffMembers={freeStaffMembers}
        />
      </SponsorFrontServiceCard>
    );
  };

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        {eventPriceRanges ? (
          <>
            <div
              style={{ display: 'flex-root', marginLeft: 15, marginTop: 20 }}
            >
              <AimTypography variant="h1" margin={0}>
                {i18n.page.subtitle.label}
              </AimTypography>
            </div>
            <div
              style={{ display: 'flex-root', marginLeft: 15, marginTop: 10 }}
            >
              <AimTypography variant="text" margin={0}>
                {i18n.page.description.label}
              </AimTypography>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <SelectableList
                // Component={ItemList}
                {...{
                  setSelected,
                  selected,
                  // defaultStyle: {
                  //   backgroundColor: theme.colors.greyScale.backgroundGrey,
                  // },
                  selectedStyle: selectedStyle({ currentEventConfiguration }),
                  Component: ItemList,
                  items: eventPriceRanges.map((x) => ({
                    ...x,
                    disabled: x.price <= selectedPriceRange,
                    // selected: selected === x.id,
                  })),
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <AimIconAndTextButton
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
                // variant="yellow"
                text={i18n.page.forwardButton.label}
                onClick={() => onClickSelect()}
                disabled={!selected || selected === currentPriceRange}
              />
            </div>
            <AimDialog
              open={selectPriceRangeDialog?.isOpen}
              title={i18n.page.selectDialogTitle.label}
              disableEnforceFocus
              onClose={() => {
                setSelectPriceRangeDialog({ isOpen: false });
              }}
              onAgree={onClickConfirmSelect}
              agreeText={i18n.page.selectDialogContinueButtonText.label}
            >
              {i18n.page.selectDialogMessage.label}
            </AimDialog>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </div>
        )}
      </InnerContainer>
    </MainContainer>
  );
};

export default PriceRangesList;
