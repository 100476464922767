import PromisePool from '@supercharge/promise-pool';
import { aws, constants, massiveQueriesHelper, utilities } from '@aim/common';
// import { sortBy } from 'lodash';

const getEventQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      organizer
      venue
      country
      city
      initials
      type
      isOwnedByCongress
      industryText
      topicText
      taxRepresentative {
        id
      }
      language {
        id
        name
      }
      isScientificCommitteeEnabled
      isFacultyPageEnabled
      isEditAbstractAfterSubmissionEnabled
      maxParticipants
      timezone
      isInsertControlCodeInANewUserEnabled
      controlCodeCode
      controlCodeLabel
      byInvitationOnlyFromEnabled
      byInvitationOnlyFromDate
      editPersonalDetailsType
      availableServices
      eventCategory
    }
  }
`;

const updateEventMutation = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventPreTitlesQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      preTitles {
        items {
          isActive
          key
          preTitle
          title
        }
      }
    }
  }
`;

const getEventTypologysQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      participationTypologies {
        items {
          name
          createdBy
        }
      }
    }
  }
`;

const getEventEmailAndLettersQuery = (queryType) => /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      templates: ${queryType} {
        items {
          name
          type
          subject
          isActive
          body
          greetings
          trigger
          isAdditionalServicesAttachmentIncluded
          isAllotmentAttachmentIncluded
          isDeletable
          isRegistrationAttachmentIncluded
          isTravelAttachmentIncluded
          fieldMapping
          delegation {
            closingDate
            customer
            description
            name
          }
        }
      }
    }
  }
`;

const getEventLetterLayoutTemplateQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      letterLayoutTemplate {
        name
        id
      }
    }
  }
`;

const createPreTitleMutation = /* GraphQL */ `
  createPreTitle(input: $input) {
    id
  }
`;

const createParticipationTypology = /* GraphQL */ `
  mutation CreateParticipationTypology(
    $input: CreateParticipationTypologyInput!
    $condition: ModelParticipationTypologyConditionInput
  ) {
    createParticipationTypology(input: $input, condition: $condition) {
      id
    }
  }
`;

const createEmailAndLetterMutation = /* GraphQL */ `
  mutation CreateEmailTemplate(
    $input: CreateEmailTemplateInput!
    $condition: ModelEmailTemplateConditionInput
  ) {
    createEmailTemplate(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventDelegationsQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      delegations {
        items {
          id
          name
          customer
          description
          eventOrder {
            id
            code
            codeName
            isDeleted
          }
        }
      }
    }
  }
`;

const getEventServiceIdByServiceNameQuery = /* GraphQL */ `
  query GetEvent($id: ID!, $serviceName: String) {
    getEvent(id: $id) {
      services(filter: { serviceName: { eq: $serviceName } }) {
        items {
          serviceName
          id
        }
      }
    }
  }
`;

const createEventOrderMutation = /* GraphQL */ `
  mutation CreateEventOrder(
    $input: CreateEventOrderInput!
    $condition: ModelEventOrderConditionInput
  ) {
    createEventOrder(input: $input, condition: $condition) {
      id
      event {
        id
      }
      delegation {
        id
      }
    }
  }
`;

const createDelegationMutation = /* GraphQL */ `
  mutation CreateDelegation(
    $input: CreateDelegationInput!
    $condition: ModelDelegationConditionInput
  ) {
    createDelegation(input: $input, condition: $condition) {
      id
      name
      event {
        id
        eventOrder {
          items {
            id
            code
            codeName
          }
        }
      }
    }
  }
`;

const updateDelegationQuery = /* GraphQL */ `
  mutation UpdateDelegation(
    $input: UpdateDelegationInput!
    $condition: ModelDelegationConditionInput
  ) {
    updateDelegation(input: $input, condition: $condition) {
      id
    }
  }
`;

const createFieldContextQuery = /* GraphQL */ `
  mutation CreateFieldContext($input: CreateFieldContextInput!) {
    createFieldContext(input: $input) {
      id
      position
      contextName
      isRequired
      isHidden
    }
  }
`;

const createFieldDefinitionQuery = /* GraphQL */ `
  mutation CreateFieldDefinition($input: CreateFieldDefinitionInput!) {
    createFieldDefinition(input: $input) {
      id
      key
      controlType
      label
      placeholder
      options
      isDeleted
    }
  }
`;

const getFieldsListByContextsQuery = /* GraphQL */ `
  query getFieldsListByContexts(
    $id: ID!
    $filter: ModelFieldContextFilterInput
    $serviceName: String
  ) {
    getEvent(id: $id) {
      standardFields {
        items {
          key
          controlType
          id
          isDeleted
          label
          defaultValue
          options
          placeholder
          contextsOfUse(filter: $filter) {
            items {
              id
              position
              contextName
              isRequired
              isHidden
            }
          }
        }
      }
      services(filter: { serviceName: { eq: $serviceName } }) {
        items {
          serviceName
          id
          customFields(filter: { isDeleted: { ne: false } }) {
            items {
              controlType
              id
              key
              isDeleted
              label
              defaultValue
              options
              placeholder
              contextsOfUse(filter: $filter) {
                items {
                  id
                  position
                  contextName
                  isRequired
                  isHidden
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getEventAllotmentInfoQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      allotmentVat
      allotmentCurrency
      allotmentAimCommission
      allotmentClientCommission
      allotmentEventVat {
        id
      }
      allotmentDateRange {
        start
        end
      }
    }
  }
`;

const listHotelsQuery = /* GraphQL */ `
  query ListEventHotelQuery($eventId: ID!) {
    getEvent(id: $eventId) {
      hotelEventVentures: hotels {
        items {
          userWhoDidImportThisType
          deadline
          hotelRoomByDate {
            items {
              date
              availableRoomCount
              room {
                id
              }
            }
          }
          referent {
            givenName
            familyName
            email
            phone
          }
          hotel {
            id
          }
        }
      }
    }
  }
`;

const createHotelEventVentureMutation = /* GraphQL */ `
  mutation CreateHotelEventVenture(
    $input: CreateHotelEventVentureInput!
    $condition: ModelHotelEventVentureConditionInput
  ) {
    createHotelEventVenture(input: $input, condition: $condition) {
      id
    }
  }
`;

const createHotelRoomByDateMutation = /* GraphQL */ `
  mutation CreateHotelRoomByDate($input: CreateHotelRoomByDateInput!) {
    createHotelRoomByDate(input: $input) {
      id
    }
  }
`;

const createHotelReferentMutation = /* GraphQL */ `
  mutation CreateHotelReferent(
    $input: CreateHotelReferentInput!
    $condition: ModelHotelReferentConditionInput
  ) {
    createHotelReferent(input: $input, condition: $condition) {
      id
    }
  }
`;

const createEventMutation = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
    }
  }
`;

const createEventServiceQuery = /* GraphQL */ `
  mutation CreateEventService($input: CreateEventServiceInput!) {
    createEventService(input: $input) {
      id
    }
  }
`;

const createAbstractServiceQuery = /* GraphQL */ `
  mutation CreateAbstractService(
    $input: CreateAbstractServiceInput!
    $condition: ModelAbstractServiceConditionInput
  ) {
    createAbstractService(input: $input, condition: $condition) {
      id
    }
  }
`;

const eventOrderCreateMutation = /* GraphQL */ `
  mutation CreateEventOrder(
    $input: CreateEventOrderInput!
    $condition: ModelEventOrderConditionInput
  ) {
    createEventOrder(input: $input, condition: $condition) {
      id
    }
  }
`;

const listEventDelegations = /* GraphQL */ `
  query GetEventDelegations($id: ID!) {
    getEvent(id: $id) {
      delegations {
        items {
          id
          name
        }
      }
    }
  }
`;
const listEventStandardFieldsQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      standardFields {
        items {
          id
          key
          controlType
          label
          placeholder
          options
        }
      }
    }
  }
`;

const deleteEventMutation = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteEvent = (eventId) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: deleteEventMutation,
      variables: { input: { id: eventId } },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.deleteEvent);
      })
      .catch((e) => {
        console.error('delete-event-mutation', e);
        reject();
      });
  });

const getDelegations = (eventId) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: listEventDelegations,
      variables: { id: eventId },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.getEvent.delegations.items);
      })
      .catch((e) => {
        console.error('list-event-delegations', e);
        reject();
      });
  });

const listEventStandardFields = (eventId) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: listEventStandardFieldsQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.getEvent.standardFields.items);
      })
      .catch((e) => {
        console.error('list-event-standard-fields', e);
        reject();
      });
  });

const eventOrderCreate = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: eventOrderCreateMutation,
      variables: { input },
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        console.error('create-event-order-event', e);
        reject(e);
      });
  });

const eventOrder = async ({ orderCode, newEventId }) => {
  try {
    const eventOrderList = [
      'event',
      'allotment',
      'travel',
      'registration',
      'services',
    ];

    const results = await PromisePool.for(eventOrderList)
      .withConcurrency(10)
      .handleError(async (error) => {
        console.error('💥 ERROR: ', error);
        throw error;
      })
      .process(async (codeName) => {
        const input = {
          codeName: codeName,
          code: orderCode,
          eventOrderEventId: newEventId,
        };

        return await eventOrderCreate(input);
      });

    return results;
  } catch (error) {
    console.error('Error', error);
    throw error;
  }
};

const createEvent = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createEventMutation,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.createEvent);
      })
      .catch((error) => {
        console.error('Error creating event', input, error);
        reject(error);
      });
  });

const updateEvent = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: updateEventMutation,
      variables: {
        input,
      },
    })
      .then((response) => {
        resolve(response.data.updateEvent);
      })
      .catch((error) => {
        console.error('Error updating event', input, error);
        reject(error);
      });
  });

const getEvent = (eventId) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: getEventQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        resolve(response.data.getEvent);
      })
      .catch((error) => {
        console.error('Error event', eventId, error);
        reject(error);
      });
  });

const getEventPreTitles = (eventId) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: getEventPreTitlesQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        resolve(response.data.getEvent.preTitles.items);
      })
      .catch((error) => {
        console.error('Error event', eventId, error);
        reject(error);
      });
  });

const copyTitles = async ({ oldEventId, newEventId }) => {
  try {
    const oldEventPreTitles = await getEventPreTitles(oldEventId);

    return await massiveQueriesHelper.massiveMutation({
      query: createPreTitleMutation,
      queryName: 'CreateEventPreTitles',
      items: oldEventPreTitles.map((item) => ({
        key: item.key,
        title: item.title,
        preTitle: item.preTitle,
        eventPreTitlesId: newEventId,
        isActive: item.isActive,
      })),
      queryInputType: 'CreatePreTitleInput',
    });
  } catch (error) {
    console.error('Error', error);
    throw error;
  }
};

const createTypologies = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createParticipationTypology,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error('Error creating typology', input, error);
        reject(error);
      });
  });

const getEventTypologys = (eventId) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: getEventTypologysQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        resolve(response.data.getEvent);
      })
      .catch((error) => {
        console.error('Error event', eventId, error);
        reject(error);
      });
  });

const copyTypologies = async ({ oldEventId, newEventId }) => {
  try {
    const oldEventData = await getEventTypologys(oldEventId);
    const oldEventTypologies = oldEventData.participationTypologies.items;

    const typologiesToCopy = oldEventTypologies.filter(
      (typology) => typology.createdBy === 'travel'
    );

    const results = await PromisePool.for(typologiesToCopy)
      .withConcurrency(10)
      .handleError(async (error) => {
        console.error('💥 ERROR: ', error);
        throw error;
      })
      .process(async (items) => {
        const input = {
          name: items.name,
          createdBy: items.createdBy,
          participationTypologyEventId: newEventId,
        };

        return await createTypologies(input);
      });

    return results;
  } catch (error) {
    console.error('Error', error);
    throw error;
  }
};

const createEmailAndLetter = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createEmailAndLetterMutation,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error('Error creating', input, error);
        reject(error);
      });
  });

const getEventEmailAndLetters = (eventId, queryType) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: getEventEmailAndLettersQuery(queryType),
      variables: { id: eventId },
    })
      .then((response) => {
        resolve(response.data.getEvent.templates.items);
      })
      .catch((error) => {
        console.error('Error event', eventId, error);
        reject(error);
      });
  });

const getLetterLayoutTemplate = async (eventId) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: getEventLetterLayoutTemplateQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        resolve(response.data.getEvent?.letterLayoutTemplate?.id);
      })
      .catch((error) => {
        console.error('Error event', eventId, error);
        reject(error);
      });
  });

const copyEmailTemplateAndLetter = async ({
  oldEventId,
  newEventId,
  letterTemplates,
  fieldDefinitionsIdMap,
}) => {
  try {
    const queryType = letterTemplates ? 'letterTemplates' : 'emailTemplates';
    const templates = await getEventEmailAndLetters(oldEventId, queryType);
    const newDelegations = await getDelegations(newEventId);

    const results = await PromisePool.for(templates)
      .withConcurrency(10)
      .handleError(async (error) => {
        console.error('💥 ERROR: ', error);
        throw error;
      })
      .process(async (template) => {
        const parsedFieldMapping = template.fieldMapping
          ? JSON.parse(template.fieldMapping)
          : undefined;

        const nextFieldMapping = parsedFieldMapping
          ? Object.entries(parsedFieldMapping).reduce((prev, [key, value]) => {
              prev[key] = Object.entries(value).reduce(
                (prevValue, [currKey, currValue]) => {
                  if (currValue.type === 'required') {
                    prevValue[currKey] = currValue;
                  } else {
                    prevValue[currKey] = {
                      ...currValue,
                      value: fieldDefinitionsIdMap[currValue.value],
                    };
                  }
                  return prevValue;
                },
                {}
              );
              return prev;
            }, {})
          : undefined;

        console.log('nextFieldMapping', nextFieldMapping);

        const input = {
          isActive: template.isActive,
          name: template.name,
          emailTemplateDelegationId: template.delegation
            ? newDelegations.find((d) => template.delegation.name === d.name)
                ?.id
            : undefined,
          body: template.body,
          ...(letterTemplates
            ? { emailTemplateLetterEventId: newEventId }
            : { emailTemplateEventId: newEventId }),
          fieldMapping: JSON.stringify(nextFieldMapping),
        };

        if (template.greetings) input.greetings = template.greetings;
        if (template.type) input.type = template.type;
        if (template.trigger) input.trigger = template.trigger;
        if (template.subject) input.subject = template.subject;
        if (template.isRegistrationAttachmentIncluded)
          input.isRegistrationAttachmentIncluded =
            template.isRegistrationAttachmentIncluded;
        if (template.isAllotmentAttachmentIncluded)
          input.isAllotmentAttachmentIncluded =
            template.isAllotmentAttachmentIncluded;
        if (template.isAdditionalServicesAttachmentIncluded)
          input.isAdditionalServicesAttachmentIncluded =
            template.isAdditionalServicesAttachmentIncluded;
        if (template.isTravelAttachmentIncluded)
          input.isTravelAttachmentIncluded =
            template.isTravelAttachmentIncluded;

        return await createEmailAndLetter(input);
      });

    return results;
  } catch (error) {
    console.error('Error', error);
    throw error;
  }
};

const copyLetterLayoutTemplate = async ({ oldEventId, newEventId }) => {
  // Get letter layout template id from old event
  const letterLayoutTemplateId = await getLetterLayoutTemplate(oldEventId);
  // Update copied event with eventLetterLayoutTemplateId
  if (letterLayoutTemplateId) {
    const input = {
      id: newEventId,
      eventLetterLayoutTemplateId: letterLayoutTemplateId,
    };
    await updateEvent(input);
  }
};

const getEventDelegations = (eventId) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: getEventDelegationsQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        console.log('getEventDelegations response', response.data.getEvent);
        resolve(response.data.getEvent.delegations?.items);
      })
      .catch((error) => {
        console.error('get-event-delegations-to-copy', eventId, error);
        reject(error);
      });
  });

const getEventServiceIdByServiceName = ({ eventId, serviceName }) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: getEventServiceIdByServiceNameQuery,
      variables: { id: eventId, serviceName },
    })
      .then((response) => {
        console.log(
          'getEventServiceIdByServiceName response',
          response.data.getEvent
        );
        resolve(response.data.getEvent.services?.items[0]?.id);
      })
      .catch((error) => {
        console.error('get-event-seviceId-by-servceName', eventId, error);
        reject(error);
      });
  });

const createEventOrder = ({ data }) =>
  new Promise((resolve, reject) => {
    const delegation = data.createDelegation;
    const item = delegation.event.eventOrder?.items?.filter(
      (el) => el?.codeName === 'event' && el?.code
    );

    const input = {
      code: item?.[0]?.code || undefined,
      codeName: delegation.name,
      eventOrderDelegationId: delegation.id,
      eventOrderEventId: delegation.event.id,
    };

    aws.API.graphql({
      query: createEventOrderMutation,
      variables: { input },
    })
      .then(({ data }) => resolve(data))
      .catch((e) => {
        console.error('create-event-order', e);
        reject(e);
      });
  });

const updateDelegation = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: updateDelegationQuery,
      variables: { input },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.updateDelegation);
      })
      .catch((e) => {
        console.error('delegation-update', e);
        reject();
      });
  });

const createDelegation = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createDelegationMutation,
      variables: { input },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.createDelegation);
        Promise.all([createEventOrder(response)]).then(([res]) => {
          updateDelegation(
            {
              id: data?.createDelegation?.id,
              delegationEventOrderId: res?.createEventOrder?.id,
            },
            false
          );
        });
      })
      .then(resolve)
      .catch((e) => {
        console.error('delegation-create', e);
        reject();
      });
  });

const getFieldsListByContexts = ({ eventId, contextsOfUse, serviceName }) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: getFieldsListByContextsQuery,
      variables: {
        id: eventId,
        filter: { or: contextsOfUse.map((c) => ({ contextName: { eq: c } })) },
        serviceName,
      },
    })
      .then((response) => {
        const out = {
          standardFields: response.data.getEvent.standardFields.items,
          customFields:
            response.data.getEvent.services.items[0].customFields.items,
          serviceId: response.data.getEvent.services.items[0].id,
        };
        resolve(out);
      })
      .catch((e) => {
        console.error('list-event-field-to-copy', e);
        reject();
      });
  });

const createFieldDefinition = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createFieldDefinitionQuery,
      variables: { input },
    })
      .then((response) => resolve(response.data.createFieldDefinition.id))
      .catch((e) => {
        console.error('create-field-definition', e);
        reject();
      });
  });

const createFieldContext = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createFieldContextQuery,
      variables: { input },
    })
      .then((response) => resolve(response.data.createFieldContext))
      .catch((e) => {
        console.error('create-field-context', e);
        reject();
      });
  });

const unionToContextOfUse = (fields, contexts) => {
  const outFields = fields.map((field) => {
    let fieldFirstContextOfUse;
    for (let i = 0; i < contexts.length; i++) {
      fieldFirstContextOfUse = field.contextsOfUse.items.find(
        (cu) => cu.contextName === contexts[i]
      );
      if (fieldFirstContextOfUse) break;
    }
    return {
      ...field,
      key: field.key || field.id,
      options: field.options,
      contextsOfUse: {
        ...fieldFirstContextOfUse,
        isVisible: !fieldFirstContextOfUse.isHidden,
      },
      isReadOnly:
        fieldFirstContextOfUse.contextName !== contexts[contexts.length - 1],
    };
  });
  return outFields;
};

const createAnagraphicPromises = async ({
  oldEventId,
  newEventId,
  serviceId,
  serviceName,
  contexts,
  oldContextName,
  newContextName,
  fieldDefinitionsIdMap,
}) => {
  // const readOnlyStandardFields = standardFields.filter((f) => f.isReadOnly);
  // const readOnlyCustomFields = customFields.filter((f) => f.isReadOnly);

  const fieldsListByContext = await getFieldsListByContexts({
    eventId: oldEventId,
    contextsOfUse: contexts,
    serviceName,
  });

  // get standardFields, customFields, serviceId
  const { standardFields, customFields } = fieldsListByContext;

  const nextStandardFields = standardFields.filter(
    (field) => field.contextsOfUse.items.length
  );
  const nextCustomFields = customFields.filter(
    (field) => field.contextsOfUse.items.length
  );

  const outStandardFields = unionToContextOfUse(nextStandardFields, contexts);
  const outCustomFields = unionToContextOfUse(nextCustomFields, contexts);

  // const nextStandardFields = sortBy(
  //   outStandardFields.filter((f) => !f.isReadOnly),
  //   ['contextsOfUse.position']
  // );
  // const nextCustomFields = sortBy(
  //   outCustomFields.filter((f) => !f.isReadOnly),
  //   ['contextsOfUse.position']
  // );

  let contextName = oldContextName;
  if (oldContextName !== newContextName && newContextName.split('|').length) {
    contextName = newContextName;
  }

  await PromisePool.for(outStandardFields)
    .withConcurrency(10)
    .handleError(async (error) => {
      console.error('💥 ERROR: ', error);
      throw error;
    })
    .process(async (sf) => {
      const {
        contextsOfUse: { isVisible, ...rest },
        ...field
      } = sf;

      // delete rest.id;
      await createFieldContext(
        {
          // ...rest,
          fieldDefinitionContextsOfUseId: fieldDefinitionsIdMap[sf.id],
          isRequired: rest.isRequired,
          isHidden: !isVisible,
          contextName, // `delegations|${delegationId}`,
          position: rest.position,
          // position: readOnlyStandardFields.length + index,
        },
        false
      );
    });

  // for (const sf of outStandardFields) {
  // }
  // for (const cf of outCustomFields) {
  // }
  await PromisePool.for(outCustomFields)
    .withConcurrency(10)
    .handleError(async (error) => {
      console.error('💥 ERROR: ', error);
      throw error;
    })
    .process(async (cf) => {
      const {
        contextsOfUse: { isVisible, ...rest },
        ...field
      } = cf;
      // delete field.id;
      const newFieldId = await createFieldDefinition(
        {
          // ...field,
          eventServiceCustomFieldsId: serviceId,
          label: field.label,
          placeholder: field.placeholder,
          controlType: field.controlType,
          options: field.options,
        },
        false
      );

      fieldDefinitionsIdMap[cf.id] = newFieldId;

      // delete rest.id;
      await createFieldContext(
        {
          // ...rest,
          fieldDefinitionContextsOfUseId: newFieldId,
          isRequired: rest.isRequired,
          isHidden: !isVisible,
          // isHidden: rest.isHidden,
          contextName, // `delegations|${delegationId}`, // rest.contextName.
          position: rest.position,
          // position: readOnlyCustomFields.length + index,
        },
        false
      );
    });
};

const copyPaxAnagraphic = async ({
  oldEventId,
  newEventId,
  fieldDefinitionsIdMap,
}) => {
  const serviceId = await getEventServiceIdByServiceName({
    eventId: newEventId,
    serviceName: constants.Services.PARTICIPATION.key,
  });

  const contexts = utilities.getContextsOfUsePerCluster({
    cluster: 'pax',
  });

  await createAnagraphicPromises({
    newEventId,
    oldEventId,
    serviceId,
    serviceName: constants.Services.PARTICIPATION.key,
    contexts,
    oldContextName: 'pax',
    newContextName: 'pax',
    fieldDefinitionsIdMap,
  });
};

const copyDelegations = async ({
  oldEventId,
  newEventId,
  fieldDefinitionsIdMap,
}) => {
  try {
    const delegationsToCopy = await getEventDelegations(oldEventId);
    const serviceId = await getEventServiceIdByServiceName({
      eventId: newEventId,
      serviceName: constants.Services.PARTICIPATION.key,
    });

    for (const delegation of delegationsToCopy) {
      const input = {
        name: delegation.name,
        customer: delegation.customer,
        description: delegation.description,
        delegationEventId: newEventId,
      };
      const delegationResponse = await createDelegation(input);

      // standards fields and custom fields
      const contexts = utilities.getContextsOfUsePerCluster({
        cluster: 'delegations',
        clusterId: delegation.id,
        excludePaxAnagraphicFields: true,
      });

      await createAnagraphicPromises({
        newEventId,
        oldEventId,
        serviceId,
        serviceName: constants.Services.PARTICIPATION.key,
        contexts,
        oldContextName: `delegations|${delegation.id}`,
        newContextName: `delegations|${delegationResponse.id}`,
        fieldDefinitionsIdMap,
      });
    }

    console.log('copy of the delegations, done');

    // return results;
  } catch (error) {
    console.error('Error', error);
    throw error;
  }
};

const getEventAllotmentInfo = (eventId) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: getEventAllotmentInfoQuery,
      variables: { id: eventId },
    })
      .then(async (response) => {
        resolve(response?.data?.getEvent);
      })
      .catch((e) => {
        console.error('get-allotment-nights', e);
        reject();
      });
  });

const listEventHotel = (eventId) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: listHotelsQuery,
      variables: { eventId },
    })
      // .then((response) => resolve(response?.data))
      .then((response) =>
        resolve(response?.data?.getEvent.hotelEventVentures?.items)
      )
      .catch((e) => {
        console.error('allotment-list-event-hotel', e);
        reject();
      });
  });

const createHotelEventVenture = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createHotelEventVentureMutation,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.createHotelEventVenture);
      })
      .catch((error) => {
        console.error('Error creating hotel event venture', input, error);
        reject(error);
      });
  });

const createHotelRoomByDate = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createHotelRoomByDateMutation,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.createHotelRoomByDate);
      })
      .catch((error) => {
        console.error('Error creating hotel room by date', input, error);
        reject(error);
      });
  });

const createReferent = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createHotelReferentMutation,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.createHotelReferent);
      })
      .catch((error) => {
        console.error('Error creating referent', input, error);
        reject(error);
      });
  });

const createHotelEventVentures = async ({ eventId, hotelEventVentures }) => {
  const hotelEventVenturesCreated = await PromisePool.for(hotelEventVentures)
    .withConcurrency(10)
    .handleError(async (error) => {
      console.error('💥 ERROR: ', error);
      // throwing errors will stop PromisePool and you must catch them yourself
      throw error;
    })
    .process(async (hev) => {
      console.log('hev ', hev);
      const referentResult = await createReferent({
        givenName: hev.referent.givenName,
        familyName: hev.referent.familyName,
        email: hev.referent.email,
        phone: hev.referent.phone,
        hotelEventVentureReferentId: hev.id,
      });
      const hevResult = await createHotelEventVenture({
        userWhoDidImportThisType: hev.userWhoDidImportThisType,
        deadline: hev.deadline,
        eventId: eventId,
        hotelEventVentureEventId: eventId,
        hotelId: hev.hotel.id,
        hotelEventVentureHotelId: hev.hotel.id,
        hotelEventVentureReferentId: referentResult.id,
      });

      const hotelRoomByDateResult = [];
      for (const hrd of hev.hotelRoomByDate.items) {
        const input = {
          date: hrd.date,
          availableRoomCount: hrd.availableRoomCount,
          hotelRoomByDateHotelEventVentureId: hevResult.id,
          hotelRoomByDateRoomId: hrd.room.id,
        };
        const hrdResult = await createHotelRoomByDate(input);
        hotelRoomByDateResult.push(hrdResult);
      }

      return { hevResult, referentResult, hotelRoomByDateResult };
    });
  return hotelEventVenturesCreated;
};

const copyAllotment = async ({ oldEventId, newEventId }) => {
  const resEventAllotmentInfo = await getEventAllotmentInfo(oldEventId);

  const { start, end } = resEventAllotmentInfo?.allotmentDateRange;
  const input = {
    id: newEventId,
    allotmentVat: resEventAllotmentInfo.allotmentVat,
    allotmentCurrency: resEventAllotmentInfo.allotmentCurrency,
    allotmentAimCommission: resEventAllotmentInfo.allotmentAimCommission,
    allotmentClientCommission: resEventAllotmentInfo.allotmentClientCommission,
    allotmentDateRange: { start, end },

    eventAllotmentEventVatId: resEventAllotmentInfo?.allotmentEventVat?.id,
  };
  const resUpdateEvent = await updateEvent(input);
  console.log('resUpdateEvent ', resUpdateEvent);

  const resListEventHotel = await listEventHotel(oldEventId);
  console.log('resListEventHotel ', resListEventHotel);

  const resCreateHotelEventVentures = await createHotelEventVentures({
    eventId: newEventId,
    hotelEventVentures: resListEventHotel,
  });
  console.log('resCreateHotelEventVentures ', resCreateHotelEventVentures);
};

const createEventService = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createEventServiceQuery,
      variables: { input },
    })
      .then((response) => resolve(response.data.createEventService))
      .catch((e) => {
        console.error('create-event-service', e);
        reject();
      });
  });

const createEventServices = async (event) => {
  const servicesCreated = await PromisePool.for(
    Object.values(constants.Services) || []
  )
    .withConcurrency(10)
    .handleError(async (error) => {
      console.error('💥 ERROR: ', error);
      // throwing errors will stop PromisePool and you must catch them yourself
      throw error;
    })
    .process(async (service) => {
      const serviceResult = await createEventService({
        eventServicesId: event.id,
        serviceName: service.key,
      });
      return { key: service.key, id: serviceResult?.id };
    });
  return servicesCreated.results;
};

const createAbstractService = (id) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createAbstractServiceQuery,
      variables: { input: { abstractServiceEventId: id } },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.createAbstractService.id);
      })
      .catch((e) => {
        console.error('create-abstract-service', e);
        reject();
      });
  });

const copyStandardFields = async ({
  oldEventId,
  newEventId,
  fieldDefinitionsIdMap,
}) => {
  const standardFields = await listEventStandardFields(oldEventId);
  return await PromisePool.for(standardFields || [])
    .withConcurrency(10)
    .handleError(async (error) => {
      console.error('💥 ERROR: ', error);
      // throwing errors will stop PromisePool and you must catch them yourself
      throw error;
    })
    .process(async ({ id, ...field }) => {
      const newId = await createFieldDefinition({
        // ...fields[i],
        key: field.key,
        controlType: field.controlType,
        label: field.label,
        placeholder: field.placeholder,
        standardFieldType: field.notes,
        eventStandardFieldsId: newEventId,
        options: field.options,
      });
      fieldDefinitionsIdMap[id] = newId;
    });
};

const createVisaFields = async ({ lang, serviceId }) => {
  return await PromisePool.for(constants.VisaFields)
    .withConcurrency(10)
    .handleError(async (error) => {
      console.error('💥 ERROR: ', error);
      // throwing errors will stop PromisePool and you must catch them yourself
      throw error;
    })
    .process(async (field, index) => {
      if (lang === 'Italian') {
        field = {
          ...field,
          label: field.labelIta || field.label,
          options: field.optionsIta || field.options,
        };
      }
      delete field.labelIta;
      delete field.optionsIta;
      const fieldDefinitionId = await createFieldDefinition({
        eventServiceCustomFieldsId: serviceId,
        ...field,
        placeholder: field.label,
        options: JSON.stringify(field.options),
      });

      if (fieldDefinitionId) {
        await createFieldContext({
          fieldDefinitionContextsOfUseId: fieldDefinitionId,
          contextName: 'visa',
          position: index,
        });
      }
    });
};

const createAbstractStandardFields = async ({ lang, serviceId }) => {
  return await PromisePool.for(constants.StandardFieldAbstract)
    .withConcurrency(10)
    .handleError(async (error) => {
      console.error('💥 ERROR: ', error);
      // throwing errors will stop PromisePool and you must catch them yourself
      throw error;
    })
    .process(async (field) => {
      if (lang === 'Italian') {
        field = {
          ...field,
          label: field.labelIta || field.label,
          options: field.optionsIta || field.options,
        };
      }
      delete field.labelIta;
      delete field.optionsIta;
      const fieldDefinitionId = await createFieldDefinition({
        eventServiceCustomFieldsId: serviceId,
        ...field,
        placeholder: field.label,
        options: JSON.stringify(field.options),
      });

      if (fieldDefinitionId) {
        await createFieldContext({
          fieldDefinitionContextsOfUseId: fieldDefinitionId,
          contextName: constants.AbstractFieldContext.Author,
        });
        await createFieldContext({
          fieldDefinitionContextsOfUseId: fieldDefinitionId,
          contextName: constants.AbstractFieldContext.Presenter,
        });
      }
    });
};

export default {
  copyTitles,
  copyTypologies,
  copyEmailTemplateAndLetter,
  copyLetterLayoutTemplate,
  createEvent,
  getEvent,
  copyPaxAnagraphic,
  copyDelegations,
  copyAllotment,
  createEventServices,
  createAbstractService,
  copyStandardFields,
  createVisaFields,
  createAbstractStandardFields,
  eventOrder,
  deleteEvent,
};
