import React from 'react';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const SelectableList = ({
  items,
  selectedStyle,
  defaultStyle,
  Component,
  pageTitle,
  setSelected,
  selected,
}) => {
  const noSelectedStyle = defaultStyle || {
    // border: '2px solid transparent',
    // margin: 10,
    cursor: 'pointer',
  };

  return (
    <Grid container>
      {pageTitle ? (
        <Grid item sm={12}>
          {pageTitle}
          <Divider variant={'middle'} style={{ margin: '4px 8px 0px 8px' }} />
        </Grid>
      ) : null}
      {items.map((item, idx) => (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={idx}>
          <div
            {...(!item.disabled && {
              onClick: () => {
                setSelected && setSelected(item.id);
              },
            })}
            style={
              selected === item.id
                ? {
                    ...selectedStyle,
                    ...(item.disabled && { cursor: 'default' }),
                  }
                : {
                    ...noSelectedStyle,
                    ...(item.disabled && { cursor: 'default' }),
                  }
            }
          >
            <Component {...item} />
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default SelectableList;
