import { aws, appState, constants } from '@aim/common';
const { API } = aws;
import { customAlphabet } from 'nanoid';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 6);

const deleteParticipationQuery = /* GraphQL */ `
  mutation deleteParticipation($id: ID!) {
    deleteParticipation(input: { id: $id }) {
      id
    }
  }
`;

const participantByEventIdQuery = /* GraphQL */ `
  query ParticipantByEventId($id: ID!) {
    participantByEventId(participationEventId: $id, limit: 1000) {
      items {
        id
        username
        fieldValues {
          items {
            id
            value
            fieldDefinition {
              id
            }
          }
        }
        delegation {
          id
          name
          closingDate
        }
        agencyGroup {
          id
          name
          closingDate
        }
        sponsorList {
          id
          name
          closingDate
        }
        sponsorStaff {
          id
          name
          closingDate
        }
      }
    }
  }
`;

const getUserTypeByCognitoIdQuery = /* GraphQL */ `
  query getUserByCognitoId($cognitoUserId: ID!) {
    usersByCognitoId(cognitoUserId: $cognitoUserId) {
      items {
        type
      }
    }
  }
`;

const getFieldsListByContextsQuery = /* GraphQL */ `
  query getFieldsListByContexts(
    $id: ID!
    $filter: ModelFieldContextFilterInput
  ) {
    getEvent(id: $id) {
      type
      typologies: participationTypologies {
        items {
          id
          name
        }
      }
      preTitles {
        items {
          id
          key
          title
          isActive
        }
      }
      profiles(filter: { isDeleted: { ne: true } }) {
        items {
          id
          name
          clusters
          isPublic
          category {
            id
            name
          }
        }
      }
      standardFields {
        items {
          controlType
          id
          key
          createdAt
          isDeleted
          label
          options
          placeholder
          updatedAt
          contextsOfUse(filter: $filter) {
            items {
              id
              position
              contextName
              isRequired
            }
          }
        }
      }
      services(filter: { serviceName: { eq: "PARTICIPATION" } }) {
        items {
          serviceName
          id
          customFields(filter: { isDeleted: { ne: false } }) {
            items {
              controlType
              id
              key
              createdAt
              isDeleted
              label
              options
              placeholder
              updatedAt
              contextsOfUse(filter: $filter) {
                items {
                  id
                  position
                  contextName
                  isRequired
                }
              }
            }
          }
        }
      }
    }
  }
`;

const createParticipationServicesMutation = /* GraphQL */ `
  mutation CreateParticipationServices(
    $input: CreateParticipationServicesInput!
    $condition: ModelParticipationServicesConditionInput
  ) {
    createParticipationServices(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventCodeQuery = /* GraphQL */ `
  query getEvent($id: ID!) {
    getEvent(id: $id) {
      id
      code
    }
  }
`;

export const updateParticipation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: updateParticipationMutation,
      variables: { input },
    })
      .then(resolve)
      .catch((e) => {
        console.error('update-participation', e);
        reject(e);
      })
      .finally(hideLoader);
  });

export const createParticipationServices = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: createParticipationServicesMutation,
      variables: {
        input: { ...input, publicServicesPageAccessCode: nanoid() },
      },
    })
      .then(resolve)
      .catch((e) => {
        console.error('create-participation-service', e);
        reject(e);
      })
      .finally(hideLoader);
  });

export const updateParticipationMutation = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getUserTypeByCognitoId = (cognitoUserId) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getUserTypeByCognitoIdQuery,
      variables: { cognitoUserId },
    })
      .then((response) => {
        resolve(response.data.usersByCognitoId.items?.[0]?.type);
      })
      .catch((e) => {
        console.error('error-usersByCognitoId', e);
        reject();
      });
  });

export const createParticipationQuery = ({
  username,
  eventId,
  cluster,
  clusterId,
  fields,
  profile,
  feeDateRange,
}) => {
  if (!fields.find((p) => p.name === 'isParticipant')) {
    fields.push({ name: 'isParticipant', value: true });
  }
  const paramField = fields
    .filter(
      (f) =>
        f.name !== 'typology' && f.name !== 'profile' && f.value !== undefined
    )
    .map((f) =>
      typeof f.value == 'boolean'
        ? `${f.name}: ${f.value}`
        : `${f.name}: "${f.value}"`
    )
    .join(', ');
  const typology = fields.find((f) => f.name === 'typology')?.value;
  let innerProfile = profile;
  if (!profile) {
    innerProfile = fields.find((f) => f.name === 'profile')?.value;
  }
  let clusterIdParam = ``;
  if (clusterId && cluster !== constants.Clusters.Pax.id) {
    const key = Object.values(constants.Clusters).find(
      (clu) => cluster === clu.id
    )?.participationQueryKey; // this is the key such as participationDelegationId
    clusterIdParam = `${key}: "${clusterId}",`;
  }
  const isClusterClosed =
    cluster === constants.Clusters.Pax.id ? 'true' : undefined;

  let query = `mutation createParticipation {
    createParticipation(input: {
      participationEventId: "${eventId}",
      ${innerProfile ? `participationProfileId: "${innerProfile}",` : ``}
      ${feeDateRange ? `participationFeeDateRangeId: "${feeDateRange}",` : ``}
      ${typology ? `participationTypologyId: "${typology}",` : ``}
      ${isClusterClosed ? `isClusterClosed: "${isClusterClosed}",` : ``}
      status: "suspended", 
      cluster: "${cluster}",
      ${clusterIdParam}
      ${paramField}
      username: "${username}",
      isInvited: false, 
      registrationChannel: "Backoffice"
    }
    ){
      id
    }
  }`;
  return query;
};

export const updateParticipationQuery = ({
  id,
  eventId,
  fields,
  cluster,
  clusterId,
  profile,
  feeDateRange,
}) => {
  const paramField = fields
    .filter(
      (f) =>
        f.name !== 'typology' && f.name !== 'profile' && f.value !== undefined
    )
    .map((f) =>
      typeof f.value == 'boolean'
        ? `${f.name}: ${f.value}`
        : `${f.name}: "${f.value}"`
    )
    .join(', ');

  let clusterIdParam = ``;
  if (clusterId && cluster !== constants.Clusters.Pax.id) {
    const key = Object.values(constants.Clusters).find(
      (clu) => cluster === clu.id
    )?.participationQueryKey; // this is the key such as participationDelegationId
    clusterIdParam = `${key}: "${clusterId}",`;
  }
  const typology = fields.find((f) => f.name === 'typology')?.value;
  let innerProfile = profile;
  if (!profile) {
    innerProfile = fields.find((f) => f.name === 'profile')?.value;
  }
  let query = `mutation updateParticipation {
    updateParticipation(input: {
      id: "${id}",
      participationEventId: "${eventId}",
      ${innerProfile ? `participationProfileId: "${innerProfile}",` : ``}
      ${feeDateRange ? `participationFeeDateRangeId: "${feeDateRange}",` : ``}
      ${typology ? `participationTypologyId: "${typology}",` : ``}
      cluster: "${cluster}",
      ${clusterIdParam}
      ${paramField}
    }
    ){
      id
    }
  }`;
  return query;
};

export const createParticipationRequestQuery = ({
  eventId,
  cluster,
  clusterId,
  fields,
  profile,
  feeDateRange,
  modificationRequestId,
}) => {
  const paramField = fields
    .filter((f) => f.name !== 'typology' && f.value)
    .map((f) =>
      typeof f.value == 'boolean'
        ? `${f.name}: ${f.value}`
        : `${f.name}: "${f.value}"`
    )
    .join(', ');
  const typology = fields.find((f) => f.name === 'typology')?.value;
  let innerProfile = profile;
  if (!profile) {
    innerProfile = fields.find((f) => f.name === 'profile');
  }
  let clusterIdParam = ``;
  if (clusterId) {
    const key = Object.values(constants.Clusters).find(
      (clu) => cluster === clu.id
    )?.participationRequestQueryKey; // this is the key such as participationDelegationId
    clusterIdParam = `${key}: "${clusterId}",`;
  }
  let query = `mutation createParticipationRequest {
    createParticipationRequest(input: {
      eventId: "${eventId}",
      ${innerProfile ? `participationRequestProfileId: "${innerProfile}",` : ``}
      ${
        feeDateRange
          ? `participationRequestFeeDateRangeId: "${feeDateRange}",`
          : ``
      }
      ${typology ? `participationRequestTypologyId: "${typology}",` : ``}
      ${clusterId ? `participationRequestDelegationId: "${clusterId}",` : ``}
      ${
        modificationRequestId
          ? `participationRequestModificationRequestId: "${modificationRequestId}",`
          : ``
      }
      status: "suspended", 
      cluster: "${cluster}",
      ${clusterIdParam}
      ${paramField}
    }
    ){
      id
    }
  }`;
  return query;
};

export const createFieldValuesQuery = ({
  participationId,
  participationRequestId,
  fields,
  eventId,
}) => {
  const fieldMutations = fields
    .map((f, idx) => {
      const parsed = JSON.stringify(f.value);
      return `
    field${idx}: ${f.id ? 'update' : 'create'}FieldValue(
      input: {
        ${f.id ? `id: "${f.id}",` : ''}
        value: ${JSON.stringify(parsed)},
        fieldValueFieldDefinitionId: "${f.fieldDefinitionId}",
        participationFieldValuesId: "${participationId}",
        participationRequestFieldValuesId: "${participationRequestId}"
        eventId: "${eventId}"
      }
    ){
      id
    }
    `;
    })
    .join(' ');
  let query = `mutation createOrUpdateFieldValues {
    ${fieldMutations}
  }`;
  return query;
};

export const getParticipationsByUsernames = async ({
  eventId,
  usernames = [],
}) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: participantByEventIdQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        const eventParticipations = response.data.participantByEventId.items;
        const foundUsernames = eventParticipations.filter(
          ({ username }) => username && usernames.includes(username)
        );
        resolve(foundUsernames);
      })
      .catch((e) => {
        console.error('error-participant-by-eventid-massive-imports', e);
        reject();
      });
  });

export const getFieldsListByContexts = ({ eventId, contextsOfUse }) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getFieldsListByContextsQuery,
      variables: {
        id: eventId,
        filter: { or: contextsOfUse.map((c) => ({ contextName: { eq: c } })) },
      },
    })
      .then((response) => {
        const out = {
          type: response.data.getEvent.type,
          typologies: response.data.getEvent.typologies.items,
          preTitles: response.data.getEvent.preTitles.items,
          profiles: response.data.getEvent.profiles.items,
          standardFields: response.data.getEvent.standardFields.items,
          customFields:
            response.data.getEvent.services.items[0].customFields.items,
        };
        resolve(out);
      })
      .catch((e) => {
        console.error('list-event-field', e);
        reject();
      });
  });

export const createUpdateParticipation = (query) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: query,
      variables: {},
    })
      .then((response) => {
        if (response.error) reject(response.error);
        else resolve(response.data);
      })
      .catch((e) => {
        console.error('error-create-update-participation', e);
        reject(e);
      });
  });

export const createParticipationRequest = (query) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: query,
      variables: {},
    })
      .then((response) => {
        if (response.error) reject(response.error);
        else resolve(response.data);
      })
      .catch((e) => {
        console.error('error-create-participation-request', e);
        reject(e);
      });
  });

export const deleteParticipation = (participationId) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: deleteParticipationQuery,
      variables: { id: participationId },
    })
      .then((response) => {
        if (response.error) reject(response.error);
        else resolve(response.data);
      })
      .catch((e) => {
        console.error('error-delete-participation', e);
        reject(e);
      });
  });

export const createFieldValues = (query) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: query,
      variables: {},
    })
      .then((response) => {
        if (response.error) reject(response.error);
        else resolve(response.data);
      })
      .catch((e) => {
        console.error('error-create-field', e);
        reject(e);
      });
  });

export const getEventCode = (eventId, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();

    API.graphql({
      query: getEventCodeQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        return resolve(response.data.getEvent.code);
      })
      .catch((e) => {
        console.error('get-event-code', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });
