import { constants, utilities } from '@aim/common';
import { sortBy, unionBy } from 'lodash';
import { nanoid } from 'nanoid';
import {
  getFieldsListByContexts,
  createParticipationQuery,
  createParticipationRequestQuery,
  createFieldValuesQuery as createOrUpdateFieldValuesQuery,
  getUserTypeByCognitoId,
  createParticipationServices,
  createUpdateParticipation as gqlCreateParticipation,
  createParticipationRequest as gqlCreateParticipationRequest,
  createFieldValues as gqlCreateFieldValue,
  deleteParticipation as gqlDeleteParticipation,
  updateParticipationQuery,
  updateParticipation as gqlUpdateParticipation,
} from './gqlHelper';

const paramFields = [
  { id: 'username', label: 'ID' },
  { id: 'title', label: 'title', controlType: 'select' },
  { id: 'givenName', label: 'name' },
  { id: 'familyName', label: 'surname' },
  { id: 'email', label: 'email' },
  { id: 'phone', label: 'phone number' },
  { id: 'typology', label: 'typology', controlType: 'select' },
  { id: 'notes', label: 'notes' },
  { id: 'seg', label: 'seg', controlType: 'checkbox' },
  { id: 'ana', label: 'ana', controlType: 'checkbox' },
  {
    id: 'type',
    label: 'participation mode',
    controlType: 'select',
  },
  {
    id: 'isParticipant',
    label: 'pax',
    controlType: 'checkbox',
  },
  {
    id: 'isSpeaker',
    label: 'speak.',
    controlType: 'checkbox',
  },
  {
    id: 'isReviewer',
    label: 'rev.',
    controlType: 'checkbox',
  },
];

/**
 * Custom Hook to use Massive Import utils
 */
export const useMassiveImport = () => {
  const getProfileTemplates = () => {
    return Object.keys(utilities.contextsOfUsePerCluster);
  };

  const getUserType = async (cognitoUserId) => {
    // Check the type of the logged user: congress or travel
    const res = await getUserTypeByCognitoId(cognitoUserId);
    return res;
  };

  const getParsedProfileTemplates = () => {
    const profiles = getProfileTemplates();
    const parsedProfiles = profiles.map((profileLabel, idx) => {
      return {
        id: idx + 1,
        label: profileLabel,
      };
    });
    return parsedProfiles;
  };

  /**
   *
   * @param {File} file
   */
  const getHeaderFromFile = async (file) => {
    const rows = await utilities.ExcelToArrays(file);
    const rowsWithSomething = rows.filter((r) => r.length);
    const headers = rowsWithSomething?.[0]?.filter((h) => h.length);
    return headers.map((header) => header?.trim()?.toLowerCase());
  };

  /**
   *
   * @param {[{type: 'standard' | 'custom' | 'param', value: '<standardFieldId>' | '<customFieldId>' | '<parmaKey>'}]} array
   * @param {'standard' | 'custom' | 'param'} type
   * @param {'<fieldLabel>'} field
   */
  const pushOption = (array, type, field) => {
    array.push({
      type: type,
      value: field.id,
      name: field.label,
      isRequired:
        field.contextsOfUse?.isRequired || type === 'param' ? true : false,
      ...(field.controlType && {
        controlType: field.controlType.toLowerCase(),
      }),
      ...(field.options && { options: field.options }),
    });
  };

  const filterParamsByCluster = (cluster, params) => {
    if (cluster === constants.Clusters.Delegations.id) {
      const toHideParamsForTravel = [
        'type',
        'isParticipant',
        'isSpeaker',
        'isReviewer',
      ];
      return params.filter((p) => !toHideParamsForTravel.includes(p.id));
    }
    if (
      cluster === constants.Clusters.SponsorList.id ||
      cluster === constants.Clusters.SponsorStaff.id ||
      cluster === constants.Clusters.Groups.id
    ) {
      const toHideParamsGroups = [
        'notes',
        'seg',
        'ana',
        'isParticipant',
        'isSpeaker',
        'isReviewer',
      ];
      return params.filter((p) => !toHideParamsGroups.includes(p.id));
    }
    return params;
  };

  /**
   *
   */
  const getAvailableFields = async ({
    eventId,
    cluster,
    clusterId,
    profileId,
    intl,
  }) => {
    try {
      const contextSelector = `${cluster}${profileId ? 'Profile' : ''}`;
      const contexts = utilities.contextsOfUsePerCluster({
        cluster,
        clusterId,
        profileId,
      })[contextSelector];
      const response = await getFieldsListByContexts({
        eventId,
        contextsOfUse: contexts,
      });
      const {
        standardFields,
        customFields,
        typologies,
        preTitles,
        profiles,
        type,
      } = response;
      const outStandardFields = unionToContextOfUse(
        standardFields
          .filter(
            (field) =>
              field.controlType !== 'upload' &&
              field.contextsOfUse?.items?.length
          )
          //to prevent duplicates with new participation fields notes and title
          .filter(
            (field) => field.label !== 'Title' && field.label !== 'Notes'
          ),
        contexts
      );
      console.log('outStandardFields', outStandardFields);
      const outCustomFields = unionToContextOfUse(
        customFields.filter(
          (field) =>
            field.controlType !== 'upload' && field.contextsOfUse?.items?.length
        ),
        contexts
      );

      const nextParamFields = filterParamsByCluster(cluster, paramFields);

      const parsedOptions = [];
      const innexParamFields = profileId
        ? nextParamFields
        : [
            ...nextParamFields,
            ...(cluster !== constants.Clusters.Delegations.id
              ? [
                  {
                    id: 'profile',
                    label: 'profile',
                    controlType: 'select',
                    options: profiles
                      .filter((p) => p.clusters.includes(cluster))
                      .map((p) => ({
                        id: p.id,
                        value: `${p.name}${
                          p?.category?.name ? ` - ${p.category.name}` : ''
                        }`,
                      })),
                  },
                ]
              : []),
          ];

      innexParamFields
        .map((f) =>
          f.id === 'typology'
            ? {
                ...f,
                options: typologies.map((t) => ({
                  id: t.id,
                  value: t.name,
                })),
              }
            : f.id === 'type'
            ? {
                ...f,
                options:
                  type === constants.EventTypes.HYBRID.id
                    ? [
                        {
                          id: constants.EventTypes.VIRTUAL.id,
                          value: constants.EventTypes.VIRTUAL.id,
                        },
                        {
                          id: constants.EventTypes.PHYSICAL.id,
                          value: constants.EventTypes.PHYSICAL.id,
                        },
                      ]
                    : (type === constants.EventTypes.VIRTUAL.id && [
                        {
                          id: constants.EventTypes.VIRTUAL.id,
                          value: constants.EventTypes.VIRTUAL.id,
                        },
                      ]) ||
                      (type === constants.EventTypes.PHYSICAL.id && [
                        {
                          id: constants.EventTypes.PHYSICAL.id,
                          value: constants.EventTypes.PHYSICAL.id,
                        },
                      ]),
              }
            : f.id === 'title'
            ? {
                ...f,
                // options: constants.TitleOptions.map((o) => ({
                //   value: o.label(intl), //value is label because user have to see label non DB value
                //   label: o.label(intl),
                // })),
                options: preTitles
                  .filter((preTitle) => preTitle.isActive)
                  .map((p) => ({
                    label: p.title,
                    value: p.title,
                  })),
              }
            : f
        )
        .map((f) => pushOption(parsedOptions, 'param', f));

      sortBy(outStandardFields, ['contextsOfUse.position']).map((f) =>
        pushOption(parsedOptions, 'standard', f)
      );
      sortBy(outCustomFields, ['contextsOfUse.position']).map((f) =>
        pushOption(parsedOptions, 'custom', f)
      );
      return parsedOptions;
    } catch (err) {
      console.error(err);
    }
  };

  const unionToContextOfUse = (fields, contexts) => {
    const outFields = fields.map((field) => {
      let fieldFirstContextOfUse;
      for (let i = 0; i < contexts.length; i++) {
        fieldFirstContextOfUse = field.contextsOfUse.items.find(
          (cu) => cu.contextName === contexts[i]
        );
        if (fieldFirstContextOfUse) break;
      }
      return {
        ...field,
        options: field.options ? JSON.parse(field.options) : null,
        key: field.id,
        contextsOfUse: fieldFirstContextOfUse,
        isReadOnly:
          fieldFirstContextOfUse.contextName !== contexts[contexts.length - 1],
      };
    });
    return outFields;
  };

  const createParticipation = async ({
    username,
    eventId,
    cluster,
    clusterId,
    fields,
    profile,
    feeDateRange,
  }) => {
    try {
      //TODO: good place to insert lamda-conflict-logic (?)
      const query = createParticipationQuery({
        username,
        eventId,
        cluster,
        clusterId,
        fields,
        profile,
        feeDateRange,
      });
      const res = await gqlCreateParticipation(query);
      return res.createParticipation;
    } catch (e) {
      console.error('e', e);
      throw new Error('Error on create participation');
    }
  };

  const updateParticipation = async ({
    id,
    eventId,
    cluster,
    clusterId,
    fields,
    profile,
    feeDateRange,
  }) => {
    try {
      //TODO: good place to insert lamda-conflict-logic (?)
      const query = updateParticipationQuery({
        id,
        eventId,
        cluster,
        clusterId,
        fields,
        profile,
        feeDateRange,
      });
      const res = await gqlCreateParticipation(query);
      return res.updateParticipation;
    } catch (e) {
      console.error('e', e);
      throw new Error('Error on update participation');
    }
  };

  const createParticipationRequest = async ({
    eventId,
    cluster,
    clusterId,
    fields,
    profile,
    feeDateRange,
    modificationRequestId,
  }) => {
    try {
      const query = createParticipationRequestQuery({
        eventId,
        cluster,
        clusterId,
        fields,
        profile,
        feeDateRange,
        modificationRequestId,
      });
      const res = await gqlCreateParticipationRequest(query);
      return res.createParticipationRequest;
    } catch (e) {
      console.error('e', e);
      throw new Error('Error on create participation request');
    }
  };

  const createOrUpdateFieldValues = async ({
    participationId,
    participationRequestId,
    fields,
    eventId,
  }) => {
    try {
      const query = createOrUpdateFieldValuesQuery({
        participationId,
        participationRequestId,
        fields,
        eventId,
      });
      const res = await gqlCreateFieldValue(query);
      return res;
    } catch (e) {
      console.error(e, 'e');
      throw new Error(
        'Error on create ' +
          e.errors?.length +
          ' fieldValue' +
          (e.errors?.length > 1 ? 's' : '')
      );
    }
  };

  const deleteFailedImportedParticipation = async (participationId) => {
    try {
      const res = await gqlDeleteParticipation(participationId);
      return res;
    } catch (e) {
      throw new Error(
        'Error on delete participation with id: ' + participationId
      );
    }
  };

  const insertOrUpdateParticipationPromise = (
    mapped,
    eventId,
    eventCode,
    cluster,
    clusterId,
    profile,
    feeDateRange
  ) =>
    new Promise((resolve, reject) => {
      if (mapped.id) {
        //IS UPDATE SO WE DON'T NEED TO CREATE PARTICIPATION
        updateParticipation({
          id: mapped.id,
          eventId,
          cluster,
          clusterId,
          fields: mapped.participationInput,
          profile: profile !== 1 ? profile : null,
          feeDateRange: feeDateRange,
        })
          .then((response) =>
            resolve({ response: response, other: { ...mapped } })
          )
          .catch((e) => {
            // Set up an error
            console.log('e', e);
            reject(e?.message);
          });
      } else {
        utilities
          .getNextSequenceValue({
            eventCode,
            sequenceName: 'participationSequence',
          })
          .then((participationSequence) => {
            const username = `${eventCode}-${participationSequence}`;
            createParticipation({
              username,
              eventId,
              cluster,
              clusterId,
              fields: mapped.participationInput,
              profile: profile !== 1 ? profile : null,
              feeDateRange: feeDateRange,
            })
              .then(async (response) => {
                if (response.id) {
                  const { data } = await createParticipationServices({
                    participationServicesParticipationId: response.id,
                    eventId,
                  });
                  await gqlUpdateParticipation({
                    id: response.id,
                    participationParticipationServicesId:
                      data.createParticipationServices.id,
                  });
                }
                resolve({ response: response, other: { ...mapped } });
              })
              .catch((e) => {
                // Set up an error
                console.log('e', e);
                reject(e?.message || '');
              });
          });
      }
    });

  const insertParticipationRequestPromise = ({
    mapped,
    eventId,
    cluster,
    clusterId,
    profile,
    feeDateRange,
    modificationRequestHelper,
    user,
  }) =>
    new Promise((resolve, reject) => {
      const inputModificationRequest = {
        eventId: eventId,
        // participationId: participationId,
        requestId: nanoid(),
        requestStatus: constants.ModificationRequestStatus.PENDING,
        type: constants.ModificationRequestTypes.CREATE,
        modificationRequestRequestedById: user.id,
      };

      modificationRequestHelper
        .create(inputModificationRequest, false)
        .then((modificationRequest) => {
          createParticipationRequest({
            eventId,
            cluster,
            clusterId,
            fields: mapped.participationInput,
            profile: profile !== 1 ? profile : null,
            feeDateRange: feeDateRange,
            modificationRequestId: modificationRequest.id,
          }).then(async (response) => {
            await modificationRequestHelper.update(
              {
                id: modificationRequest.id,
                modificationRequestParticipationRequestId: response.id,
              },
              false
            );
            resolve({ response: response, other: { ...mapped } });
          });
        })
        .catch((e) => {
          console.error(e);
          // Set up an error
          reject(e?.message);
        });
    });

  const insertOrUpdateFieldsPromise = ({
    participationId,
    participationRequestId,
    eventId,
    other,
    idx,
  }) =>
    new Promise((resolve, reject) => {
      createOrUpdateFieldValues({
        participationId,
        participationRequestId, //id of the participation request just created
        fields: other.fieldsInputs,
        eventId,
      })
        .then((fieldResponse) => {
          resolve(fieldResponse);
        })
        .catch((e) => {
          console.error('e', e);
          const _other = other.participationInput;
          _other.push({ name: 'id', value: participationRequestId });
          createError(idx, _other, e?.message);
          // reject(createError(idx, _other, e?.message));
          reject(e?.message);
        });
    });

  const createError = (idx, participationInput, reason) => {
    const error = {
      aim_excel_index: idx,
      aim_failed_import_reason: reason || 'generic error',
    };
    participationInput.map((v) => {
      error[`${v.name}`] = v.value;
    });
    return error;
  };

  return {
    getHeaderFromFile,
    getAvailableFields,
    getProfileTemplates,
    getParsedProfileTemplates,
    createParticipation,
    createFieldValues: createOrUpdateFieldValues,
    deleteFailedImportedParticipation,
    getUserType,
    insertOrUpdateParticipationPromise,
    insertParticipationRequestPromise,
    insertOrUpdateFieldsPromise,
    createError,
  };
};
