import React, { useState, useEffect } from 'react';
import { appState, constants, utilities } from '@aim/common';
import { InnerMassiveImportDialog, ExportTemplateDialog } from './Dialogs';
import { getEventDetailsByClusters } from './utils/eventDetailsGqlHelper.js';
import { useMassiveImport } from '../../../utils/hooks/MassiveImportUtils';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const getfileNameEscapingSpecialChars = (name) =>
  name
    .replaceAll(' ', '_')
    .replaceAll(':', '')
    .replaceAll('*', '')
    .replaceAll('?', '')
    .replaceAll('/', '')
    .replaceAll('\\', '')
    .replaceAll('[', '')
    .replaceAll(']', '')
    .replaceAll('.', '');

export const MassiveImportDialog = ({
  eventId,
  massiveDialog,
  setMassiveDialog,
  cluster,
  clusterId,
  clusterName,
  state,
  intl,
  history,
  customPath = false,
  customBackUrl = false,
  rows,
}) => {
  // Hooks
  // const { eventId, sponsorId } = useParams();
  const { getAvailableFields } = useMassiveImport();
  // States
  const [exportTemplateDialog, setExportTemplateDialog] = useState({
    isOpen: false,
  });
  const [availableProfiles, setAvailableProfiles] = useState();

  useEffect(() => {
    fetchProfiles();
  }, [cluster]);

  //Functions
  const fetchProfiles = async () => {
    if (eventId && cluster) {
      const res = await getEventDetailsByClusters(eventId, [cluster]);
      const _profiles = [];
      res?.profiles?.items?.map((profile) => {
        _profiles.push({
          label:
            profile.name +
            (profile?.category?.name ? ` - ${profile?.category.name}` : ''),
          id: profile.id,
          cluster: profile.cluster,
        });
      });
      _profiles.unshift({ id: 1, label: 'All' });
      setAvailableProfiles(_profiles);
    }
  };

  const handleSelectedAction = async (action) => {
    if (action === 'export') {
      //export template
      if (cluster === constants.Clusters.Delegations.id && clusterId) {
        showLoader();
        await handleExportDelegations();
        hideLoader();
        setMassiveDialog({ isOpen: false });
      } else {
        setExportTemplateDialog({
          isOpen: true,
        });
      }
    } else if (action === 'import') {
      //import
      history.push({
        pathname:
          customPath ||
          `/events/${eventId}/participation/${cluster}${
            clusterId ? `/${clusterId}` : ''
          }/import${massiveDialog.deadlinePath || ''}`,
        state: {
          ...state,
          ...(customBackUrl && { backUrl: customBackUrl }),
        },
      });
    } else {
      //export template with rows
      const fields = await getAvailableFields({
        eventId,
        cluster,
        clusterId,
        intl,
      });
      const nextFields = fields.filter((x) => x.value !== 'cluster');
      const visibleRows =
        Object.entries(rows?.filter?.visibleRowsLookup || {})
          .filter(([_, isVisible]) => isVisible)
          .map(([key]) => key) || [];
      const rowIdsForLookup = rows.selection?.length
        ? rows.selection
        : visibleRows?.length
        ? visibleRows
        : rows?.ids;

      const headerObjects = rows.columns?.all
        .filter((key) => key !== '__check__' && key !== 'actions')
        .map((key) => rows.columns?.lookup[key])
        .filter((x) => !x.hide);

      const keys = nextFields.map((x) => x.value);
      const header = nextFields.map((x) => x.name);
      const objects = rowIdsForLookup
        .map((x) => rows.rows?.idRowsLookup[x])
        .map((x) =>
          headerObjects.reduce(
            (prev, curr) => {
              const value = curr.valueGetter
                ? curr.valueGetter({ row: x })
                : x[curr.field];
              const finalValue =
                curr.type === 'boolean'
                  ? (value || false).toString() // || false is to prevent null values
                  : curr.valueFormatter
                  ? curr.valueFormatter({
                      row: x,
                      formattedValue: value,
                      value,
                    })
                  : value;
              // const fieldType = nextFields.find((f) => f.name === curr.field)
              //   ?.type;
              return {
                ...prev,
                [curr.field]: finalValue,
              };
            },

            {}
          )
        );

      utilities.objectsToExcel({
        filename: `export_template_with_rows`,
        header,
        keys,
        objects,
      });
    }
  };

  const handleExportDelegations = async () => {
    const fields = await getAvailableFields({
      eventId,
      cluster,
      clusterId,
      intl,
    });
    utilities.objectsToExcel({
      filename: `${getfileNameEscapingSpecialChars(clusterName)}_template`,
      header: fields,
      keys: [],
      objects: [],
    });
  };
  const handleExportProfile = async (profile) => {
    const isNotAll = profile !== 1;
    const nameTemplate = getFileName(profile);
    const fields = await getAvailableFields({
      eventId,
      cluster,
      profileId: isNotAll ? profile : false,
      intl,
    });
    utilities.objectsToExcel({
      filename: `${getfileNameEscapingSpecialChars(nameTemplate)}_template`,
      header: fields,
      keys: [],
      objects: [],
    });
  };

  const getFileName = (profile) => {
    if (profile !== 1)
      return availableProfiles
        ?.filter((p) => p.id === profile)[0]
        .label?.replace(' ', '_');
    else return cluster;
  };

  return (
    <div>
      <InnerMassiveImportDialog
        intl={intl}
        massiveImportDialog={massiveDialog}
        onClose={() => {
          setMassiveDialog({ isOpen: false });
        }}
        onAgree={handleSelectedAction}
        exportTemplateWithRowsVisible={
          rows !== undefined && rows.selection?.length
        }
      />
      <ExportTemplateDialog
        intl={intl}
        availableProfiles={availableProfiles}
        exportTemplateDialog={exportTemplateDialog}
        onClose={() => {
          setExportTemplateDialog({ isOpen: false });
          setMassiveDialog({ isOpen: false });
        }}
        onAgree={handleExportProfile}
        cluster={cluster}
      />
    </div>
  );
};
