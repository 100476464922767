import React, { useEffect, useState } from 'react';
import { format, sub } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { sortBy } from 'lodash';

import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';

import { appState, constants, utilities } from '@aim/common';
import { AimIconAndTextButton, AimTypography } from '../atoms';

import { styled } from '../styled';
import { theme } from '../../theme';
import translation from './ProgramTemplate/translation';
import { getEventPrintOptions } from './ProgramTemplate/gqlHelper';
import { AimReactPdfRenderer } from './AimReactPdfRender';

const CardsWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 10,
  marginTop: 10,
});

const Card = styled('div')({
  display: 'flex',
  width: '100%',
  padding: 20,
  backgroundColor: theme.colors.greyScale.backgroundGrey,
  borderBottom: `1px solid ${theme.colors.greyScale.grey2}`,
});

const byRoomsRowsTemplate = ({ position }) => {
  return [
    {
      indentation: 1,
      interline: 0.5,
      textSections: [{ key: 'generalSessionDate', modelPath: 'date' }],
    },
    {
      indentation: 1,
      interline: 0.5,
      textSections: [],
      iterateOnProperty: 'rooms',
      subRowsTemplate: [
        {
          indentation: 1,
          interline: 0.5,
          textSections: [{ key: 'generalSessionRoom', modelPath: 'room' }],
        },
        {
          iterateOnProperty: 'sessions',
          subRowsTemplate: [
            {
              indentation: 1,
              interline: 0.2,
              ...(position.isGeneralSessionStartEndToRightOfTitle
                ? {
                    textSections: [
                      {
                        key: 'generalSessionCode',
                        modelPath: 'sessionCode',
                      },
                      {
                        key: 'generalSessionTitle',
                        modelPath: 'sessionTitle',
                      },
                      {
                        key: 'generalSessionStartEndTime',
                        modelPath: 'sessionStartEndTime',
                      },
                    ],
                  }
                : {
                    textSections: [
                      {
                        key: 'generalSessionCode',
                        modelPath: 'sessionCode',
                      },
                      {
                        key: 'generalSessionStartEndTime',
                        modelPath: 'sessionStartEndTime',
                      },
                      {
                        key: 'generalSessionTitle',
                        modelPath: 'sessionTitle',
                      },
                    ],
                  }),
              separator: {
                text: ' ',
                spacing: 0,
                style: { fontWeight: 100 },
              },
            },
            {
              // indentation: 4.35,
              indentation: 1.6,
              interline: 0.1,
              textSections: [
                // {
                //   key: 'generalSessionTypology',
                //   modelPath: 'sessionTypology',
                // },
                {
                  key: 'generalSessionDescription',
                  modelPath: 'sessionDescription',
                },
              ],
              separator: {
                text: ' - ',
                spacing: 0,
                style: { fontSize: 10 },
              },
            },
            {
              // indentation: 6.35,
              indentation: 1.6,
              interline: 0.1,
              textSections: [
                { key: 'attendeeRole', modelPath: 'role' },
                { key: 'attendeeName', modelPath: 'attendees' },
                // { key: 'facultyRole', modelPath: 'role' },
                // { key: 'facultyName', modelPath: 'attendees' },
              ],
              separator: {
                text: ': ',
                spacing: 0,
                style: { fontSize: 10 },
              },
              iterateOnProperty: 'sessionAttendees',
            },
            {
              // indentation: 4.35,
              // interline: 0.6,
              // textSections: [
              //   {
              //     key: 'subSessionBlockStartEndTime',
              //     modelPath: 'subSessionStartEndTime',
              //   },
              // ],
              iterateOnProperty: 'subSessions',
              subRowsTemplate: [
                {
                  // indentation: 4.35,
                  indentation: 1,
                  interline: 0.1,
                  ...(position.isSubSessionStartEndToRightOfTitle
                    ? {
                        textSections: [
                          {
                            key: 'subSessionBlockCode',
                            modelPath: 'subSessionCode',
                          },
                          {
                            key: 'subSessionBlockTitle',
                            modelPath: 'subSessionTitle',
                          },
                          {
                            key: 'subSessionBlockStartEndTime',
                            modelPath: 'subSessionStartEndTime',
                          },
                        ],
                      }
                    : {
                        textSections: [
                          {
                            key: 'subSessionBlockCode',
                            modelPath: 'subSessionCode',
                          },
                          {
                            key: 'subSessionBlockStartEndTime',
                            modelPath: 'subSessionStartEndTime',
                          },

                          {
                            key: 'subSessionBlockTitle',
                            modelPath: 'subSessionTitle',
                          },
                        ],
                      }),
                  separator: {
                    text: ' ',
                    spacing: 0,
                    style: {},
                  },
                },
                {
                  // indentation: 4.35,
                  indentation: 1.6,
                  interline: 0.1,
                  textSections: [
                    {
                      key: 'subSessionBlockDescription',
                      modelPath: 'subSessionDescription',
                    },
                  ],
                  separator: {
                    text: ' ',
                    spacing: 0,
                    style: {},
                  },
                },
                {
                  // indentation: 6.35,
                  indentation: 1.6,
                  interline: 0.1,
                  textSections: [
                    { key: 'attendeeRole', modelPath: 'role' },
                    { key: 'attendeeName', modelPath: 'attendees' },
                    // { key: 'facultyRole', modelPath: 'role' },
                    // { key: 'facultyName', modelPath: 'attendees' },
                  ],
                  separator: {
                    text: ': ',
                    spacing: 0,
                    style: { fontSize: 10 },
                  },
                  iterateOnProperty: 'subSessionAttendees',
                },
                {
                  // indentation: 4.35,
                  indentation: 1,
                  interline: 0.4,
                  // textSections: [
                  //   {
                  //     key: 'interventionBlockStartEndTime',
                  //     modelPath: 'speechStartEndTime',
                  //   },
                  // ],
                  iterateOnProperty: 'interventions',
                  subRowsTemplate: [
                    {
                      // indentation: 4.35,
                      indentation: 1,
                      interline: 0.1,
                      ...(position.isSpeechStartEndTimeToRight
                        ? {
                            textSections: [
                              {
                                key: 'interventionBlockCode',
                                modelPath: 'speechCode',
                              },
                              {
                                key: 'interventionBlockTitle',
                                modelPath: 'speechTitle',
                              },
                              {
                                key: 'interventionBlockStartEndTime',
                                modelPath: 'speechStartEndTime',
                              },
                            ],
                          }
                        : {
                            textSections: [
                              {
                                key: 'interventionBlockCode',
                                modelPath: 'speechCode',
                              },
                              {
                                key: 'interventionBlockStartEndTime',
                                modelPath: 'speechStartEndTime',
                              },
                              {
                                key: 'interventionBlockTitle',
                                modelPath: 'speechTitle',
                              },
                            ],
                          }),
                      separator: {
                        text: ' ',
                        spacing: 0,
                        style: { fontWeight: 100 },
                      },
                    },
                    {
                      // indentation: 6.35,
                      indentation: 1.6,
                      interline: 0.1,
                      textSections: [
                        {
                          key: 'interventionBlockTypology',
                          modelPath: 'speechType',
                        },
                        {
                          key: 'interventionBlockDescription',
                          modelPath: 'speechDescription',
                        },
                      ],
                      separator: {
                        text: ' - ',
                        spacing: 0,
                        style: { fontSize: 10 },
                      },
                    },
                    {
                      // indentation: 6.35,
                      indentation: 1.6,
                      interline: 0.1,
                      textSections: [
                        { key: 'attendeeRole', modelPath: 'role' },
                        { key: 'attendeeName', modelPath: 'attendees' },
                        // { key: 'facultyRole', modelPath: 'role' },
                        // { key: 'facultyName', modelPath: 'attendees' },
                      ],
                      separator: {
                        text: ': ',
                        spacing: 0,
                        style: { fontSize: 10 },
                      },
                      iterateOnProperty: 'attendees',
                    },
                    {
                      // indentation: 6.35,
                      indentation: 1.6,
                      interline: 0.1,
                      textSections: [
                        { key: 'abstractRole', modelPath: 'role' },
                        // {
                        //   key: 'abstractAuthors',
                        //   modelPath: 'attendees',
                        //   customStyle: 'customAbstractAuthorsStyle',
                        // },
                      ],
                      direction: 'row',
                      // separator: {
                      //   text: ': ',
                      //   spacing: 0,
                      //   style: { fontSize: 10 },
                      // },
                      iterateOnProperty: 'abstractAttendees',
                      subRowsTemplate: [
                        {
                          indentation: 0,
                          interline: 0.1,
                          textSections: [
                            {
                              key: 'abstractAuthors',
                              modelPath: 'abstractAttendee',
                              customStyle: 'customAbstractAuthorsStyle',
                            },
                            {
                              key: 'abstractAffiliation',
                              modelPath: 'affiliation',
                              customStyle: 'customAbstractAffiliationStyle',
                            },
                          ],
                          viewSeparator: {
                            text: ', ',
                            spacing: 0,
                            style: { fontSize: 10 },
                          },
                          iterateOnProperty: 'attendees',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};

const byTimeRowsTemplate = ({ position }) => {
  return [
    {
      indentation: 1,
      interline: 0.5,
      textSections: [{ key: 'generalSessionDate', modelPath: 'date' }],
    },
    {
      indentation: 1,
      interline: 0.5,
      textSections: [],
      iterateOnProperty: 'starts',
      subRowsTemplate: [
        // {
        //   indentation: 1,
        //   interline: 0.5,
        //   textSections: [{ key: 'generalSessionStart', modelPath: 'start' }],
        // },
        {
          // indentation: 1,
          // interline: 0.2,
          // textSections: [
          //   { key: 'generalSessionStartEndTime', modelPath: 'startEndTime' },
          // ],
          iterateOnProperty: 'sessions',
          subRowsTemplate: [
            // {
            //   indentation: 1,
            //   interline: 0.1,
            //   textSections: [
            //     { key: 'generalSessionSponsor', modelPath: 'sponsor' },
            //   ],
            // },
            {
              indentation: 1,
              interline: 0.1,
              ...(position.isGeneralSessionStartEndToRightOfTitle
                ? {
                    textSections: [
                      {
                        key: 'generalSessionCode',
                        modelPath: 'code',
                      },
                      {
                        key: 'generalSessionTitle',
                        modelPath: 'title',
                      },
                      {
                        key: 'generalSessionStartEndTime',
                        modelPath: 'startEndTime',
                      },
                    ],
                  }
                : {
                    textSections: [
                      {
                        key: 'generalSessionCode',
                        modelPath: 'code',
                      },
                      {
                        key: 'generalSessionStartEndTime',
                        modelPath: 'startEndTime',
                      },
                      {
                        key: 'generalSessionTitle',
                        modelPath: 'title',
                      },
                    ],
                  }),
              separator: {
                text: '. ',
                spacing: 0,
                style: { fontWeight: 100 },
              },
            },
            {
              indentation: 1.6,
              interline: 0,
              textSections: [{ key: 'generalSessionRoom', modelPath: 'room' }],
            },
            {
              indentation: 1,
              interline: 0,
              textSections: [
                { key: 'generalSessionSponsor', modelPath: 'sponsor' },
              ],
            },
            {
              indentation: 1.6,
              interline: 0.1,
              textSections: [
                // {
                //   key: 'generalSessionTypology',
                //   modelPath: 'sessionTypology',
                // },
                {
                  key: 'generalSessionDescription',
                  modelPath: 'sessionDescription',
                },
              ],
              separator: {
                text: ' - ',
                spacing: 0,
                style: { fontSize: 10 },
              },
            },
            {
              indentation: 1,
              interline: 0.6,
              // textSections: [
              //   {
              //     key: 'subSessionBlockStartEndTime',
              //     modelPath: 'subSessionStartEndTime',
              //   },
              // ],
              iterateOnProperty: 'subsessions',
              subRowsTemplate: [
                {
                  indentation: 1,
                  interline: 0.1,
                  ...(position.isSubSessionStartEndToRightOfTitle
                    ? {
                        textSections: [
                          // {
                          //   key: 'subSessionBlockCode',
                          //   modelPath: 'subSessionCode',
                          // },
                          {
                            key: 'subSessionBlockTitle',
                            modelPath: 'subSessionTitle',
                          },
                          {
                            key: 'subSessionBlockStartEndTime',
                            modelPath: 'subSessionStartEndTime',
                          },
                        ],
                      }
                    : {
                        textSections: [
                          // {
                          //   key: 'subSessionBlockCode',
                          //   modelPath: 'subSessionCode',
                          // },
                          {
                            key: 'subSessionBlockStartEndTime',
                            modelPath: 'subSessionStartEndTime',
                          },

                          {
                            key: 'subSessionBlockTitle',
                            modelPath: 'subSessionTitle',
                          },
                        ],
                      }),
                  // textSections: [
                  //   {
                  //     key: 'subSessionBlockCode',
                  //     modelPath: 'subSessionCode',
                  //   },
                  //   {
                  //     key: 'subSessionBlockTitle',
                  //     modelPath: 'subSessionTitle',
                  //   },
                  // ],
                  separator: {
                    text: '. ',
                    spacing: 0,
                    style: {},
                  },
                },
                {
                  indentation: 1.6,
                  interline: 0.1,
                  textSections: [
                    {
                      key: 'subSessionBlockDescription',
                      modelPath: 'subSessionDescription',
                    },
                  ],
                  separator: {
                    text: ' ',
                    spacing: 0,
                    style: { fontWeight: 100 },
                  },
                },
                {
                  indentation: 1,
                  interline: 0.4,
                  // textSections: [
                  //   {
                  //     key: 'interventionBlockStartEndTime',
                  //     modelPath: 'speechStartEndTime',
                  //   },
                  // ],
                  iterateOnProperty: 'interventions',
                  subRowsTemplate: [
                    {
                      indentation: 1,
                      interline: 0.1,
                      ...(position.isSpeechStartEndTimeToRight
                        ? {
                            textSections: [
                              {
                                key: 'interventionBlockCode',
                                modelPath: 'speechCode',
                              },
                              {
                                key: 'interventionBlockTitle',
                                modelPath: 'speechTitle',
                              },
                              {
                                key: 'interventionBlockStartEndTime',
                                modelPath: 'speechStartEndTime',
                              },
                            ],
                          }
                        : {
                            textSections: [
                              {
                                key: 'interventionBlockCode',
                                modelPath: 'speechCode',
                              },
                              {
                                key: 'interventionBlockStartEndTime',
                                modelPath: 'speechStartEndTime',
                              },
                              {
                                key: 'interventionBlockTitle',
                                modelPath: 'speechTitle',
                              },
                            ],
                          }),
                      // textSections: [
                      //   {
                      //     key: 'interventionBlockCode',
                      //     modelPath: 'speechCode',
                      //   },
                      //   {
                      //     key: 'interventionBlockTitle',
                      //     modelPath: 'speechTitle',
                      //   },
                      // ],
                      separator: {
                        text: ' ',
                        spacing: 0,
                        style: { fontWeight: 100 },
                      },
                    },
                    {
                      indentation: 1.6,
                      interline: 0.1,
                      textSections: [
                        {
                          key: 'interventionBlockTypology',
                          modelPath: 'speechType',
                        },
                        {
                          key: 'interventionBlockDescription',
                          modelPath: 'speechDescription',
                        },
                      ],
                      separator: {
                        text: ' - ',
                        spacing: 0,
                        style: { fontSize: 10 },
                      },
                    },
                    {
                      indentation: 1.6,
                      interline: 0.1,
                      textSections: [
                        { key: 'attendeeRole', modelPath: 'role' },
                        { key: 'attendeeName', modelPath: 'attendees' },
                        // { key: 'facultyRole', modelPath: 'role' },
                        // { key: 'facultyName', modelPath: 'attendees' },
                      ],
                      separator: {
                        text: ': ',
                        spacing: 0,
                        style: { fontSize: 10 },
                      },
                      iterateOnProperty: 'attendees',
                    },
                    {
                      indentation: 1.6,
                      interline: 0.1,
                      textSections: [
                        { key: 'attendeeRole', modelPath: 'role' },
                        {
                          key: 'abstractAuthors',
                          modelPath: 'attendees',
                          customStyle: 'customAbstractAuthorsStyle',
                        },
                      ],
                      separator: {
                        text: ': ',
                        spacing: 0,
                        style: { fontSize: 10 },
                      },
                      iterateOnProperty: 'abstractAttendees',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};

const customDateFormat = (date, currentTimezone) =>
  date
    ? format(utcToZonedTime(new Date(date), currentTimezone), 'dd/MM/yyyy')
    : '';

// const customDateFormatNoTz = (date) => format(new Date(date), 'dd/MM/yyyy');

const customHourFormat = (date, currentTimezone) =>
  date ? format(utcToZonedTime(new Date(date), currentTimezone), 'HH:mm') : '';

const extractAffiliation = (affiliation, printOptions) => {
  if (!affiliation) return '';
  let abstractAffiliationPrintOption = printOptions.find(
    (po) => po.key === constants.PrintOptions.abstractAffiliation.key
  );

  const affiliationPositionValues = Object.values(
    constants.PrintOptions.abstractAffiliation.options.position
  );

  // institution
  const institutionKey = affiliationPositionValues.find(
    (x) => x.key === 'institution'
  )?.key;
  const isInstitutionVisible =
    abstractAffiliationPrintOption?.position?.[institutionKey];

  // city
  const cityKey = affiliationPositionValues.find((x) => x.key === 'city')?.key;
  const isCityVisible = abstractAffiliationPrintOption?.position?.[cityKey];

  // closingBetweenBrackets
  const closingBetweenBracketsKey = affiliationPositionValues.find(
    (x) => x.key === 'closingBetweenBrackets'
  )?.key;
  const isClosingBetweenBrackets =
    abstractAffiliationPrintOption?.position?.[closingBetweenBracketsKey];

  // commaSeparator
  const commaSeparatorKey = affiliationPositionValues.find(
    (x) => x.key === 'commaSeparator'
  )?.key;

  // pointSeparatorSemicolon
  const pointSeparatorSemicolonKey = affiliationPositionValues.find(
    (x) => x.key === 'pointSeparatorSemicolon'
  )?.key;
  const isSemicolonSeparatorSelected =
    abstractAffiliationPrintOption?.position?.[pointSeparatorSemicolonKey];

  // separatorHyphen
  const hyphenSeparatorKey = affiliationPositionValues.find(
    (x) => x.key === 'separatorHyphen'
  )?.key;
  const isHyphenSeparatorSelected =
    abstractAffiliationPrintOption?.position?.[hyphenSeparatorKey];

  // do not show affiliation
  const doNotShowAffiliationKey = affiliationPositionValues.find(
    (x) => x.key === 'doNotShowAffiliation'
  )?.key;
  const doNotShowAffiliation =
    abstractAffiliationPrintOption?.position?.[doNotShowAffiliationKey];

  if (doNotShowAffiliation) {
    return '';
  }

  // {
  //   "id": "9b07207d-c65c-4f73-87a3-a503ab4b15c4",
  //   "key": "abstractAffiliation",
  //   "fontSize": "10px",
  //   "isBold": false,
  //   "isUnderlined": false,
  //   "isItalic": false,
  //   "position": {
  //       "doNotShowAffiliation": false,
  //       "institution": true,
  //       "separatorHyphen": false,
  //       "city": true,
  //       "commaSeparator": false,
  //       "closingBetweenBrackets": false,
  //       "pointSeparatorSemicolon": true
  //   }
  // }

  const isPresenterUnderline =
    abstractAffiliationPrintOption?.position?.[
      constants.PrintOptions.abstractAffiliation.options.position[0].key
    ];

  const isCityEnabled =
    abstractAffiliationPrintOption?.position?.[
      constants.PrintOptions.abstractAffiliation.options.position[0].key
    ];
  const cityString = isCityEnabled
    ? utilities.capitalize(affiliation.city)
    : '';

  // const isProvinceEnabled =
  //   abstractAffiliationPrintOption?.position?.[
  //     constants.PrintOptions.abstractAffiliation.options.position[1].key
  //   ];
  // const provinceString = isProvinceEnabled
  //   ? utilities.capitalize(affiliation.province)
  //   : '';

  // const isAllCountryEnabled =
  //   abstractAffiliationPrintOption?.position?.[
  //     constants.PrintOptions.abstractAffiliation.options.position[2].key
  //   ];

  // const isCountryNoItalyEnabled =
  //   abstractAffiliationPrintOption?.position?.[
  //     constants.PrintOptions.abstractAffiliation.options.position[3].key
  //   ];

  // const countrySring =
  //   isAllCountryEnabled ||
  //   (isCountryNoItalyEnabled &&
  //     affiliation.country?.toLowerCase?.() !== 'italy' &&
  //     affiliation.country?.toLowerCase?.() !== 'italia')
  //     ? utilities.capitalize(affiliation.country)
  //     : '';

  // //comma not used beacuse is the default
  // const isAbstractAffiliationIsseparatorHyphen =
  //   abstractAffiliationPrintOption?.position?.[
  //     constants.PrintOptions.abstractAffiliation.options.position[5].key
  //   ];
  // const isAbstractAffiliationSemiSemicolonSeparated =
  //   abstractAffiliationPrintOption?.position?.[
  //     constants.PrintOptions.abstractAffiliation.options.position[6].key
  //   ];

  const separator = isSemicolonSeparatorSelected
    ? '; '
    : isHyphenSeparatorSelected
    ? ' - '
    : ', ';
  const text = [
    isInstitutionVisible ? affiliation.institution : '',
    isCityVisible ? cityString : '',
    // provinceString,
    // countrySring,
  ]
    .filter((x) => x)
    .join(`${separator}`);

  if (isClosingBetweenBrackets) return text ? ` (${text})` : '';
  else return text ? ` ${text}` : '';
};

const extractAuthors = (
  speech,
  isAbstractAuthorsNameAbbreviated,
  printOptions
) => {
  const authors = speech.abstract.authors?.items?.length
    ? speech.abstract.authors.items
        .filter((a) => !a.isPresentingAuthor)
        .map((author) => {
          if (!author) return '';
          const name = author.name;
          const surname = author.surname;

          const affiliation = extractAffiliation(
            author.affiliation,
            printOptions
          );

          return {
            abstractAttendee: isAbstractAuthorsNameAbbreviated
              ? `${name.charAt(0).toUpperCase()}. ${utilities.capitalize(
                  surname
                )}`
              : `${utilities.capitalize(name)} ${utilities.capitalize(
                  surname
                )}`,
            affiliation,
          };
        })
    : [{ abstractAttendee: '', affiliation: '' }];
  return authors;
};

const extractPresenters = ({
  speech,
  printOptions,
  isAbstractAuthorsNameAbbreviated,
  isPresenterUnderlined,
}) => {
  const presenters = speech.abstract.authors?.items.filter(
    (author) => author.isPresentingAuthor
  );

  // TODO: missing (City, Country) in participation ==> CustomField?
  const nextPresenters = presenters.map((presenter) => {
    const affiliation = extractAffiliation(
      presenter?.affiliation,
      printOptions
    );
    const nextPresenter = {
      abstractAttendee: isAbstractAuthorsNameAbbreviated
        ? `${presenter.name.charAt(0).toUpperCase()}. ${utilities.capitalize(
            presenter?.surname
          )}`
        : `${utilities.capitalize(presenter.name)} ${utilities.capitalize(
            presenter?.surname
          )}`,
      affiliation,
      customAbstractAuthorsStyle: isPresenterUnderlined
        ? {
            textDecoration: 'underline',
          }
        : {},
    };

    // return `${
    //   isAbstractAuthorsNameAbbreviated
    //     ? `${presenter.name.charAt(0).toUpperCase()}.`
    //     : presenter.name
    // } ${utilities.capitalize(presenter?.surname)} ${extractAffiliation(
    //   presenter?.affiliation,
    //   printOptions
    // )}`
    return nextPresenter;
  });

  return nextPresenters;
};

const generateAbstractAttendees = (speech, printOptions) => {
  const abstractAuthorsPrintOption = printOptions.find(
    (po) => po.key === constants.PrintOptions.abstractAuthors.key
  );
  const abstractPositionValues = Object.values(
    constants.PrintOptions.abstractAuthors.options.position
  );

  // only the presenter
  const abstractAuthorsOnlyThePresenterKey = abstractPositionValues.find(
    (x) => x.key === 'onlyThePresenter'
  )?.key;
  const isOnlyThePresenter =
    abstractAuthorsPrintOption?.position?.[abstractAuthorsOnlyThePresenterKey];

  // presenter underlined
  const abstractAuthorsPresenterUnderlinedKey = abstractPositionValues.find(
    (x) => x.key === 'underlineThePresenter'
  )?.key;
  const isPresenterUnderlined =
    abstractAuthorsPrintOption?.position?.[
      abstractAuthorsPresenterUnderlinedKey
    ];

  // name abbreviated N.Surname
  const abstractAuthorsNameAbbreviatedKey = abstractPositionValues.find(
    (x) => x.key === 'nSurname'
  )?.key;
  const isAbstractAuthorsNameAbbreviated =
    abstractAuthorsPrintOption?.position?.[abstractAuthorsNameAbbreviatedKey];

  const abstractAuthors = speech.abstract
    ? [
        ...(!isOnlyThePresenter
          ? [
              {
                role: 'Author: ',
                attendees: extractAuthors(
                  speech,
                  isAbstractAuthorsNameAbbreviated,
                  printOptions
                ),
              },
            ]
          : []),
        {
          role: 'Presenter: ',
          attendees: extractPresenters({
            speech,
            printOptions,
            isAbstractAuthorsNameAbbreviated,
            isPresenterUnderlined,
          }),
        },
      ]
    : [];
  return abstractAuthors;
};

const getRolesAndAttendees = ({
  printOptions,
  section,
  attendeesToFilter,
  isFrontOffice = false,
}) => {
  let attendeesPrintOption = printOptions?.find(
    (po) => po.key === constants.PrintOptions.attendeeName.key
  );
  const attendeeNamePositionValues = Object.values(
    constants.PrintOptions.attendeeName.options.position
  );

  // name abbreviated N.Surname
  const attendeeNameAbbreviatedKey = attendeeNamePositionValues.find(
    (x) => x.key === 'nSurname'
  )?.key;
  const isAttendeeNameAbbreviated =
    attendeesPrintOption?.position?.[attendeeNameAbbreviatedKey];

  const attendees = section?.attendeeRoles?.items?.flatMap((attRole) => {
    let nextAttendees = attRole.attendees.items
      ?.filter(
        (att) => !attendeesToFilter || !attendeesToFilter?.includes(att.id)
      )
      ?.reduce((res, curr) => {
        let nationality = '';
        if (curr.participation.user) {
          nationality = ` (${curr.participation.user.nationality})`;
        }

        return `${res} ${
          isAttendeeNameAbbreviated &&
          (curr.participation.isFaculty || curr.participation.isSpeaker)
            ? `${curr.participation.givenName.charAt(0).toUpperCase()}.`
            : utilities.capitalize(curr.participation.givenName)
        } ${utilities.capitalize(curr.participation.familyName)}${
          nationality ? ` ${nationality}` : ''
        },`;
      }, '');

    nextAttendees = nextAttendees.slice(0, -1); // remove last ','
    // const isPresenter =
    //   attRole.role?.id === constants.agendaSessionAttendees.Presenter.id;

    // roles visible if showInProgram is true and
    // there are attendees to view not relative to abstract
    const nextShowInProgram =
      (attRole.role?.showInProgram === null || attRole.role?.showInProgram) &&
      nextAttendees?.length > 0;
    return {
      ...(nextShowInProgram && {
        //used below on render
        originalAttendeeRole: attRole,
        role: utilities.capitalize(
          isFrontOffice && attRole.role?.frontofficeName
            ? attRole.role?.frontofficeName
            : attRole.role?.name
        ),
      }),
      // attendees: nextAttendees,
      ...(nextAttendees !== '' && { attendees: nextAttendees }),
    };
  });

  return attendees;
};

const parseSingleSession = ({
  s,
  isSubSession = false,
  currentTimezone,
  printOptions,
  isAllCodesPresentInAllLevels,
}) => {
  const isSpeakerNameAbbreviated = printOptions.find(
    (po) => po.key === constants.PrintOptions.attendeeName.key
  )?.position?.[constants.PrintOptions.attendeeName.options.position[0].key];

  /**
   * Common Properties
   */
  const toReturn = {
    ...s,
    sessionStartEndTime: `${customHourFormat(
      s.start,
      currentTimezone
    )} - ${customHourFormat(s.end, currentTimezone)}`,
    attendeeRoles: s.attendeeRoles?.items?.length
      ? s.attendeeRoles.items.map((a) => ({
          //used below on render
          originalAttendeeRole: a,
          //used below on render
          role: a.role?.frontofficeName || a.role.name || '',
          attendees: a.attendees?.items
            .map((att) =>
              isSpeakerNameAbbreviated
                ? `${att.participation.givenName
                    .charAt(0)
                    .toUpperCase()}. ${utilities.capitalize(
                    att.participation.familyName
                  )}`
                : `${utilities.capitalize(
                    att.participation.givenName
                  )} ${utilities.capitalize(att.participation.familyName)}`
            )
            .join(', '),
        }))
      : [],
    /**
     * Only Session Properties
     */
    ...(!isSubSession && {
      room: utilities.capitalize(s.room?.name), // used for byTimeRowsTemplate
      sessionStartEndTime: `${customHourFormat(
        s.start,
        currentTimezone
      )} - ${customHourFormat(s.end, currentTimezone)}`,
      type: 'session',
      sessionCode: s.code || '',
      sessionTitle: s.name.toUpperCase(),
      date: s.date ? customDateFormat(s.date) : '',
      sessionTypology: s.sessionTypology?.name || '',
      sessionDescription: s.shortDescription || '',
      sponsor: s.symposium?.sponsor
        ? `lecture supported by ${s.symposium.sponsor.name}`
        : '',
      sessionAttendees: getRolesAndAttendees({
        printOptions,
        section: s,
      }),
    }),
    /**
     * Only SubSessions Properties
     */
    ...(isSubSession && {
      ...(!s.isCoincideEnabled && {
        subSessionStartEndTime: `${customHourFormat(
          s.start,
          currentTimezone
        )} - ${customHourFormat(s.end, currentTimezone)}`,
      }),
      // startEndTime: JSON.stringify(!s.isCoincideEnabled),
      type: 'subSession',
      subSessionCode: s.code || '',
      subSessionTitle: s.name,
      subSessionDescription: s.shortDescription || '',
      sponsor: s.symposium?.sponsor
        ? `lecture supported by ${s.symposium.sponsor.name}`
        : '',
      subSessionAttendees: getRolesAndAttendees({
        printOptions,
        section: s,
        attendeesToFilter: s?.abstract?.authors?.items.map(
          (author) => author.id
        ),
      }),
      interventions: s.speeches?.items?.length
        ? s.speeches?.items
            .sort((a, b) => {
              if (isAllCodesPresentInAllLevels) {
                return a.code > b.code ? 1 : -1;
              } else {
                // sort by time string of type 00:00
                return utcToZonedTime(new Date(a.start), currentTimezone) >
                  utcToZonedTime(new Date(b.start), currentTimezone)
                  ? 1
                  : -1;
              }
            })

            .map((speech) => ({
              type: 'speech',
              speechType: speech.type || '',
              speechCode: speech.code || '',
              speechTitle: speech.abstract
                ? speech.abstract.title
                : speech.title,
              speechDescription: speech.titleDescription || '',
              speechStartEndTime: `${customHourFormat(
                speech.start,
                currentTimezone
              )}-${customHourFormat(speech.end, currentTimezone)}`,
              //used below on render
              attendeeRoles: speech.attendeeRoles.items.map((ar) => ({
                originalAttendeeRole: ar,
              })),
              attendees: getRolesAndAttendees({
                printOptions,
                section: speech,
                attendeesToFilter: speech?.abstract?.authors?.items.map(
                  (author) => author.id
                ),
              }),
              abstractAttendees: generateAbstractAttendees(
                speech,
                printOptions
              ),
            }))
        : [],
      hasAbstract: s.speeches?.items?.findIndex((x) => x.abstract) > -1,
    }),
  };
  return toReturn;
};

const parseSession = ({
  session,
  currentTimezone,
  printOptions,
  isAllCodesPresentInAllLevels,
}) => {
  const nextSubSession = session.subSessions?.items?.length
    ? session.subSessions?.items.sort((a, b) => {
        if (isAllCodesPresentInAllLevels) {
          return a.code > b.code ? 1 : -1;
        } else {
          // sort by time string of type 00:00
          return utcToZonedTime(new Date(a.start), currentTimezone) >
            utcToZonedTime(new Date(b.start), currentTimezone)
            ? 1
            : -1;
        }
      })
    : [];

  const sessionParsed = {
    ...parseSingleSession({
      s: session,
      isSubSession: false,
      currentTimezone,
      printOptions,
      isAllCodesPresentInAllLevels,
    }),
    subSessions: nextSubSession.map((sub) =>
      parseSingleSession({
        s: sub,
        isSubSession: true,
        currentTimezone,
        printOptions,
        isAllCodesPresentInAllLevels,
      })
    ),
  };
  return sessionParsed;
};

// BY ROOMS MODEL
const generateOrderByRoomsModel = ({
  sessions,
  currentTimezone,
  printOptions,
  isAllCodesPresentInAllLevels,
}) => {
  // test slowly typing as Eugenio says
  // sessions = [...sessions, ...sessions, ...sessions, ...sessions];
  const orderedByDate = sessions.sort(
    (a, b) =>
      utcToZonedTime(new Date(a.start), currentTimezone) -
      utcToZonedTime(new Date(b.start), currentTimezone)
  );

  const groupByDate = orderedByDate.reduce((acc, curr) => {
    const index = acc.findIndex(
      (item) => item.date === customDateFormat(curr.date)
    );
    index > -1
      ? acc[index].rooms.push(curr)
      : acc.push({
          date: customDateFormat(curr.date),
          rooms: [curr],
        });

    return acc;
  }, []);

  const groupByRooms = groupByDate.map((day) => {
    day.rooms.sort((a, b) => (a.room.odering > b.room.odering ? 1 : -1));
    return {
      date: day.date,
      rooms: day.rooms.reduce((acc, curr) => {
        const index = acc.findIndex((item) => item.room === curr.room.name);
        index > -1
          ? acc[index].sessions.push(
              parseSession({
                session: curr,
                currentTimezone,
                printOptions,
                isAllCodesPresentInAllLevels,
              })
            )
          : acc.push({
              room: utilities.capitalize(curr.room.name),
              sessions: [
                parseSession({
                  session: curr,
                  currentTimezone,
                  printOptions,
                  isAllCodesPresentInAllLevels,
                }),
              ],
            });
        return acc;
      }, []),
    };
  });

  const orderedByRooms = groupByRooms.map((obj) => ({
    date: obj.date,
    // rooms: obj.rooms.sort((a, b) => a.room.localeCompare(b.room)),
    rooms: obj.rooms.sort((a, b) => {
      if (isAllCodesPresentInAllLevels) {
        return a.code > b.code ? 1 : -1;
      } else {
        // sort by time string of type 00:00
        return utcToZonedTime(new Date(a.start), currentTimezone) >
          utcToZonedTime(new Date(b.start), currentTimezone)
          ? 1
          : -1;
      }
    }),
  }));

  return orderedByRooms;
};

const renderRolesAndAttendees = (section, isFrontOffice) => {
  const attendees = section.attendeeRoles.flatMap(
    ({ originalAttendeeRole: attRole }) => {
      const nextAttendees = attRole.attendees.items
        .reduce((res, curr) => {
          let nationality = '';
          if (curr.participation.user) {
            nationality = ` (${curr.participation.user.nationality})`;
          }
          return `${res} ${utilities.capitalize(
            curr.participation.givenName
          )} ${utilities.capitalize(
            curr.participation.familyName
          )}${nationality},`;
        }, '')
        .slice(0, -1);
      if (attRole.role?.showInProgram === null || attRole.role?.showInProgram) {
        return (
          <div style={{ display: 'flex' }}>
            {`${utilities.capitalize(
              isFrontOffice && attRole.role?.frontofficeName
                ? attRole.role?.frontofficeName
                : attRole.role?.name
            )}: ${nextAttendees}`}
          </div>
        );
      }
      return <div style={{ display: 'flex' }}>{nextAttendees}</div>;
    }
  );
  return attendees;
};

const onClickDownload = (eventId, sessions) => {
  history.push({
    pathname: `/events/${eventId}/agenda/print-program`,
    state: {
      sessions: sessions.map((session) => session.id),
      sorting: 'byRoom',
    },
  });
};

export const ProgramTemplate = ({
  intl,
  sessions,
  eventId,
  isFrontOffice,
  hideDownloadPdfAndTitle = true,
  title = '',
  onlyInterventions,
  inputPrintOptions,
  titleProps = {},
  cardProps = {},
}) => {
  const [currentTimezone, setCurrentTimezone] = useState();
  const [isCodesMode, setIsCodesMode] = useState(false);
  const [rowTemplate, setRowTemplate] = useState();
  const [printOptions, setPrintOptions] = useState();
  const [model, setModel] = useState();

  useEffect(() => {
    const subscriptionEvent = appState.eventInfo.subscribe((info) => {
      setCurrentTimezone(info.timezone);
    });
    return () => {
      subscriptionEvent.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const handlePrintOptions = async (eventId) => {
      let nextPrintOptions = inputPrintOptions;
      if (!nextPrintOptions) {
        nextPrintOptions = await getEventPrintOptions(eventId);
      }
      const isSubSessionStartEndToRightOfTitle = nextPrintOptions.find(
        (nextPo) =>
          nextPo.key === constants.PrintOptions.subSessionBlockStartEndTime.key
      )?.position?.[
        constants.PrintOptions.subSessionBlockStartEndTime.options.position[0]
          .key
      ];

      const isGeneralSessionStartEndToRightOfTitle = nextPrintOptions.find(
        (nextPo) =>
          nextPo.key === constants.PrintOptions.generalSessionStartEndTime.key
      )?.position?.[
        constants.PrintOptions.generalSessionStartEndTime.options.position[0]
          .key
      ];
      let isSpeechStartEndTimeToRight = nextPrintOptions.find(
        (nextPo) =>
          nextPo.key ===
          constants.PrintOptions.interventionBlockStartEndTime.key
      )?.position?.[
        constants.PrintOptions.interventionBlockStartEndTime.options.position[0]
          .key
      ];

      const abstractAuthorsPrintOption = nextPrintOptions.find(
        (nextPo) => nextPo.key === constants.PrintOptions.abstractAuthors.key
      );
      const abstractAuthorsPositionValues = Object.values(
        constants.PrintOptions.abstractAuthors.options.position
      );

      // abstract authors: underline the presenter
      const underlineThePresenterKey = abstractAuthorsPositionValues.find(
        (x) => x.key === 'underlineThePresenter'
      )?.key;
      const isUnderlineThePresenter =
        abstractAuthorsPrintOption?.position?.[underlineThePresenterKey];

      setPrintOptions(nextPrintOptions);

      const nextByRoomRowsTemplate = byRoomsRowsTemplate({
        position: {
          isGeneralSessionStartEndToRightOfTitle,
          isSubSessionStartEndToRightOfTitle,
          isSpeechStartEndTimeToRight,
          isUnderlineThePresenter,
        },
      });
      setRowTemplate(nextByRoomRowsTemplate);

      const isAllCodesPresentInAllLevels = sessions?.items?.every(
        (session) =>
          session.code &&
          session?.subSessions?.items?.every(
            (subSession) =>
              subSession.code &&
              subSession.speeches?.items?.every((speech) => speech.code)
          )
      );

      const model = generateOrderByRoomsModel({
        sessions: sessions.items,
        currentTimezone,
        printOptions: nextPrintOptions,
        isAllCodesPresentInAllLevels,
      });

      setModel(model);
      setIsCodesMode(isAllCodesPresentInAllLevels);
      // getSpeakers(eventId).then((speakers) => {
      //   const nextSpeakers = speakers.sort((a, b) =>
      //     a.familyName.toLowerCase() > b.familyName.toLowerCase() ? 1 : -1
      //   );
      //   return setSpeakers(nextSpeakers);
      // })
    };

    if (sessions?.items?.length && currentTimezone) {
      handlePrintOptions(eventId);
    }
  }, [currentTimezone, inputPrintOptions]);

  const i18n = translation.programTemplate(intl);
  return (
    <>
      {!hideDownloadPdfAndTitle ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <AimTypography variant="h1" {...titleProps}>
            {title}
          </AimTypography>
          {rowTemplate && model && printOptions?.length ? (
            <AimReactPdfRenderer
              rowsTemplate={rowTemplate}
              model={model}
              printOptions={printOptions}
              defaultFontSize={10}
              preview={false}
            />
          ) : null}
        </div>
      ) : null}
      <CardsWrapper>
        <Card {...cardProps}>
          <Grid container>
            {model?.map(({ date, rooms }) => (
              <Grid item xs={12} key={date}>
                {!onlyInterventions ? (
                  <AimTypography variant="text" margin="0 0 8px 0px">
                    {date}
                  </AimTypography>
                ) : null}
                {rooms.map(({ room, sessions: roomSessions }) => (
                  <>
                    {!onlyInterventions ? (
                      <AimTypography variant="textBold" margin={0}>
                        {room}
                      </AimTypography>
                    ) : null}

                    {roomSessions?.map((session) => {
                      const attendeesOfSession = renderRolesAndAttendees(
                        session,
                        isFrontOffice
                      );

                      return (
                        <>
                          {!onlyInterventions ? (
                            <div style={{ display: 'flex' }}>
                              <AimTypography
                                variant="text"
                                margin="0px"
                                boxStyle={{ minWidth: 60 }}
                              >
                                {`${
                                  isCodesMode
                                    ? session.sessionCode
                                    : session.sessionStartEndTime
                                }`}
                              </AimTypography>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  margin: '0 0 0 8px',
                                }}
                              >
                                <AimTypography variant="textBold" margin="0px">
                                  {`${
                                    isCodesMode
                                      ? `${session.sessionStartEndTime} `
                                      : ''
                                  }${session.sessionTitle}`}
                                </AimTypography>

                                {session.sessionDescription ? (
                                  <AimTypography margin="0px" variant="text">
                                    {session.sessionDescription}
                                  </AimTypography>
                                ) : null}
                                <AimTypography margin="0px" variant="text">
                                  {attendeesOfSession}
                                </AimTypography>
                              </div>
                            </div>
                          ) : null}
                          {session?.subSessions.map((subSession) => {
                            const attendeesOfSubSession = renderRolesAndAttendees(
                              subSession,
                              isFrontOffice
                            );

                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                                key={subSession.id}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                  }}
                                >
                                  {isCodesMode ? (
                                    <AimTypography
                                      margin="0px"
                                      variant="text"
                                      boxStyle={{
                                        minWidth: 60,
                                      }}
                                    >
                                      {subSession.subSessionCode}
                                    </AimTypography>
                                  ) : (
                                    <AimTypography
                                      margin="0px"
                                      variant="text"
                                      boxStyle={{
                                        minWidth: 60,
                                      }}
                                    >
                                      {subSession.subSessionStartEndTime}
                                    </AimTypography>
                                  )}
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      margin: '0px 0px 0px 8px',
                                    }}
                                  >
                                    {!subSession.isCoincideEnabled && (
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                          }}
                                        >
                                          <AimTypography
                                            variant="textBold"
                                            margin="0px"
                                          >
                                            {`${
                                              isCodesMode
                                                ? `${subSession.subSessionStartEndTime} `
                                                : ''
                                            }${subSession.subSessionTitle}`}
                                          </AimTypography>
                                        </div>
                                        <AimTypography
                                          variant="text"
                                          margin="0px"
                                        >
                                          {attendeesOfSubSession}
                                        </AimTypography>
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        display: 'flex',
                                        marginTop: 5,
                                        flexDirection: 'column',
                                      }}
                                    >
                                      {subSession?.interventions.map(
                                        (speech) => {
                                          // not necessary
                                          // const title =
                                          //   speech.type === 'ABSTRACT'
                                          //     ? speech.abstract?.title
                                          //     : speech.title;
                                          const attendeesOfSpeech = renderRolesAndAttendees(
                                            speech,
                                            isFrontOffice
                                          );

                                          return (
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                              }}
                                              key={speech.id}
                                            >
                                              <div
                                                style={{
                                                  display: 'flex',
                                                }}
                                              >
                                                {isCodesMode ? (
                                                  <AimTypography
                                                    variant="text"
                                                    margin="0px"
                                                    boxStyle={{
                                                      minWidth: 60,
                                                    }}
                                                  >
                                                    {speech.speechCode}
                                                  </AimTypography>
                                                ) : (
                                                  <AimTypography
                                                    variant="text"
                                                    margin="0px"
                                                    boxStyle={{
                                                      minWidth: 60,
                                                    }}
                                                  >
                                                    {speech.speechStartEndTime}
                                                  </AimTypography>
                                                )}

                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    margin: '0px 0px 0px 8px',
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                    }}
                                                  >
                                                    <AimTypography
                                                      {...{
                                                        variant: speech.speechTitle
                                                          ? 'textBold'
                                                          : 'text',
                                                        margin: '0px',
                                                        style: {
                                                          ...(!speech.speechTitle && {
                                                            fontStyle: 'italic',
                                                            color: 'gray',
                                                          }),
                                                        },
                                                      }}
                                                    >
                                                      {`${
                                                        isCodesMode
                                                          ? `${speech.speechStartEndTime} `
                                                          : ''
                                                      }${
                                                        speech.speechTitle
                                                          ? speech.speechTitle
                                                          : i18n.noTitleOfIntervention
                                                      }`}
                                                    </AimTypography>
                                                    <AimTypography
                                                      variant="textBold"
                                                      margin="0px 0px 0px 8px"
                                                      style={{
                                                        color:
                                                          theme.colors.secondary
                                                            .lightBlue,
                                                      }}
                                                    >
                                                      {speech.speechDescription}
                                                    </AimTypography>
                                                  </div>
                                                  <AimTypography
                                                    variant="text"
                                                    margin="0px"
                                                  >
                                                    {attendeesOfSpeech}
                                                  </AimTypography>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      );
                    })}
                  </>
                ))}
              </Grid>
            ))}
          </Grid>
        </Card>
        {/* <AimIconAndTextButton
        variant={'lightBlue'}
        text={i18n.download}
        onClick={() => onClickDownload(eventId, sessions)}
      >
        <GetAppIcon />
      </AimIconAndTextButton> */}
      </CardsWrapper>
    </>
  );
};
