import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const listGenericPaymentQuery = /* GraphQL */ `
  query ListGenericPayment($id: ID!) {
    getEvent(id: $id) {
      genericPayments(filter: { isDeleted: { eq: "false" } }) {
        items {
          client
          cluster
          description
          isDeleted
          netAmount
          orderCode
          orderDate
          id
          vatRate
          totalAmount
          participation {
            id
            familyName
            givenName
          }
          agencyGroup {
            id
            name
          }
          sponsor {
            id
            name
          }
          vat {
            id
            amount
            description
          }
          payment {
            id
            amount
            paymentStatus
            date
            updatedAt
            paymentId
            paymentType
          }
        }
      }
    }
  }
`;

export const listGenericPayment = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: listGenericPaymentQuery,
      variables: { id },
    })
      .then((response) => resolve(response.data.getEvent.genericPayments))
      .catch((e) => {
        console.error('get-sponsor-invoices', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
