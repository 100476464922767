import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import ArrowBack from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import {
  theme,
  AimTypography,
  AimIconAndTextButton,
  AimTitleAndButtons,
  useAimMediaQuery,
  CustomIntl,
  AimSnackbar,
  AimSnackbarSeverity,
} from '@aim/components';
import useI18n from './i18n';
import { listCertifications } from '../shared/papersGqlHelper';
import { listBillingDocsByClientId as fetchMyInvoices } from '../shared/billingGqlHelper';

import {
  getMyTickets,
  translations,
  MainContainer,
  TabWrapper,
  ServiceCard,
  CertificationsCardContainer,
  getMyAllotment,
  getMyAdditionalServices,
  getMyCertifications,
} from './Services/index';

import {
  getOthersParticipantAvailableServices,
  getProductsByClientIdServiceId,
} from './profileHelper';
import { appState, constants, utilities } from '@aim/common';
const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const {
  REGISTRATION: { key: RegistrationKey },
  ALLOTMENT: { key: AllotmentKey },
  SOCIAL_EVENT: { key: SocialEventKey },
  SCIENTIFIC_EVENT: { key: ScientificEventKey },
} = constants.Services;
const {
  ABSTRACT: { key: AbstractKey },
  EDUCATIONAL: { key: EducationalKey },
} = constants.GrantType;

const Services = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const i18nMyServices = translations(intl);
  const { eventId, participationId } = useParams();
  const history = useHistory();
  const { isMobile, isMobileOnly } = useAimMediaQuery();

  const [ticket, setTicket] = useState();
  const [, setProducts] = useState();
  const [certifications, setCertifications] = useState([]);
  const [servicesTypeInProds, setServicesTypeInProds] = useState([]);
  const [myInvoices, setMyInvoices] = useState([]);
  const [selectedTabValue, setSelectedTabValue] = useState(RegistrationKey);
  const [tabPanelData, setTabPanelData] = useState({});
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  useEffect(() => {
    const fetchProductsByClientIdServiceId = async () => {
      try {
        const products = await getProductsByClientIdServiceId(
          participationId,
          eventId
        );

        const otherAvailableServices = await getOthersParticipantAvailableServices(
          participationId
        );
        if (products) {
          const ticketProduct = products
            .filter(
              (prod) => prod.serviceType === constants.Services.REGISTRATION.key
            )
            .filter((product) => utilities.isValidPayment(product.payment));
          setTicket((ticketProduct.length && ticketProduct[0].id) || false);
          setProducts(products);

          const uniqueServiceTypes = [
            ...new Set(
              [...products, ...otherAvailableServices].map(
                (item) => item.serviceType
              )
            ),
          ];

          setServicesTypeInProds(uniqueServiceTypes);
        }
      } catch (err) {
        console.error(err);
      }
    };
    const fetchCertificationsData = async () => {
      const event = await listCertifications(eventId, participationId);
      console.log('papers', event.papers);
      console.log(
        'papers',
        appState.user.getValue()?.userAndParticipation?.participation
          ?.filledPapers?.items
      );
      const nextPapers = [
        ...event.papers.items,
        ...(appState.user.getValue()?.userAndParticipation?.participation
          ?.filledPapers?.items || []),
      ];
      if (nextPapers.length) {
        setCertifications(nextPapers);
      }
    };
    fetchProductsByClientIdServiceId();
    fetchMyInvoices(participationId).then(setMyInvoices);

    fetchCertificationsData();
    checkoutErrorResponse();
  }, []);

  const checkoutErrorResponse = () => {
    console.log('checkoutErrorResponse');
    const query = new URLSearchParams(location.search);
    const errorcode = query.get('errorcode');
    const order = query.get('merchantorderid');
    console.log('errorcode', errorcode);
    if (errorcode && errorcode > 0) {
      let messageError = '';
      switch (parseInt(errorcode)) {
        case 1: // query error in lambda
          messageError = `${i18n.errors.error1} ${order || ''}`;
          break;
        case 2: // response error in lambda
          messageError = `${i18n.errors.error2} ${order || ''}`;
          break;
        case 3: // error in lambda, init payment
          messageError = `${i18n.errors.error3} ${order || ''}`;
          break;
        case 4: // duplicate order or other errors
          messageError = `${i18n.errors.error4} ${order || ''}`;
          break;
        default:
          break;
      }
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: messageError,
      });
    }
  };

  useEffect(() => {
    if (selectedTabValue && !tabPanelData[selectedTabValue]) {
      fetchTabContent();
    }
  }, [selectedTabValue]);

  const fetchTabContent = async () => {
    showLoader();
    let _nextTabPanelData;
    switch (selectedTabValue) {
      case RegistrationKey:
        _nextTabPanelData = await getMyTickets({
          clientId: participationId,
          eventId,
          intl,
          translations: i18nMyServices.myTickets,
        });
        break;
      case AllotmentKey:
        _nextTabPanelData = await getMyAllotment({
          participationId,
          eventId,
          intl,
        });
        break;
      case SocialEventKey:
        _nextTabPanelData = await getMyAdditionalServices({
          participationId,
          eventId,
          serviceType: SocialEventKey,
          intl,
        });
        break;
      case ScientificEventKey:
        _nextTabPanelData = await getMyAdditionalServices({
          participationId,
          eventId,
          serviceType: ScientificEventKey,
          intl,
        });
        break;
      case 'CERTIFICATIONS':
        _nextTabPanelData = await getMyCertifications({
          participationId,
          eventId,
        });
        break;
      default:
        _nextTabPanelData = 'Content Not Available';
        break;
    }
    setTabPanelData((prev) => ({
      ...prev,
      [selectedTabValue]: _nextTabPanelData,
    }));
    hideLoader();
  };

  const TabsData = {
    ...(servicesTypeInProds.includes(constants.Services.REGISTRATION.key) && {
      [RegistrationKey]: {
        tabTitle: i18n.page.registrations,
        children: tabPanelData[RegistrationKey]?.map((service, idx) => (
          <ServiceCard
            key={`${RegistrationKey}-${idx}`}
            cardData={service}
            qrCodeKey={'__code'}
            cardTitle={i18nMyServices.myTickets.cardTitle}
            serviceDetailLink={
              service &&
              service.find((data) => data['__id']) &&
              Object.values(service.find((data) => data['__id']))
                ? `/events/${eventId}/user/${participationId}/my-ticket/${
                    Object.values(service.find((data) => data['__id']))[0]
                  }`
                : ''
            }
          />
        )),
      },
    }),
    ...(servicesTypeInProds.includes(constants.GrantType.ABSTRACT.key) && {
      [AbstractKey]: {
        tabTitle: i18n.services.abstractGrantTitle,
        children: <div>ABSTRACT</div>,
      },
    }),
    ...(servicesTypeInProds.includes(constants.GrantType.EDUCATIONAL.key) && {
      [EducationalKey]: {
        tabTitle: i18n.services.educationalGrantTitle,
        children: <div>EDUCATIONAL</div>,
      },
    }),
    ...(servicesTypeInProds.includes(constants.Services.ALLOTMENT.key) && {
      [AllotmentKey]: {
        tabTitle: i18n.services.allotmentTitle,
        children: tabPanelData[AllotmentKey]?.map((service, idx) => (
          <ServiceCard
            key={`${AllotmentKey}-${idx}`}
            cardData={service}
            cardTitle={i18nMyServices.myTickets.allotmentTitle}
            serviceDetailLink={
              service &&
              service.find((data) => data['productId']) &&
              Object.values(service.find((data) => data['productId']))
                ? `/events/${eventId}/user/${participationId}/my-allotment/${
                    Object.values(service.find((data) => data['productId']))[0]
                  }`
                : ''
            }
          />
        )),
      },
    }),
    ...(servicesTypeInProds.includes(constants.Services.SOCIAL_EVENT.key) && {
      [SocialEventKey]: {
        tabTitle: i18n.services.socialEvents,
        children: tabPanelData[SocialEventKey]?.map(
          (service, idx) =>
            console.log('service', service) || (
              <ServiceCard
                key={`${SocialEventKey}-${idx}`}
                cardData={service}
                cardTitle={i18nMyServices.myTickets.socialEventsTitle}
                serviceDetailLink={
                  service &&
                  service.find((data) => data.productId) &&
                  Object.values(service.find((data) => data.productId))
                    ? `/events/${eventId}/user/${participationId}/additional-services/social/${
                        Object.values(service.find((data) => data.productId))[0]
                      }`
                    : ''
                }
                imgLink={
                  service &&
                  service.find((data) => data.image) &&
                  Object.values(service.find((data) => data.image))
                    ? Object.values(service.find((data) => data.image))[0]
                    : ''
                }
              />
            )
        ),
      },
    }),
    ...(servicesTypeInProds.includes(
      constants.Services.SCIENTIFIC_EVENT.key
    ) && {
      [ScientificEventKey]: {
        tabTitle: i18n.services.scientificEvents,
        children: tabPanelData[ScientificEventKey]?.map((service, idx) => (
          <ServiceCard
            key={`${ScientificEventKey}-${idx}`}
            cardData={service}
            cardTitle={i18nMyServices.myTickets.scientificEventsTitle}
            serviceDetailLink={
              service &&
              service.find((data) => data.productId) &&
              Object.values(service.find((data) => data.productId))
                ? `/events/${eventId}/user/${participationId}/additional-services/scientific/${
                    Object.values(service.find((data) => data.productId))[0]
                  }`
                : ''
            }
            imgLink={
              service &&
              service.find((data) => data.image) &&
              Object.values(service.find((data) => data.image))
                ? Object.values(service.find((data) => data.image))[0]
                : ''
            }
          />
        )),
      },
    }),
    ...(certifications.length > 0 && {
      ['CERTIFICATIONS']: {
        tabTitle: i18n.services.certificateListTitle,
        children: (
          <CertificationsCardContainer
            certifications={tabPanelData['CERTIFICATIONS']}
            downloadText={i18nMyServices.myTickets.donwloadBtn}
            subTitle={i18nMyServices.myTickets.certificationTitle}
          />
        ),
      },
    }),
  };

  // const listServices = (i18n) => [
  //   {
  //     ...(servicesTypeInProds.includes(constants.Services.REGISTRATION.key) && {
  //       key: RegistrationKey,
  //       title: i18n.page.registrations,
  //       description: i18n.services.myTicketDesc,
  //       ticket,
  //       disableFunc: () => !ticket,
  //       onClick: () =>
  //         history.push(`/events/${eventId}/user/${participationId}/my-tickets`),
  //     }),
  //   },

  //   {
  //     ...(servicesTypeInProds.includes(constants.GrantType.ABSTRACT.key) && {
  //       key: AbstractKey,
  //       title: i18n.services.abstractGrantTitle,
  //       description: i18n.services.abstractGrantDesc,
  //       onClick: () =>
  //         history.push(
  //           `/events/${eventId}/user/${participationId}/my-grants/${constants.GrantType.ABSTRACT.key}`
  //         ),
  //     }),
  //   },
  //   {
  //     ...(servicesTypeInProds.includes(constants.GrantType.EDUCATIONAL.key) && {
  //       key: EducationalKey,
  //       title: i18n.services.educationalGrantTitle,
  //       description: i18n.services.educationalGrantDesc,
  //       onClick: () =>
  //         history.push(
  //           `/events/${eventId}/user/${participationId}/my-grants/${constants.GrantType.EDUCATIONAL.key}`
  //         ),
  //     }),
  //   },
  //   {
  //     ...(servicesTypeInProds.includes(constants.Services.ALLOTMENT.key) && {
  //       key: AllotmentKey,
  //       title: i18n.services.allotmentTitle,
  //       description: i18n.services.allotmentDesc,
  //       ticket,
  //       disableFunc: () => !ticket,
  //       onClick: () =>
  //         history.push(
  //           `/events/${eventId}/user/${participationId}/my-allotment`
  //         ),
  //     }),
  //   },

  //   {
  //     ...((servicesTypeInProds.includes(constants.Services.SOCIAL_EVENT.key) ||
  //       servicesTypeInProds.includes(
  //         constants.Services.SCIENTIFIC_EVENT.key
  //       )) && {
  //       key: SocialEventKey,
  //       title: i18n.services.additionalServiceTitle,
  //       description: i18n.services.additionalServiceDesc,
  //       ticket,
  //       disableFunc: () => !ticket,
  //       onClick: () =>
  //         history.push(
  //           `/events/${eventId}/user/${participationId}/additional-services`
  //         ),
  //     }),
  //   },
  //   {
  //     ...(certifications.length > 0 && {
  //       title: i18n.services.certificateListTitle,
  //       description: i18n.services.certificateListDesc,
  //       key: 'CERTIFICATIONS',
  //       onClick: () =>
  //         history.push(
  //           `/events/${eventId}/user/${participationId}/certifications`
  //         ),
  //     }),
  //   },
  // ];

  const Tab = ({ value, children, ...other }) => (
    <div
      {...other}
      style={{
        height: '50px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%',
        minWidth: isMobile ? 'unset' : 200,
        fontWeight: selectedTabValue === value ? 'bold' : 'normal',
        borderBottom: `${selectedTabValue === value ? 2 : 1}px solid ${
          selectedTabValue === value
            ? theme.colors.dark.yellow
            : theme.colors.secondary.lightGrey
        }`,
        fontSize: '18px',
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      {children}
    </div>
  );

  return (
    <MainContainer isMobileOnly={isMobileOnly}>
      <div style={{ width: '100%' }}>
        <AimIconAndTextButton
          variant="none"
          onClick={() => {
            history.push(`/events/${eventId}/user/${participationId}`);
          }}
        >
          <ArrowBack style={{ color: theme.colors.greyScale.grey3 }} />
          <AimTypography variant="text">{i18n.actions.back}</AimTypography>
        </AimIconAndTextButton>
        <AimTitleAndButtons title={i18n.page.myServices} />
      </div>
      <TabWrapper>
        {Object.entries(TabsData).map(([key, value]) => (
          <Tab onClick={() => setSelectedTabValue(key)} value={key} key={key}>
            {value.tabTitle}
          </Tab>
        ))}
      </TabWrapper>
      {TabsData[selectedTabValue]?.children || <></>}
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </MainContainer>
  );
};

export default Services;
