import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { isMobileOnly } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';

import {
  styled,
  CustomIntl,
  AimTitleAndButtons,
  AimIconAndTextButton,
  AimList,
  AimTypography,
  AimIconButton,
  AimDialog,
  AimSnackbar,
  AimSnackbarSeverity,
} from '@aim/components';
import Grid from '@material-ui/core/Grid';

import { format } from 'date-fns';

import { appState, constants, utilities } from '@aim/common';

import { utcToZonedTime } from 'date-fns-tz';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';

import {
  getProducts,
  getProfileFeeBracket,
  getParticipationInfo,
} from './myTickets/gqlHelper';

import translation from './myTickets/translation';

const { decodeDbNumber, formatNumber } = utilities;

const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: isMobileOnly ? '100%' : '80%',
  padding: '10px',
});

const StyledRow = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-evenly',
  backgroundColor: '#F3F2F6',
  borderRadius: 5,
  // cursor: 'pointer',
  paddingLeft: 5,
  minHeight: 50,
  alignItems: 'center',
});

const ucWords = (str) => {
  str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });
  return str;
};

const fetchProducts = async ({
  clientId,
  eventId,
  serviceType,
  intl,
  formatNumber,
  decodeDbNumber,
}) => {
  const res = await getProducts(clientId, eventId, serviceType);
  if (res) {
    const items = res?.productByClientIdServiceTypeServiceId?.items.filter(
      (p) => utilities.isValidPayment(p.payment)
    );

    const getItem = async ({ item }) => {
      const getFee = await getProfileFeeBracket(item.productId);
      const participationInfo = await getParticipationInfo(clientId);

      const obj = {
        __id: item.id,
        ticketId: item.productId,
        accompanyingPersons:
          item?.guests?.items.length > 0
            ? item?.guests?.items
                .map((guest) => {
                  return `${ucWords(guest?.givenName)} ${ucWords(
                    guest?.familyName
                  )}`;
                })
                .join(', ')
            : `-`,
        code: item.payment?.paymentId || ``,
        profile: ucWords(participationInfo?.profile?.name) || ``,
        participationMode: Object.values(constants.EventTypes)
          .find((x) => x.id === item?.productType)
          ?.label(intl),
        paymentStatus: Object.values(constants.PaymentStatuses)
          .find((x) => x.key === item?.payment?.paymentStatus)
          .label(intl),
        total: item?.payment?.amount
          ? formatNumber(decodeDbNumber(item.payment.amount))
          : '-',
        startDate: getFee?.feeDateRange?.start
          ? format(
              utcToZonedTime(
                new Date(getFee?.feeDateRange?.start),
                currentTimezone
              ),
              'dd/MM/yyyy'
            )
          : '-',
        endDate: getFee?.feeDateRange?.end
          ? format(
              utcToZonedTime(
                new Date(getFee?.feeDateRange?.end),
                currentTimezone
              ),
              'dd/MM/yyyy'
            )
          : '-',
        registrationFee: getFee?.feeDateRange?.label || '-',
        paymentType: item.payment?.paymentType,
        rawData: item || null,
        showPrice: item.showPrice !== false,
      };

      return obj;
    };

    const promises = [];
    items.forEach((item) => {
      promises.push(getItem({ item }));
    });

    const data = await Promise.all(promises);

    return data && data.length > 0 ? data : false;

    // for (const item of items) {
    //   if (item.productId) {
    //     const getFee = await getProfileFeeBracket(item.productId);
    //     const participationInfo = await getParticipationInfo(clientId);
    //     // const city = { label: item.billing?.city, value: item.billing?.city };
    //     // const country = {
    //     //   label: item.billing?.country,
    //     //   value: item.billing?.country,
    //     // };

    //     data.push({
    //       __id: item.id,
    //       ticketId: item.productId,
    //       accompanyingPersons:
    //         participationInfo?.guests?.items.length > 0
    //           ? participationInfo?.guests?.items
    //               .map((guest) => {
    //                 return `${ucWords(guest?.givenName)} ${ucWords(
    //                   guest?.familyName
    //                 )}`;
    //               })
    //               .join(', ')
    //           : `-`,
    //       code: item.payment?.paymentId || ``,
    //       profile: ucWords(participationInfo?.profile?.name) || ``,
    //       paymentStatus:
    //         item?.payment?.paymentType ===
    //           constants.PaymentTypes.BankTransfer &&
    //         item?.paymentStatus !== constants.PaymentStatuses.DONE.key
    //           ? constants.PaymentStatuses.PENDING.label(intl)
    //           : constants.PaymentStatuses.DONE.label(intl),
    //       total: item?.payment?.amount
    //         ? formatNumber(decodeDbNumber(item.payment.amount))
    //         : '-',
    //       startDate:
    //         format(
    //           utcToZonedTime(
    //             new Date(getFee?.feeDateRange?.start),
    //             currentTimezone
    //           ),
    //           'dd/MM/yyyy'
    //         ) || '-',
    //       endDate:
    //         format(
    //           utcToZonedTime(
    //             new Date(getFee?.feeDateRange?.end),
    //             currentTimezone
    //           ),
    //           'dd/MM/yyyy'
    //         ) || '-',
    //       registrationFee: getFee?.feeDateRange?.label || '-',
    //       paymentType: item.payment?.paymentType,
    //       rawData: item || null,
    //     });
    //   }
    // }
  }
};

const Row = ({
  row,
  eventId,
  history,
  intl,
  participationId,
  headers,
  renderActionButton,
  setBookingInfoDialog,
}) => {
  console.log('🚀 ~ row:', row);
  return (
    <StyledRow>
      {headers.map((key) => {
        const style = {
          display: 'inline-block',
          width: `calc(${100 / headers.length}%)`,
        };
        return key.id === '__view' ? (
          <AimTypography ellipsis style={style} variant={'text'} key={key.id}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {renderActionButton({
                paymentType: row.paymentType,
                paymentStatus: row.rawData?.payment?.paymentStatus,
                paymentStatuses: constants.PaymentStatuses,
                paymentTypes: constants.PaymentTypes,
                rowId: row.__id,
                participationId,
                eventId,
                history,
                row,
                setBookingInfoDialog,
              })}
            </div>
          </AimTypography>
        ) : (
          <AimTypography
            ellipsis
            title={row[key.id]}
            style={style}
            variant={'text'}
            key={key.id}
          >
            {!row.showPrice && key.id === 'total' ? '-' : row[key.id]}
          </AimTypography>
        );
      })}
    </StyledRow>
  );
};

const currentTimezone = appState.eventInfo.getValue().timezone;

const ViewButton = ({ eventId, participationId, rowId, history }) => {
  return (
    <AimIconButton
      variant="primary"
      onClick={() => {
        history.push(
          `/events/${eventId}/user/${participationId}/my-ticket/${rowId}`
        );
      }}
    >
      <VisibilityIcon />
    </AimIconButton>
  );
};

const BuyButton = ({
  eventId,
  participationId,
  rowId,
  history,
  row,
  setBookingInfoDialog,
}) => {
  return (
    <AimIconButton
      variant="primary"
      onClick={() => {
        setBookingInfoDialog({ isOpen: true, data: row });
      }}
    >
      <ShoppingCartIcon />
    </AimIconButton>
  );
};

const renderActionButton = ({
  paymentType,
  paymentStatus,
  paymentStatuses,
  paymentTypes,
  eventId,
  participationId,
  rowId,
  history,
  row,
  setBookingInfoDialog,
}) => {
  const card = paymentTypes.CreditCard.toLowerCase().trim();
  const bankTransfer = paymentTypes.BankTransfer.toLowerCase().trim();
  const free = paymentTypes.Free.toLowerCase().trim();

  const pending = paymentStatuses.PENDING.key.toLowerCase().trim();
  const done = paymentStatuses.DONE.key.toLowerCase().trim();
  const cancelled = paymentStatuses.CANCELLED.key.toLowerCase().trim();

  paymentType = paymentType.toLowerCase().trim();
  paymentStatus = paymentStatus.toLowerCase().trim();

  let toRender = null;

  if (paymentType === bankTransfer) {
    switch (paymentStatus) {
      case pending:
      case done:
        toRender = 'view'; //tochange
    }
  } else if (paymentType === card) {
    switch (paymentStatus) {
      case pending:
        toRender = 'buy';
        break;
      case done:
        toRender = 'view';
    }
  } else {
    //free???
    toRender = 'view';
  }

  return toRender === 'view' ? (
    <ViewButton {...{ eventId, participationId, rowId, history }} />
  ) : (
    <BuyButton
      {...{
        eventId,
        participationId,
        rowId,
        history,
        row,
        setBookingInfoDialog,
      }}
    />
  );
};

const goToPayment = ({ data, eventId, history }) => {
  const feeType = data?.rawData?.productType;
  const profileFeeBracketId = data?.rawData?.productId;

  if (!feeType || !profileFeeBracketId) {
    return;
  }

  history.push(
    `/events/${eventId}/tickets/billing-info/${profileFeeBracketId}/${feeType}`,
    {
      prevData: data,
    }
  );

  return;
};

const MyTickets = () => {
  const { eventId, participationId } = useParams();

  const intl = CustomIntl(useIntl());
  const i18n = translation.myTickets(intl);
  const history = useHistory();
  const serviceType = constants.Services.REGISTRATION.key;

  const [ticketsRows, setTicketsRows] = useState([]);

  const [clientId, setClientId] = useState(null);

  const [bookingInfoDialog, setBookingInfoDialog] = useState({ isOpen: false });
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const checkoutErrorResponse = () => {
    const query = new URLSearchParams(location.search);
    const errorcode = query.get('errorcode');
    const order = query.get('merchantorderid');
    if (errorcode && errorcode > 0) {
      let messageError = '';
      switch (parseInt(errorcode)) {
        case 1: // query error in lambda
          messageError = `${i18n.errors.error1} ${order}`;
          break;
        case 2: // response error in lambda
          messageError = `${i18n.errors.error2} ${order}`;
          break;
        case 3: // error in lambda, init payment
          messageError = `${i18n.errors.error3} ${order}`;
          break;
        case 4: // duplicate order or other errors
          messageError = `${i18n.errors.error4} ${order}`;
          break;
        default:
          break;
      }
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: messageError,
      });
    }
  };

  useEffect(() => {
    const user = appState.user.getValue();
    setClientId(user.userAndParticipation.participation.id);

    checkoutErrorResponse();
  }, []);

  const headers = [
    {
      id: 'code',
      name: 'Code',
      label: `Code`,
      numeric: false,
      disablePadding: true,
      sortable: false,
    },
    {
      id: 'profile',
      name: 'Profile',
      label: `Profile`,
      numeric: false,
      disablePadding: true,
      sortable: false,
    },
    {
      id: 'participationMode',
      name: 'Participation Mode',
      label: `Participation Mode`,
      numeric: false,
      disablePadding: true,
      sortable: false,
    },
    {
      id: 'registrationFee',
      name: 'Registration Fee',
      label: `Registration Fee`,
      numeric: false,
      disablePadding: true,
      sortable: false,
    },
    {
      id: 'startDate',
      name: 'Start date',
      label: `Start date`,
      numeric: false,
      disablePadding: true,
      sortable: false,
    },
    {
      id: 'endDate',
      name: 'End date',
      label: `End date`,
      numeric: false,
      disablePadding: true,
      sortable: false,
    },
    {
      id: 'accompanyingPersons',
      name: 'Accompanying persons',
      label: `Accompanying persons`,
      numeric: false,
      disablePadding: true,
      sortable: false,
    },
    {
      id: 'total',
      name: 'Total',
      label: `Total`,
      numeric: false,
      disablePadding: true,
      sortable: false,
    },
    {
      id: 'paymentStatus',
      name: 'Payment status',
      label: `Payment status`,
      numeric: false,
      disablePadding: true,
      sortable: false,
    },
    {
      id: '__view',
      name: 'view',
      label: ``,
      numeric: false,
      disablePadding: true,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!clientId) {
      return;
    }
    fetchProducts({
      clientId,
      eventId,
      serviceType,
      intl,
      formatNumber,
      decodeDbNumber,
    }).then((result) => {
      if (result) {
        setTicketsRows(result);
      }
    });
  }, [clientId]);

  return (
    <>
      <MainContainer>
        <AimDialog
          title={'Booking info'}
          open={bookingInfoDialog.isOpen}
          onClose={() => setBookingInfoDialog({ isOpen: false })}
          hideAgreeButton
          hideCancelButton
          PaperProps={{
            style: {
              paddingRight: 30,
              paddingLeft: 30,
              paddingTop: 20,
              maxWidth: '100%',
            },
          }}
        >
          <Grid
            container
            style={{
              minWidth: '70vw',
              backgroundColor: '#F8F6FA',
              padding: 15,
            }}
          >
            <Grid item xs={4}>
              <Grid item container>
                <Grid item xs={12}>
                  <AimTypography margin={0}>
                    <span style={{ fontWeight: 'bold' }}>{`Code`}</span>
                    {` `}
                    {bookingInfoDialog?.data?.code || '-'}
                  </AimTypography>
                  <AimTypography margin={0}>
                    <span style={{ fontWeight: 'bold' }}>{`Profile`}</span>
                    {` `}
                    {bookingInfoDialog?.data?.profile || '-'}
                  </AimTypography>
                  <AimTypography margin={0}>
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`Participation Mode`}</span>
                    {` `}
                    {bookingInfoDialog?.data?.participationMode || '-'}
                  </AimTypography>
                  <AimTypography margin={0}>
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`Registration Fee`}</span>
                    {` `}
                    {bookingInfoDialog?.data?.registrationFee || '-'}
                  </AimTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <AimTypography margin={0}>
                <span style={{ fontWeight: 'bold' }}>{`Start date`}</span>
                {` `}
                {bookingInfoDialog?.data?.startDate || '-'}
              </AimTypography>
              <AimTypography margin={0}>
                <span style={{ fontWeight: 'bold' }}>{`End date`}</span>
                {` `}
                {bookingInfoDialog?.data?.endDate || '-'}
              </AimTypography>
              <AimTypography margin={0}>
                <span
                  style={{ fontWeight: 'bold' }}
                >{`Accompanying persons`}</span>
                {` `}
                {bookingInfoDialog?.data?.accompanyingPersons || '-'}
              </AimTypography>
            </Grid>
            <Grid item xs={4}>
              <AimTypography margin={0}>
                <span style={{ fontWeight: 'bold' }}>{`Amount`}</span>
                {` `}
                {bookingInfoDialog?.data?.total || '-'}
              </AimTypography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Grid item style={{ padding: 10 }}>
              <AimIconAndTextButton
                variant="secondary"
                text={`cancel`}
                onClick={() => setBookingInfoDialog({ isOpen: false })}
              >
                <ReplayIcon />
              </AimIconAndTextButton>
              <AimIconAndTextButton
                variant="primary"
                text={`continue`}
                onClick={() => {
                  goToPayment({
                    data: bookingInfoDialog?.data,
                    eventId,
                    history,
                  });
                }}
              >
                <CheckIcon />
              </AimIconAndTextButton>
            </Grid>
          </Grid>
        </AimDialog>
        <AimIconAndTextButton
          style={{ justifyContent: 'flex-start' }}
          variant="none"
          text={i18n.back}
          onClick={() => {
            history.push(`/events/${eventId}/user/${participationId}/services`);
          }}
        >
          <ArrowBack />
        </AimIconAndTextButton>
        <AimTitleAndButtons title={`My Services - tickets`} />
        {!isMobileOnly ? (
          <div style={{ paddingTop: 20 }}>
            <AimList
              customScrollbarsStyle={{ paddingTop: 10 }}
              checkable={false}
              deletable={false}
              rows={ticketsRows}
              rowKey="id"
              childrenProps={{ i18n }}
              HeaderRow={headers.map((head) => (
                <AimTypography
                  key={head.id}
                  variant="textBold"
                  style={{
                    width: `calc(${100 / headers.length}%)`,
                  }}
                >
                  {head.label}
                </AimTypography>
              ))}
            >
              <Row
                {...{
                  eventId,
                  history,
                  intl,
                  participationId,
                  headers,
                  renderActionButton,
                  setBookingInfoDialog,
                }}
              />
            </AimList>
          </div>
        ) : (
          <>
            <div
              style={{
                backgroundColor: '#F3F2F6',
                padding: '15px 15px 15px 15px',
              }}
            >
              {ticketsRows.map((x) => {
                return (
                  <Grid item xs={12} key={x.id}>
                    <div
                      style={{
                        padding: 10,
                        textAlign: 'center',
                        backgroundColor: 'white',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <Grid item container>
                        <Grid
                          item
                          xs={12}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            fontWeight: 'bold',
                            paddingBottom: 10,
                          }}
                        >
                          {x.title}
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: 10 }}>
                          <hr
                            style={{
                              backgroundColor: '#e2e2e2',
                              height: 0.5,
                              width: '100%',
                              borderWidth: 0,
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            fontWeight: 'bold',
                            paddingBottom: 10,
                          }}
                        >
                          {`Code`}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                          }}
                        >
                          {x.code}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            fontWeight: 'bold',
                            paddingBottom: 10,
                          }}
                        >
                          {`Profile`}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                          }}
                        >
                          {x.profile}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            fontWeight: 'bold',
                            paddingBottom: 10,
                          }}
                        >
                          {`Participation Mode`}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                          }}
                        >
                          {x.participationMode}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            fontWeight: 'bold',
                            paddingBottom: 10,
                          }}
                        >
                          {`Registration Fee`}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                          }}
                        >
                          {x.registrationFee}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            fontWeight: 'bold',
                            paddingBottom: 10,
                          }}
                        >
                          {`Start date`}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                          }}
                        >
                          {x.startDate}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            fontWeight: 'bold',
                            paddingBottom: 10,
                          }}
                        >
                          {`End date`}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                          }}
                        >
                          {x.endDate}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            fontWeight: 'bold',
                            paddingBottom: 10,
                          }}
                        >
                          {`Accompanying persons`}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                          }}
                        >
                          {x.accompanyingPersons}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            fontWeight: 'bold',
                            paddingBottom: 10,
                          }}
                        >
                          {`Total`}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                          }}
                        >
                          {x.total}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                            fontWeight: 'bold',
                            paddingBottom: 10,
                          }}
                        >
                          {`Payment status`}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            justifyContent: 'flex-start',
                            display: 'flex',
                          }}
                        >
                          {x.paymentStatus}
                        </Grid>

                        <Grid item xs={12} style={{ paddingTop: 10 }}>
                          <AimIconAndTextButton
                            text={i18n.viewDetail}
                            style={{
                              backgroundColor: '#c7f2f2',
                              color: 'black',
                              width: '90%',
                            }}
                            onClick={() => {
                              history.push(
                                `/events/${eventId}/user/${participationId}/my-ticket/${x.__id}`
                              );
                            }}
                          >
                            <ArrowForward />
                          </AimIconAndTextButton>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                );
              })}
            </div>
          </>
        )}
      </MainContainer>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default MyTickets;
