import appState from './appState';
import aws from './aws';
import jszip from 'jszip';
import { saveAs } from 'file-saver';

const { API } = aws;

export const fileSchema = `
  id
  extension
  originalName
  size
`;
/**
 * Handling Sponsor ProfileImage, CoverImage, Documents
 */
export const createFileQuery = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      extension
      originalName
      size
    }
  }
`;
export const deleteFileQuery = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
    }
  }
`;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const handleDirectory = (dirPath) =>
  dirPath ? `${dirPath}${dirPath.slice(-1) === '/' ? '' : '/'}` : '';

/**
 * downloadFromS3
 * @param dirPath: specifica il percorso delle directory su s3 successivo a s3FolderBase
 */
const getS3Link = async (dirPath, fileData, expires) => {
  const link = await aws.Storage.get(
    `${handleDirectory(dirPath)}${fileData.id}${fileData.extension}`,
    {
      contentDisposition:
        'attachment; filename ="' + fileData.originalName + '"',
      expires: expires,
    }
  );
  return link;
};

const getS3LinkWithDownload = async ({ s3Url, dirPath, fileData }) => {
  const file = await aws.Storage.get(
    s3Url
      ? s3Url.split('/public/')?.[1]
      : `${handleDirectory(dirPath)}${fileData.id}${fileData.extension}`,
    {
      contentDisposition:
        'attachment; filename ="' + fileData.originalName + '"',
      download: true,
    }
  );
  return file;
};

const getAwsS3Link = (dirPath, fileData) =>
  `${handleDirectory(dirPath)}${fileData.id}${fileData.extension}`;

const downloadFromS3 = async ({ dirPath, fileData, useLoader = true }) => {
  useLoader && showLoader();
  const link = await getS3Link(dirPath, fileData);
  useLoader && hideLoader();
  window.open(link);
};

const groupBy = (files, key) =>
  files.reduce((prev, curr) => {
    if (prev[curr[key] || '_']?.length) {
      prev[curr[key] || '_'].push(curr);
    } else {
      prev[curr[key] || '_'] = [curr];
    }
    return prev;
  }, {});

const downloadZipFromS3 = async (files, zipName) => {
  const zip = new jszip();
  const grouped = groupBy(files, 'folder');
  const keys = Object.keys(grouped);
  for (let i = 0; i < keys.length; i++) {
    const folder = keys[i] || '_';
    const zipFolder = folder !== '_' ? zip.folder(folder) : zip; // for files in the root of zip

    await Promise.all(
      grouped[folder].map(async ({ s3Url, dirPath, ...el }) => {
        // IF no dirPath or s3 url is local file
        if (dirPath || s3Url) {
          try {
            const file = await getS3LinkWithDownload({
              s3Url,
              dirPath,
              fileData: el,
            });
            file && zipFolder.file(el.originalName, file.Body);
          } catch {
            console.log('file not found', dirPath);
          }
        } else if (el.Body) {
          zipFolder.file(el.originalName, el.Body);
        }
      })
    );
  }

  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, zipName);
};
/**
 * Handling File CRUD
 */

const createFile = ({
  dirPath,
  fileData,
  localFile,
  isPublic = false,
  useLoader = true,
  skipFileDataOnS3Link = false,
  returnFileObject = false,
}) => {
  let nextFileData = fileData;
  if (!nextFileData) {
    const originalName = localFile.name;
    const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
    const extension = matchExtension ? matchExtension[0] : '';
    nextFileData = {
      originalName: originalName,
      extension,
      size: localFile.size,
    };
  }
  return new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: createFileQuery,
      variables: { input: nextFileData },
    })
      .then(async (response) => {
        try {
          const link = skipFileDataOnS3Link
            ? dirPath
            : getAwsS3Link(dirPath, {
                id: response.data.createFile.id,
                extension: nextFileData.extension,
              });
          // if (isPublic) {
          //   await aws.s3.putObject({
          //     key: link,
          //     file: localFile,
          //     isPublic: true,
          //   });
          // } else {
          await aws.Storage.put(link, localFile, { tagging: 'publicFile=yes' });
          // }
        } catch (e) {
          console.error('common-create-s3-file', e);
          reject();
        }
        resolve(
          returnFileObject
            ? response.data.createFile
            : response.data.createFile.id
        );
      })
      .catch((e) => {
        console.error('common-create-file', e);
        reject();
      })
      .finally(() => {
        useLoader && hideLoader();
      });
  });
};

const getPublicFileLink = ({
  dirPath,
  fileData,
  skipFileDataOnS3Link = false,
}) => {
  if (!skipFileDataOnS3Link && !fileData) return;
  if (fileData?.id || skipFileDataOnS3Link) {
    const link = skipFileDataOnS3Link
      ? dirPath
      : getAwsS3Link(dirPath, fileData);
    return aws.s3.getS3ObjectUrl(link);
  } else {
    return window.URL.createObjectURL(fileData);
  }
};

const deleteFile = ({
  dirPath,
  fileData,
  useLoader = true,
  skipFileDataOnS3Link = false,
}) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: deleteFileQuery,
      variables: { input: { id: fileData.id } },
    })
      .then(async (response) => {
        try {
          const link = skipFileDataOnS3Link
            ? dirPath
            : getAwsS3Link(dirPath, fileData);
          await aws.Storage.remove(link);
        } catch (e) {
          console.error('common-delete-s3-file', e);
          reject();
        }
        resolve(response.data.deleteFile);
      })
      .catch((e) => {
        console.error('common-delete-file', e);
        reject();
      })
      .finally(() => {
        useLoader && hideLoader();
      });
  });

const downloadFile = async ({ file, dirPath }) => {
  if (file.id) {
    downloadFromS3({
      dirPath,
      fileData: file,
    });
  } else if (file && !file.id) {
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(file);

    link.download = file.name;
    link.click();
    link.remove();
  }
};

export default {
  createFile,
  deleteFile,
  downloadFromS3,
  getS3Link,
  getAwsS3Link,
  getS3LinkWithDownload,
  downloadZipFromS3,
  downloadFile,
  getPublicFileLink,
};
