import React, { useState, useEffect } from 'react';
import { isAfter, isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { appState, aws, awsmobile } from '@aim/common';
import LoginLayout from './shared/LoginLayout';
import {
  AimIconAndTextButton,
  AimSnackbarSeverity,
  AimSnackbar,
  AimTextField,
  AimTypography,
  CustomIntl,
} from '@aim/components';
import { translations } from './shared/translations';

import { useIntl } from 'react-intl';
import { getMyEventParticipation } from '../../shared/gqlHelper';
import { setUserCognitoIdentityProviderIdentityId } from '../../shared/utilities';
import { isMobile } from 'react-device-detect';

const formControlStyle = { width: 'calc(100% - 10px)' };
const labelStyle = {
  cursor: 'pointer',
  margin: '8px 0 0 8px',
  paddingRight: 20,
};

const StandardLogin = (props) => {
  const intl = CustomIntl(useIntl());
  const i18n = translations.signIn(intl);
  const { eventId } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const [username, setUsername] = useState();
  const [pass, setPass] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const { isLoader } = appState;

  const setIsWorking = (state) => {
    isLoader.next(state);
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const username = params.get('username');
    const temporaryPassword = params.get('temporaryPassword');

    if (username && temporaryPassword) {
      setUsername(username);
      setPass(temporaryPassword);
      login({
        e: null,
        autoLogin: {
          username: username,
          password: temporaryPassword,
        },
      });
    }
  }, []);

  const login = async ({ e, autoLogin = null }) => {
    const nextUsername = !autoLogin ? username : autoLogin.username;
    const nextPassword = !autoLogin ? pass : autoLogin.password;
    try {
      e?.preventDefault();
      setIsWorking(true);
      try {
        // await aws.Auth.signOut({ global: true });
      } catch (error) {
        console.log('error signing out: ', error);
      }
      let result = await aws.Auth.signIn(nextUsername, nextPassword);

      if (result) {
        props.config.setUser(result);
        if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
          props.config.setWindow('NEW_PASSWORD_REQUIRED');
        } else {
          const cognitoUserId = result.attributes.sub;
          const userAndParticipation = await getMyEventParticipation(
            cognitoUserId,
            eventId
          );
          if (userAndParticipation) {
            //existing participation setting session id
            await setUserCognitoIdentityProviderIdentityId(
              userAndParticipation.participation.id,
              result.storage[
                `CognitoIdentityId-${awsmobile.aws_cognito_identity_pool_id}`
              ]
            );
          }
          window.dataLayer.push({
            event: 'login',
            event_id: eventId,
            event_name: appState.eventInfo.getValue()?.name,
            username: userAndParticipation?.participation?.username,
            name: userAndParticipation?.participation?.givenName,
            suname: userAndParticipation?.participation?.familyName,
            email: userAndParticipation?.participation?.email,
          });
          history.push(
            history.location.state?.prevPathname || `/events/${eventId}/landing`
          );
        }
      }
      setIsWorking(false);
    } catch (err) {
      let message;
      let openSnackbar = true;
      if (err.code === 'InvalidParameterException') {
        message = i18n.userNotExist;
      } else if (err.code === 'UserNotConfirmedException') {
        history.push(`/events/${eventId}/verify?username=${nextUsername}`);
      } else if (
        err.code === 'NotAuthorizedException' &&
        err.message ===
          'Temporary password has expired and must be reset by an administrator.'
      ) {
        history.push(
          `/events/${eventId}/password-expired?username=${nextUsername}`
        );
      } else {
        if (autoLogin) {
          setPass('');
          openSnackbar = false;
        }
        message = err.message;
        console.error(err);
      }
      setSnackbar({
        isOpen: openSnackbar,
        severity: AimSnackbarSeverity.error,
        message: message,
      });
      setIsWorking(false);
    }
  };

  function forgotPassword() {
    history.push(`/events/${eventId}/forgotpassword`);
  }

  const forgotUsername = () => {
    history.push(`/events/${eventId}/forgot-username`);
  };

  function signUp() {
    history.push(`/events/${eventId}/signup`);
  }

  const dateTimezone = (dateString) => {
    const currentTimezone = appState.eventInfo.getValue().timezone;
    const date = utcToZonedTime(
      dateString ? new Date(dateString) : new Date(),
      currentTimezone
    );

    return date;
  };

  return (
    <LoginLayout title={i18n.pageTitle}>
      <form onSubmit={(e) => login({ e })}>
        <Grid container style={{ paddingTop: 20 }}>
          <Grid item xs={12}>
            <AimTextField
              // displayLabel={false}
              label={i18n.username}
              name="username"
              value={username}
              placeholder={i18n.username}
              onChange={(e) => setUsername(e.target.value)}
              formControlStyle={formControlStyle}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <AimTypography
              margin={0}
              variant={'h6'}
              style={{
                cursor: 'pointer',
                margin: '8px 0 0 8px',
                paddingRight: 20,
              }}
              onClick={forgotUsername}
            >
              {i18n.forgotUsername}
            </AimTypography>
          </Grid>
          <Grid item xs={12}>
            <AimTextField
              // displayLabel={false}
              label={i18n.password}
              name="password"
              value={pass}
              placeholder={i18n.password}
              formControlStyle={formControlStyle}
              type="password"
              onChange={(e) => setPass(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <AimTypography
              margin={0}
              variant={'h6'}
              style={labelStyle}
              onClick={forgotPassword}
            >
              {i18n.forgotPassword}
            </AimTypography>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            style={{ ...labelStyle, ...(isMobile && { marginTop: 15 }) }}
          >
            <AimIconAndTextButton
              text={i18n.confirm}
              type="submit"
              isUpperCase
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      </form>
      {!appState.eventInfo.getValue()?.byInvitationOnlyFromDate ||
      isAfter(
        dateTimezone(appState.eventInfo.getValue().byInvitationOnlyFromDate),
        dateTimezone()
      ) ? (
        <Grid
          item
          xs={12}
          style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}
        >
          <AimTypography
            margin={0}
            variant={'h6'}
            style={{ cursor: 'pointer', margin: '8px 0 0 8px' }}
            onClick={signUp}
          >
            {i18n.newUser} {i18n.registerNow}
          </AimTypography>
        </Grid>
      ) : (
        <></>
      )}
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </LoginLayout>
  );
};

export default StandardLogin;
