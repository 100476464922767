/* eslint-disable react/display-name */
import React, { useState, useRef } from 'react';

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  //   PDFViewer,
  BlobProvider,
} from '@react-pdf/renderer';
import PDFViewer from 'mgr-pdf-viewer-react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { appState, constants } from '@aim/common';
import { AimIconAndTextButton } from '../atoms/AimIconAndTextButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { globalStyles } from './AimReactPdfRenderer/ReactPdfHelper';
import './AimReactPdfRenderer/PDFViewer.css';
import { styled } from '../styled';

// Font.register({
//   family: 'Roboto',
//   fonts: [
//     { src: RobotoRegular, fontStyle: 'normal', fontWeight: 400 },
//     { src: RobotoBold, fontStyle: 'normal', fontWeight: 700 },
//     { src: RobotoBoldItalic, fontStyle: 'italic', fontWeight: 700 },
//   ],
// });

const CustomIconButton = styled(IconButton)({
  '&:hover': {
    background: 'transparent !important',
  },
});

const StyledReactPdfWithPrintOptionsText = ({
  element,
  value,
  customStyle,
  defaultFontSize,
}) => {
  let styles;
  if (element && constants.PrintOptions[element.key]) {
    styles = StyleSheet.create({
      element: {
        fontSize: element
          ? element[constants.PrintOptions[element.key].options.fontSize]
          : customStyle?.fontSize || defaultFontSize,
        fontWeight:
          (element &&
            element[
              constants.PrintOptions[element.key].options.format[0].key
            ]) ||
          customStyle?.fontWeight === 'bold'
            ? 'bold'
            : 400,
        fontStyle:
          (element &&
            element[
              constants.PrintOptions[element.key].options.format[1].key
            ]) ||
          customStyle?.fontStyle === 'italic'
            ? 'italic'
            : 'normal',
        textDecoration:
          (element &&
            element[
              constants.PrintOptions[element.key].options.format[2].key
            ]) ||
          customStyle?.textDecoration === 'underline'
            ? 'underline'
            : 'none',
      },
    });
  } else {
    // for all keys not found in constants; for ex: abstractRole for program template
    styles = StyleSheet.create({
      element: {
        fontSize: defaultFontSize,
      },
    });
  }
  return (
    <Text {...(styles?.element ? { style: styles.element } : {})}>{value}</Text>
  );
};

const DefaultText = ({ value, spacing = 0, style, defaultFontSize }) => {
  const spacingText = '  '.repeat(spacing);
  const styles = StyleSheet.create({
    element: {
      // marginLeft: 20 * spacing,
      // marginRight: 20 * spacing,
      fontSize: defaultFontSize,
      fontWeight: 100,
      fontStyle: 'normal',
      textDecoration: 'none',
      ...style,
    },
  });
  return (
    <Text style={styles.element}>
      {spacingText}
      {value}
      {spacingText}
    </Text>
  );
};

const PageTemplateRows = ({
  rowsTemplate,
  model,
  printOptions,
  defaultFontSize,
}) => {
  return rowsTemplate.map(
    ({
      separator = {
        text: '',
        spacing: 0,
        style: {},
      },
      viewSeparator = {
        text: '',
        spacing: 0,
        style: {},
      },
      direction = 'column',
      interline = 0.1,
      indentation = 0,
      textSections = [],
      iterateOnProperty,
      subRowsTemplate = [],
    }) => {
      const styles = StyleSheet.create({
        rowStyle: {
          flexDirection: 'row',
          display: 'flex',
          marginLeft: 20 * indentation,
          marginTop: 20 * interline,
        },
        subView: {
          display: 'flex',
          flexDirection: direction === 'row' ? 'row' : 'column',
          margin: direction === 'row' ? 0 : 0.1,
          padding: direction === 'row' ? 0 : 0.1,
        },
        mainView: {
          margin: direction === 'row' ? 0 : 0.1,
          padding: direction === 'row' ? 0 : 0.1,
          // ...(direction === 'row' && { paddingLeft: 1, marginLeft: 1 }),
          ...(direction === 'row' && { display: 'flex', flexDirection: 'row' }),
          // flexGrow: 1,
          // ...(subRowsTemplate.length === 0
          //   ? { display: 'flex' }
          //   : { flexGrow: 1 }),
        },
      });
      const itemsToRender =
        (iterateOnProperty ? model[iterateOnProperty] : [model]) || [];

      if (!itemsToRender) {
        throw new Error(`item with key ${iterateOnProperty} not found`);
      }
      return itemsToRender.map((currentLevelModel, idx) => {
        // textSectionsFiltered.length === textSections.length REMOVED
        // NB: if check after filter (textSectionsFiltered.length === textSections.length): filtered textSections items length === textSections.length not true
        //  then the row of template will be removed (see for ex textSessions below)
        // for ex if speech code undefined, so in parse of single session (ProgramTemplate.js) for default speechCode is empty (speechCode: speech.code || '')
        // textSections: [
        //   {
        //     key: 'interventionBlockCode',
        //     modelPath: 'speechCode',
        //   },
        //   {
        //     key: 'interventionBlockTitle',
        //     modelPath: 'speechTitle',
        //   },
        //   {
        //     key: 'interventionBlockStartEndTime',
        //     modelPath: 'speechStartEndTime',
        //   },
        // ],

        const textSectionsFiltered = textSections?.filter(
          ({ modelPath }) => currentLevelModel[modelPath] !== undefined
        );

        return (
          <View key={idx} style={styles.mainView}>
            {textSections.length > 0 ? (
              // textSectionsFiltered.length === textSections.length ? (
              <Text style={styles.rowStyle}>
                {viewSeparator.text && idx > 0 ? (
                  <DefaultText
                    value={viewSeparator.text}
                    spacing={viewSeparator.spacing}
                    style={viewSeparator.style}
                    defaultFontSize={defaultFontSize}
                  />
                ) : null}
                {textSections.map(({ key, modelPath, customStyle }, idx) => {
                  const value = currentLevelModel[modelPath];
                  const customStyleValue = currentLevelModel[customStyle];
                  const element = printOptions.find((po) => po.key === key);

                  return (
                    <Text key={`${idx}${modelPath}`}>
                      {separator.text &&
                      idx > 0 &&
                      textSections.length > 1 &&
                      // check if at least there are 2 textSections with value not undefined
                      textSections.filter(
                        (x) => currentLevelModel[x.modelPath] !== undefined
                      )?.length > 1 ? (
                        <DefaultText
                          value={separator.text}
                          spacing={separator.spacing}
                          style={separator.style}
                          defaultFontSize={defaultFontSize}
                        />
                      ) : null}
                      <StyledReactPdfWithPrintOptionsText
                        element={element}
                        value={value}
                        customStyle={customStyleValue}
                        defaultFontSize={defaultFontSize}
                      />
                    </Text>
                  );
                })}
              </Text>
            ) : null}
            {subRowsTemplate.length > 0 ? (
              <View style={styles.subView}>
                <PageTemplateRows
                  rowsTemplate={subRowsTemplate}
                  model={currentLevelModel}
                  printOptions={printOptions}
                  defaultFontSize={defaultFontSize}
                />
              </View>
            ) : null}
          </View>
        );
      });
    }
  );
};

const PdfTemplateRender = ({
  model,
  rowsTemplate,
  printOptions,
  defaultFontSize,
}) => {
  const modelArray = Array.isArray(model) ? model : [model];

  return modelArray.map((modelItem, idx) => (
    <PageTemplateRows
      key={idx}
      rowsTemplate={rowsTemplate}
      model={modelItem}
      printOptions={printOptions}
      defaultFontSize={defaultFontSize}
    />
  ));
};

export const AimReactPdfRenderer = ({
  rowsTemplate = [],
  model = [],
  printOptions = [],
  defaultFontSize = 16,
  preview = true,
}) => {
  const [buttonStyle] = useState(
    appState.template.getValue() === constants.Templates.BACKEND
      ? { background: 'white', marginTop: 20, marginBottom: 20 }
      : {}
  );
  const [page, setPage] = useState(1);
  const handleNextButtonRef = useRef(null);
  const handlePrevButtonRef = useRef(null);
  return (
    <>
      <PDFDownloadLink
        document={
          <Document>
            <Page size="A4" style={globalStyles.page}>
              <PdfTemplateRender
                rowsTemplate={rowsTemplate}
                model={model}
                printOptions={printOptions}
                defaultFontSize={defaultFontSize}
              />
            </Page>
          </Document>
        }
        fileName="program.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            'Loading document...'
          ) : (
            <AimIconAndTextButton text="Download" style={buttonStyle}>
              <GetAppIcon />
            </AimIconAndTextButton>
          )
        }
      </PDFDownloadLink>
      {preview && (
        <BlobProvider
          document={
            <Document>
              <Page size="A4" style={globalStyles.page}>
                <PdfTemplateRender
                  rowsTemplate={rowsTemplate}
                  model={model}
                  printOptions={printOptions}
                  defaultFontSize={defaultFontSize}
                />
              </Page>
            </Document>
          }
        >
          {({ blob, url, loading, error }) => {
            // Do whatever you need with blob here
            return url ? (
              <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                <CustomIconButton
                  style={{
                    marginRight: 100,
                  }}
                  disableRipple
                  disableFocusRipple
                  onClick={() => handlePrevButtonRef.current()}
                >
                  <ArrowBackIosIcon />
                </CustomIconButton>
                <div style={{ boxShadow: '0 25px 50px 0 rgb(62 62 62 / 15%)' }}>
                  <PDFViewer
                    document={{
                      url,
                    }}
                    page={page}
                    css="customViewer"
                    navigation={{
                      css: {
                        wrapper: 'customWrapper',
                      },
                      elements: {
                        previousPageBtn: ({ handlePrevClick }) => {
                          handlePrevButtonRef.current = handlePrevClick;
                          return <></>;
                        },
                        nextPageBtn: ({ handleNextClick }) => {
                          handleNextButtonRef.current = handleNextClick;
                          return <></>;
                        },
                        pages: () => <></>,
                      },
                    }}
                  />
                </div>
                <CustomIconButton
                  style={{ marginLeft: 100 }}
                  disableRipple
                  disableFocusRipple
                  onClick={() => handleNextButtonRef.current()}
                >
                  <ArrowForwardIosIcon />
                </CustomIconButton>
              </div>
            ) : (
              <></>
            );
          }}
        </BlobProvider>
      )}
    </>
  );
};
