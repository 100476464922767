export default function (intl) {
  return {
    page: {
      myArea: intl.formatMessage({
        description: 'myAreaTitle',
        defaultMessage: 'My Area',
      }),
      myServices: intl.formatMessage({
        description: 'myServicesTitle',
        defaultMessage: 'My Services',
      }),
      registrations: intl.formatMessage({
        description: 'myServicesTitle',
        defaultMessage: 'Registrations',
      }),
      isUnpublishedContent: intl.formatMessage({
        description: 'isUnpublishedContent',
        defaultMessage: 'Only you can view this content',
      }),
      sendMessage: intl.formatMessage({
        description: 'sendMessage',
        defaultMessage: 'Send message',
      }),
      changeCoverImage: intl.formatMessage({
        description: 'changeCoverImage',
        defaultMessage: 'change image',
      }),
      description: intl.formatMessage({
        description: 'description',
        defaultMessage: 'Description',
      }),
      contacts: intl.formatMessage({
        description: 'contacts',
        defaultMessage: 'Contacts',
      }),
      lastDocs: intl.formatMessage({
        description: 'lastDocs',
        defaultMessage: 'Last docs',
      }),
      subscriptions: intl.formatMessage({
        description: 'subscriptions',
        defaultMessage: 'Subscriptions',
      }),
      myInterventions: intl.formatMessage({
        description: 'myInterventions',
        defaultMessage: 'My interventions',
      }),
      personalData: intl.formatMessage({
        description: 'personal data tab label',
        defaultMessage: 'Personal data',
      }),
      changePassword: intl.formatMessage({
        description: 'change password tab label',
        defaultMessage: 'Change password',
      }),
      documents: intl.formatMessage({
        description: 'documents tab label',
        defaultMessage: 'Documents',
      }),
      mySurveys: intl.formatMessage({
        description: 'my surveys tab label',
        defaultMessage: 'My surveys',
      }),
      myServices: intl.formatMessage({
        description: 'my services tab label',
        defaultMessage: 'My services',
      }),
      pleaseEdit: intl.formatMessage({
        description: 'please edit',
        defaultMessage: 'Please Edit',
      }),
      noDataAvailable: intl.formatMessage({
        description: 'no data available',
        defaultMessage: 'No data available.',
      }),
      detailEdit: intl.formatMessage({
        description: 'detil Edit',
        defaultMessage: 'Edit Profile',
      }),
      participationDataEdit: intl.formatMessage({
        description: 'profile Edit Regitration Data button label',
        defaultMessage: 'Edit Regitration Data',
      }),
      myAgenda: intl.formatMessage({
        description: 'myAgenda',
        defaultMessage: 'Favourite sessions',
      }),
      speaker: intl.formatMessage({
        description: 'profile speaker label',
        defaultMessage: 'Speaker',
      }),
    },
    contact: {
      phone: intl.formatMessage({
        description: 'phone',
        defaultMessage: 'Phone',
      }),
      businessPhone: intl.formatMessage({
        description: 'businessPhone',
        defaultMessage: 'Business phone',
      }),
      contactEmail: intl.formatMessage({
        description: 'contactEMail',
        defaultMessage: 'Contact email',
      }),
      email: intl.formatMessage({
        description: 'userEMail',
        defaultMessage: 'User email',
      }),
      companyName: intl.formatMessage({
        description: 'company',
        defaultMessage: 'Company',
      }),
      bio: intl.formatMessage({
        description: 'bio',
        defaultMessage: 'Biography',
      }),
      birthDate: intl.formatMessage({
        description: 'birthday',
        defaultMessage: 'Date of Birthday',
      }),
      address: intl.formatMessage({
        description: 'address',
        defaultMessage: 'Address',
      }),
      nationality: intl.formatMessage({
        description: 'nationality',
        defaultMessage: 'Nationality',
      }),
    },
    surveys: {
      mySurveys: intl.formatMessage({
        description: 'mySurveys',
        defaultMessage: 'My surveys',
      }),
      surveyStatusLabel: intl.formatMessage({
        description: 'survey status label',
        defaultMessage: 'Survey status',
      }),
      readyToStart: intl.formatMessage({
        description: 'Ready to start status label',
        defaultMessage: 'Ready to start',
      }),
      completed: intl.formatMessage({
        description: 'completed status label',
        defaultMessage: 'Completed',
      }),
      noSurvey: intl.formatMessage({
        description: 'no survey available label',
        defaultMessage: 'No survey available',
      }),
    },
    services: {
      myTicketTitle: intl.formatMessage({
        description: 'my ticket title',
        defaultMessage: 'My Registration',
      }),
      myTicketDesc: intl.formatMessage({
        description: 'my ticket desc',
        defaultMessage:
          'Here can you see the ticket you buyed to participate at the event. You can also download the voucher you have to present at the reception the day of the event.',
      }),
      myAbstractTitle: intl.formatMessage({
        description: 'my abstract title',
        defaultMessage: 'My Abstract',
      }),
      myAbstractDesc: intl.formatMessage({
        description: 'my abstract desc',
        defaultMessage:
          'Here you can find all the information about the abstract you submitted and the instruction for the presentation.',
      }),
      abstractGrantTitle: intl.formatMessage({
        description: 'abstract grant title',
        defaultMessage: 'Abstract Grant',
      }),
      abstractGrantDesc: intl.formatMessage({
        description: 'abstract grant desc',
        defaultMessage:
          'See the results of your subscription at the abstract grant',
      }),
      educationalGrantTitle: intl.formatMessage({
        description: 'educational grant title',
        defaultMessage: 'Educational Grant',
      }),
      educationalGrantDesc: intl.formatMessage({
        description: 'educational grant desc',
        defaultMessage:
          'See the results of your subscription at the educational grant',
      }),
      allotmentTitle: intl.formatMessage({
        description: 'allotment title',
        defaultMessage: 'Allotment',
      }),
      allotmentDesc: intl.formatMessage({
        description: 'allotment desc',
        defaultMessage:
          'Here you can find all your hotel reservations and the receipt of your transaction.',
      }),
      travelTitle: intl.formatMessage({
        description: 'travel title',
        defaultMessage: 'Travel',
      }),
      travelDesc: intl.formatMessage({
        description: 'travel desc',
        defaultMessage:
          'Here you can find all your ticket for the trip and the receipt of your transaction.',
      }),
      additionalServiceTitle: intl.formatMessage({
        description: 'additional services title',
        defaultMessage: 'Additional Services',
      }),
      socialEvents: intl.formatMessage({
        description: 'social events title',
        defaultMessage: 'Social events',
      }),
      scientificEvents: intl.formatMessage({
        description: 'scientific events title',
        defaultMessage: 'Scientific events',
      }),
      additionalServiceDesc: intl.formatMessage({
        description: 'additional services desc',
        defaultMessage:
          'Download your voucher to participate at the social and scientific events you buyed.',
      }),
      visaTitle: intl.formatMessage({
        description: 'visa title',
        defaultMessage: 'VISA',
      }),
      visaDesc: intl.formatMessage({
        description: 'visa desc',
        defaultMessage:
          'Here you can have access to the documents you have to present at your embassy to participate at the congress.',
      }),
      certificateListTitle: intl.formatMessage({
        description: 'certificate list title',
        defaultMessage: 'Certifications',
      }),
      certificateListDesc: intl.formatMessage({
        description: 'certificate list desc',
        defaultMessage:
          'Here you can find all the certifications related to your partecipation at the event',
      }),
      invoicesListTitle: intl.formatMessage({
        description: 'invoices list title',
        defaultMessage: 'Invoices',
      }),
      invoicesListDesc: intl.formatMessage({
        description: 'invoices list desc',
        defaultMessage:
          'Here you can find all the invoices related to your purchases',
      }),
    },
    actions: {
      back: intl.formatMessage({
        description: 'back',
        defaultMessage: 'back to my area',
      }),
    },
    errors: {
      error1: intl.formatMessage({
        description: 'order data request processing error',
        defaultMessage: 'Order data request processing error',
      }),
      error2: intl.formatMessage({
        description: 'order data processing error',
        defaultMessage: 'Order data processing error',
      }),
      error3: intl.formatMessage({
        description: 'order payment initialization error',
        defaultMessage: 'Order payment initialization error',
      }),
      error4: intl.formatMessage({
        description: 'order payment error',
        defaultMessage: 'Order payment error',
      }),
      paymentExist: intl.formatMessage({
        description: 'payment exist',
        defaultMessage: 'Payment exist',
      }),
    },
  };
}
