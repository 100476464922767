import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import {
  CustomIntl,
  AimDynamicForm,
  StepDivider,
  Title,
} from '@aim/components';

import {
  getFieldsListByContexts,
  unionToContextOfUse,
} from '../../shared/fieldsHelper';

import { constants } from '@aim/common';

const context = [constants.AbstractFieldContext.Abstract];

const sort = (fields) =>
  fields.sort((a, b) => a.contextsOfUse.position - b.contextsOfUse.position);

const AdditionalData = ({
  title,
  control,
  reset,
  register,
  setValue,
  getValues,
  errors,
  clearErrors,
  setStandardFields,
  setCustomFields,
  customFields,
  standardFields,
  abstractDocument,
}) => {
  // Hooks
  const intl = CustomIntl(useIntl());
  const { eventId } = useParams();

  // States
  // const [standardFields, setStandardFields] = useState();
  // const [customFields, setCustomFields] = useState();
  const [formValuesLoaded, setFormValuesLoaded] = useState();

  // Effects
  useEffect(() => {
    // Get standard fields and custom fields of abstract service
    const fetchFields = async () => {
      const fieldsRes = await getFieldsListByContexts({
        useLoader: true,
        eventId,
        contextsOfUse: context,
      });

      const { standardFields: sFields, customFields: cFields } = fieldsRes;

      // Get standard fields to show
      const outStandardFields = unionToContextOfUse(sFields, context);

      // Get custom fields to show
      const outCustomFields = unionToContextOfUse(cFields, context);

      const nextStandardFields = createCollection(
        outStandardFields.map((f) => ({
          ...f,
          disabled: false,
          isRequired: f.contextsOfUse.isRequired,
          dirPath: null,
        })),
        context,
        true
      );

      const nextCustomFields = createCollection(
        outCustomFields.map((f) => ({
          ...f,
          disabled: false,
          isRequired: f.contextsOfUse.isRequired,
          dirPath: null,
        })),
        context,
        true
      );
      console.log('nextStandardFields', nextStandardFields);
      console.log('nextCustomFields', nextCustomFields);

      setStandardFields(nextStandardFields);
      setCustomFields(nextCustomFields);

      const { additionalData } = abstractDocument; // only if in edit mode

      const nextStandardDefaultValues = createDefaultValues({
        fields: sFields,
        fieldValues: additionalData || [],
      });
      const nextCustomDefaultValues = createDefaultValues({
        fields: cFields,
        fieldValues: additionalData || [],
      });

      reset({
        ...getValues(),
        standardDefaultValues: nextStandardDefaultValues,
        customDefaultValues: nextCustomDefaultValues,
      });

      setFormValuesLoaded(true);
    };
    fetchFields();
  }, [eventId]);

  const createDefaultValues = ({ fields, fieldValues }) => {
    const defaultValues = fields.reduce((res, field) => {
      const fieldValue = fieldValues.find(
        (fv) =>
          fv.fieldDefinition.id === field.id ||
          fv.fieldDefinition.key === field.key
      );

      let value = fieldValue ? fieldValue.value : '';

      if (value && typeof value === 'string' && /^[[{]/.test(value)) {
        try {
          value = JSON.parse(value);
        } catch (error) {
          console.error('Error parsing value for field:', field.id, error);
        }
      }

      if (field.controlType === 'upload' && Array.isArray(value)) {
        value = value.map((v) => ({ ...v, name: v.originalName }));
      }

      res[field.id] = value;

      return res;
    }, {});

    return defaultValues;
  };

  const { createFormFields } = AimDynamicForm;

  const createCollection = (fields, contextsOfUse, showHiddenFields) =>
    contextsOfUse
      .map((ctx) => ctx)
      .reduce((res, curr) => {
        const filtered = fields.filter(
          (f) => f.contextsOfUse.contextName === curr
        );
        const nextFiltered = showHiddenFields
          ? filtered
          : filtered.filter((f) => !f.contextsOfUse.isHidden);

        const nextFields = sort(nextFiltered);
        return { ...res, [curr]: nextFields };
      }, {});

  const renderFormFields = (collection, prefix) => {
    return Object.values(collection).map((fields) =>
      fields.length ? (
        createFormFields({
          prefix,
          fields,
          register,
          setValue,
          getValues,
          control,
          errors,
          intl,
          clearErrors,
          radioVariant: 'secondary',
        })
      ) : (
        <></>
      )
    );
  };
  return (
    <>
      <Title>{title}</Title>
      <StepDivider />
      {formValuesLoaded && (
        <Grid container>
          {renderFormFields(standardFields, 'standardDefaultValues')}
          {renderFormFields(customFields, 'customDefaultValues')}
        </Grid>
      )}
    </>
  );
};

export default AdditionalData;
