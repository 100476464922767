import { aws, appState, utilities } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventDetailsQuery = /* GraphQL */ `
  query getEventDetails($id: ID!, $filter: ModelProfileFilterInput) {
    getEvent(id: $id) {
      id
      profiles(filter: $filter) {
        items {
          id
          name
          isAccompanyingPersonEnabled
          maxAccompanyingPersons
          category {
            id
            name
          }
        }
      }
    }
  }
`;

const getFeeInfoQuery = /* GraphQL */ `
  query getFeeInfo($profileId: ID!, $eventId: ID!) {
    getProfile(id: $profileId) {
      isAccompanyingPersonEnabled
      profileFeeBrackets {
        items {
          id
          priceOnSite
          priceOnAir
          accompanyingPersonPrice
          profileFeeConfiguration {
            id
            isActive
            ticketDescription
            requireVat
            nMaxTickets
          }
          feeBracket {
            feeDateRange {
              label
              start
              end
            }
            feeBracketModel {
              end
              start
            }
          }
        }
      }
    }
    getEvent(id: $eventId) {
      type
    }
  }
`;

const getEventGatewaysQuery = /* GraphQL */ `
  query GetEvent($id: ID!, $serviceType: String) {
    getEvent(id: $id) {
      id
      gateways(filter: { service: { eq: $serviceType } }) {
        items {
          id
          service
          shop {
            id
            gatewayType
            paymentType
            iban
            ibanCausal
            addressedTo
          }
        }
      }
    }
  }
`;

const getTicketFeeQuery = /* GraphQL */ `
  query GetTicketFee($id: ID!) {
    getProfileFeeBracket(id: $id) {
      id
      priceOnAir
      priceOnSite
      accompanyingPersonPrice
      profileFeeConfiguration {
        requireVat
      }
      profile {
        name
        category {
          name
          id
        }
        id
      }
      feeBracket {
        feeBracketModel {
          end
          start
          fee {
            vatRate
            vat {
              id
            }
          }
        }
        feeDateRange {
          label
          start
          end
        }
      }
    }
  }
`;

const createBillingInformationMutation = /* GraphQL */ `
  mutation CreateBillingInformation(
    $input: CreateBillingInformationInput!
    $condition: ModelBillingInformationConditionInput
  ) {
    createBillingInformation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateBillingInformationMutation = /* GraphQL */ `
  mutation UpdateBillingInformation(
    $input: UpdateBillingInformationInput!
    $condition: ModelBillingInformationConditionInput
  ) {
    updateBillingInformation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateParticipationMutation = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const getParticipationBillingInformationQuery = /* GraphQL */ `
  query GetParticipationBillingInformation($id: ID!) {
    getParticipation(id: $id) {
      billingInformation {
        id
        invoiceTo
        name
        address
        city
        postalCode
        region
        province
        country
        countryIsoCode
        vatCode
        taxCode
        reference
        email
        pec
        phone
        invoiceToPublicAuthority
        uniqueCode
        ipaCode
        isSplitPayment
        isVatEvent
      }
    }
  }
`;

const getAdditionalServicesQuery = /* GraphQL */ `
  query getAdditionalServices($id: ID!) {
    getEvent(id: $id) {
      id
      additionalServices(filter: { priceIncluded: { eq: true } }) {
        items {
          id
          typology
          title
          participationType
          maxSubscription
        }
      }
    }
  }
`;

const getProductsByClientIdServiceIdQuery = /* GraphQL */ `
  query getProductsByClientIdServiceId(
    $serviceId: String
    $serviceType: String
  ) {
    productByServiceIdServiceType(
      serviceId: $serviceId
      serviceType: { eq: $serviceType }
    ) {
      items {
        id
        productId
        clientId
        clientType
        serviceId
        serviceType
        payment {
          id
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
        }
      }
    }
  }
`;

const listPartcipantEventsQuery = /* GraphQL */ `
  query listPartcipantEvents($cognitoUserId: ID!, $eventId: ID!) {
    participantByCognitoUserId(cognitoUserId: $cognitoUserId) {
      items {
        id
        event {
          start
          id
        }
      }
    }
    getEvent(id: $eventId) {
      id
      start
    }
  }
`;

export const getEventDetails = (eventId, cluster, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getEventDetailsQuery,
      variables: {
        id: eventId,
        filter: {
          and: { isPublic: { eq: true }, isDeleted: { ne: true } },
          or: [
            { clusters: { contains: cluster } },
            { cluster: { eq: cluster } },
          ],
        },
      },
    })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('get-event-details', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getFeeDetails = (profileId, eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getFeeInfoQuery,
      variables: {
        profileId,
        eventId,
      },
    })
      .then((response) => resolve(response.data))
      .catch((e) => {
        console.error('get-fee-details', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getEventGateway = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getEventGatewaysQuery,
      variables: { ...input },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.getEvent.gateways.items[0]);
      })
      .catch((e) => {
        console.error('event-gateways', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getTicketFee = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getTicketFeeQuery,
      variables: {
        id,
      },
    })
      .then((response) => resolve(response.data.getProfileFeeBracket))
      .catch((e) => {
        console.error('get-ticket-fee', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getBillingInformations = (participationId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getParticipationBillingInformationQuery,
      variables: { id: participationId },
    })
      .then((response) =>
        resolve(response.data.getParticipation?.billingInformation)
      )
      .catch((e) => {
        console.error('get-participation-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createBillingInformation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: createBillingInformationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createBillingInformation.id))
      .catch((e) => {
        console.error('create-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateBillingInformation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateBillingInformationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateBillingInformation.id))
      .catch((e) => {
        console.error('update-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateParticipation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateParticipationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateParticipation))
      .catch((e) => {
        console.error('update-participation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getAdditionalServices = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getAdditionalServicesQuery,
      variables: { id: eventId },
    })
      .then((response) => resolve(response.data.getEvent.additionalServices))
      .catch((e) => {
        console.error('get-additional-services', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getProductsByClientIdServiceId = (
  serviceId,
  serviceType,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getProductsByClientIdServiceIdQuery,
      variables: {
        serviceId,
        serviceType,
      },
    })
      .then((response) => {
        const validProducts = response.data.productByServiceIdServiceType.items.filter(
          (product) => utilities.isValidPayment(product.payment)
        );
        resolve(validProducts);
      })
      .catch((e) => {
        console.error('products-by-service-id-service-type', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getParticipationLastEventBillingInfo = (
  cognitoUserId,
  eventId,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: listPartcipantEventsQuery,
      variables: { cognitoUserId, eventId },
    })
      .then(async (response) => {
        const first = response.data.participantByCognitoUserId.items
          .filter(
            (p) =>
              p.event.id != eventId &&
              new Date(p.event.start).getTime() <
                new Date(response.data.getEvent.start).getTime()
          )
          .sort((a, b) =>
            new Date(a.event.start).getTime() >=
            new Date(b.event.start).getTime()
              ? 1
              : -1
          )?.[0];

        const billing = await getBillingInformations(first.id, false);
        resolve(billing);
      })
      .catch((e) => {
        console.error('get-participation-last-event-billing-info', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
