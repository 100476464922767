import { aws, appState } from '@aim/common';
// const { API } = aws;

const getSponsorsFieldValuesQuery = /* GraphQL */ `
  query getSponsorsFieldValues($id: ID!) {
    getEvent(id: $id) {
      sponsors(filter: { isDeleted: { ne: true } }) {
        items {
          id
          name
          therapeuticArea
          profileImage {
            id
            originalName
            size
            extension
          }
          profileCover {
            id
            originalName
            size
            extension
          }
          publicPage {
            id
            isPublic
            buyOperation {
              id
              purchase {
                id
                paymentType
                payment {
                  paymentIdMonetaOnLine
                  paymentIdIngenico
                  paymentIdGpWebpay
                  paymentType
                }
              }
            }
          }
          priceRange {
            id
            name
            price
            frontendNameColor
            backgroundColor
          }
        }
      }
      sponsorPriceRanges {
        items {
          id
          name
          price
          start
          description
          freeStaffMembers
          order
          frontendNameColor
          backgroundColor
          sponsors(filter: { isDeleted: { ne: true } }) {
            items {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const getSponsors = ({ eventId }) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getSponsorsFieldValuesQuery,
      variables: {
        id: eventId,
      },
    })
      .then((response) => {
        resolve(response.data.getEvent);
      })
      .catch((e) => {
        console.error(
          'list-event-sponsors-field-values-and-sponsorPriceRanges',
          e
        );
        reject();
      })
      .finally(hideLoader);
  });

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);
