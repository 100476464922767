import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';
import useI18n from './shared/translation';

import { useParams, useHistory } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';

import Divider from '@material-ui/core/Divider';

import {
  AimSnackbar,
  AimSnackbarSeverity,
  AimTypography,
  AimIconAndTextButton,
  CustomIntl,
} from '@aim/components';
import { constants, appState, utilities } from '@aim/common';

import Bookings from './sponsorCart/Bookings';
import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';

import { useBuyOperations } from './shared/buyOperationsGqlHelper';
import { useDataHelper } from './shared/dataHelper';
import { responseCodesTexts } from './sponsorCart/parymentErrors';
import { format } from 'date-fns';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const BOTH_PAYMENT_TYPE = 'both';
const SponsorCart = () => {
  // Hooks
  const { eventId, sponsorId } = useParams();
  const history = useHistory();
  const { listSurcharges, refreshSponsorCart, update } = useBuyOperations();
  const { formatNumber } = useDataHelper();

  const intl = CustomIntl(useIntl());
  const i18n = useI18n.sponsorCart(intl);

  // States
  const [bookingsBankTransfer, setBookingsBankTransfer] = useState([]);
  const [bookingsCreditCard, setBookingsCreditCard] = useState([]);
  const [bookingsBothPayments, setBookingsBothPayments] = useState([]);
  const [allBookings, setAllBookings] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [checkoutBookings, setCheckoutBookings] = useState([]);
  const [paymentGateway, setPaymentGateway] = useState('');
  const [sponsorGateway, setSponsorGateway] = useState('');
  const [surcharge, setSurcharge] = useState();
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const $responseCode = query.get('responseCode');
    if ($responseCode) {
      const errorLabel = responseCodesTexts
        .find((x) => x.key === $responseCode)
        ?.label(intl);
      console.error('transaction error : ', errorLabel);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: errorLabel || 'generic error',
        autoHide: false,
      });
    }
  }, []);

  const fetchSurcharges = async (eventId) => {
    const fetchedSurcharges = await listSurcharges(eventId);
    const actualSurcharge = fetchedSurcharges
      .map((surcharge) => ({
        id: surcharge.id,
        createdAt: surcharge.createdAt,
        percentage: utilities.decodeDbNumber(surcharge.percentage),
      }))
      .sort(function (a, b) {
        // Sort rollbacks by createdAt from newest to oldest
        return new Date(b.createdAt) - new Date(a.createdAt);
      })[0];
    setSurcharge(actualSurcharge);
  };

  useEffect(() => {
    if (eventId) fetchSurcharges(eventId);
    if (eventId && sponsorId) refreshSponsorCart(eventId, sponsorId);
  }, [eventId, sponsorId]);

  useEffect(() => {
    const subscription = appState.cartGateway.subscribe((gateway) => {
      setPaymentGateway(gateway?.shop?.gatewayType);
      setSponsorGateway(gateway);
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const subscription = appState.cart.subscribe(loadData);
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (
      history?.location?.state?.bookings?.length &&
      allBookings?.length &&
      paymentGateway &&
      sponsorGateway
    ) {
      const purchaseNowArray = history?.location?.state?.bookings;
      const intersection = allBookings.filter((b) =>
        purchaseNowArray.find((p) => p.id === b.id)
      );
      onClickBuy({
        bookings: intersection,
        payment: paymentGateway,
        gateway: sponsorGateway,
      });
    }
  }, [
    allBookings?.length,
    history?.location?.state?.bookings?.length,
    paymentGateway,
    sponsorGateway,
  ]);

  // Functions
  const loadData = async (bookings) => {
    try {
      setAllBookings(bookings.filter((booking) => booking.paymentType));
      setBookingsBankTransfer(
        bookings.filter(
          (i) => i.paymentType === constants.PaymentTypes.BankTransfer
        )
      );
      setBookingsCreditCard(
        bookings.filter(
          (i) => i.paymentType === constants.PaymentTypes.CreditCard
        )
      );
      setBookingsBothPayments(
        bookings.filter((i) => i.paymentType === BOTH_PAYMENT_TYPE)
      );
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.errors.loadBookings.label,
      });
    }
  };

  const onClickBuy = ({ bookings, payment, gateway }) => {
    if (bookings.length) {
      history.push({
        pathname: `/events/${eventId}/${sponsorId}/cart/billing-informations`,
        state: {
          bookings,
          payment: payment || paymentGateway,
          gateway: gateway || sponsorGateway,
          url: `/events/${eventId}/${sponsorId}`,
          paymentType:
            bookings?.[0].paymentType !== BOTH_PAYMENT_TYPE
              ? bookings?.[0].paymentType
              : undefined,
        },
      });
    }
  };

  const onClickDelete = async (bookings) => {
    showLoader();
    try {
      const promises = bookings.map((b) => {
        if (b.sponsorPackage) {
          return update({
            id: b.id,
            date: null,
            buyOperationSponsorId: null,
            paymentType: null,
          });
        } else {
          return update({ id: b.id, isDeleted: 'true' }, false);
        }
      });
      await Promise.all(promises);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.deleteBookingSuccess.label,
      });
      await refreshSponsorCart(eventId, sponsorId);
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.deleteBookingFail.label,
      });
    } finally {
      hideLoader();
    }
  };

  // const header = () => [
  //   { name: i18n.bookingTable.columns.bookingId.label },
  //   { name: i18n.bookingTable.columns.service.label },
  //   { name: i18n.bookingTable.columns.instance.label },
  //   { name: i18n.bookingTable.columns.date.label },
  //   { name: i18n.bookingTable.columns.expiringDate.label },
  //   { name: i18n.bookingTable.columns.netPrice.label, isCurrency: true },
  //   { name: i18n.bookingTable.columns.price.label, isCurrency: true },
  //   { name: i18n.bookingTable.columns.quantity.label },
  //   { name: i18n.bookingTable.columns.vatRate.label },
  //   { name: i18n.bookingTable.columns.vatAmount.label, isCurrency: true },
  //   { name: i18n.bookingTable.columns.totalNetPrice.label, isCurrency: true },
  //   { name: i18n.bookingTable.columns.totalGrossPrice.label, isCurrency: true },
  //   { name: i18n.bookingTable.columns.paymentType.label },
  // ];
  // const keys = [
  //   'bookingId',
  //   'service',
  //   'instance',
  //   'date',
  //   'expiringDate',
  //   'netPrice',
  //   'price',
  //   'quantity',
  //   'vatRate',
  //   'vatAmount',
  //   'totalNetPrice',
  //   'totalGrossPrice',
  //   'paymentType',
  // ];

  // const handleExportExcel = () => {
  //   const rows = allBookings.flatMap((booking) => {
  //     return {
  //       bookingId: booking.bookingId,
  //       service: booking.service,
  //       instance: booking.instance,
  //       date: getFormattedDate(booking.date),
  //       expiringDate: getFormattedDate(booking.expiringDate),
  //       netPrice: Number(booking.netPrice),
  //       price: Number(booking.price),
  //       quantity: booking.quantity,
  //       vatRate: booking.vatRate,
  //       vatAmount: Number(booking.vatAmount * booking.quantity),
  //       totalNetPrice: Number(booking.netPrice * booking.quantity),
  //       totalGrossPrice: Number(booking.price * booking.quantity),
  //       paymentType: i18n.paymentTypes[booking.paymentType].label,
  //     };
  //   });

  //   utilities.objectsToExcel({
  //     filename: 'bookings',
  //     header: header(),
  //     keys,
  //     objects: rows,
  //   });
  // };

  return (
    <>
      <MainContainer>
        {surcharge && visibility && (
          <div
            style={{ width: '100%', padding: 8, backgroundColor: '#FFF7CC' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#7F550A',
                alignItems: 'center',
              }}
            >
              <AimTypography variant="h6">
                {`⚠️ On ${format(
                  new Date(surcharge.createdAt),
                  'dd-MM-yyyy'
                )} ${i18n.page.banner.surcharge} ${surcharge.percentage}%`}
              </AimTypography>
              <AimIconAndTextButton
                variant="text"
                small
                onClick={() => setVisibility(false)}
              >
                <CloseIcon />
              </AimIconAndTextButton>
            </div>
          </div>
        )}
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <InnerContainer>
            <Tooltip
              title={
                !checkoutBookings?.length
                  ? i18n.page.back.cart.tooltip
                  : i18n.page.back.checkout.tooltip
              }
            >
              <AimIconAndTextButton
                variant="text"
                text={
                  !checkoutBookings?.length
                    ? i18n.page.back.cart.label
                    : i18n.page.back.checkout.label
                }
                onClick={() => {
                  if (!checkoutBookings?.length) {
                    history.push(`/events/${eventId}/${sponsorId}/my-services`);
                  } else {
                    setCheckoutBookings([]);
                  }
                }}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
            <AimTypography variant={'h1'}>
              {!checkoutBookings?.length
                ? i18n.page.title.cart
                : i18n.page.title.checkout}
            </AimTypography>
          </InnerContainer>
        </div>

        {/* {!checkoutBookings?.length ? ( */}
        <>
          <InnerContainer bottomSpacing grow>
            {allBookings?.length ? (
              <>
                {/* export excel */}
                {/* <div>
                  <AimTitleAndButtons title={i18n.page.title.label}>
                    <Tooltip title={i18n.bookingTable.buttons.export.label}>
                      <AimIconButton
                        variant="green"
                        aria-label="filter"
                        onClick={() => handleExportExcel()}
                      >
                        <CallMadeIcon />
                      </AimIconButton>
                    </Tooltip>
                  </AimTitleAndButtons>
                </div> */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 20,
                  }}
                >
                  {/* <GridTitle item xs={12}> */}
                  <AimTypography style={{ fontSize: 24 }}>
                    {i18n.page.totalAmountDue}
                  </AimTypography>
                  <AimTypography style={{ fontSize: 24 }}>
                    {formatNumber(
                      allBookings.reduce(
                        (res, curr) => (res += curr.price * curr.quantity),
                        0
                      )
                    )}
                  </AimTypography>
                </div>
                <Divider style={{ marginTop: 0, marginBottom: 20 }} />
              </>
            ) : null}
            <Bookings
              {...{
                bookingsCreditCard,
                bookingsBankTransfer: bookingsBankTransfer,
                bookingsBothPayments,
                i18n,
                onClickBuy,
                onClickDelete,
              }}
            />
          </InnerContainer>
          <div style={{ height: 20 }} />
        </>
      </MainContainer>

      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default SponsorCart;
