import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { isWithinInterval, addDays } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import {
  AimSelect,
  AimSelectMenuItem,
  AimRichText,
  StepDivider,
  Title,
  AimSelectForm,
} from '@aim/components';

const formControlStyle = { width: 'calc(100% - 10px)' };

const isInIntervalDate = (startDate, endDate) =>
  isWithinInterval(new Date(), {
    start: new Date(startDate),
    end: addDays(new Date(endDate), 1),
  });

const filterChoices = (presentationTypologies, presentationMode) => {
  return presentationTypologies.filter(
    (pt) =>
      pt.presentationMode === presentationMode &&
      isInIntervalDate(pt.validationDate, pt.expirationDate)
  );
};

const InstructionsStep = ({
  i18n,
  title,
  control,
  variant = 'grey',
  errors,
  instructionsElement,
  abstractService,
  watch,
  setValue,
}) => {
  if (!instructionsElement) return null;

  const {
    instructionsText,
    presentationModes,
    instructions,
  } = instructionsElement;

  const { presentationTypologies } = abstractService;

  const presentationMode = watch('presentationMode');

  const choices = filterChoices(presentationTypologies.items, presentationMode);

  return (
    <>
      <Title>{title}</Title>
      <StepDivider />
      <Controller
        name="presentationMode"
        control={control}
        render={(props) => (
          <AimSelect
            selectVariant={variant}
            label={i18n.wizard.subtitles.instructions}
            value={props.value}
            addAsterisk
            onChange={(e) => {
              if (e.target.value !== props.value) {
                setValue('preferredExposureId', null);
              }
              props.onChange(e.target.value);
            }}
            formControlStyle={formControlStyle}
          >
            {presentationModes.map((pt) => (
              <AimSelectMenuItem key={pt} value={pt}>
                {pt}
              </AimSelectMenuItem>
            ))}
          </AimSelect>
        )}
      />
      {instructions && instructionsText.join('').trim() !== '' && (
        <AimRichText
          readOnly={true}
          variant={variant}
          style={formControlStyle}
          value={JSON.parse(instructions)}
        />
      )}

      <Grid container>
        <AimSelectForm
          selectVariant={variant}
          control={control}
          errors={errors}
          name="preferredExposureId"
          label={i18n.wizard.subtitles.presentations}
          formControlStyle={formControlStyle}
          isRequired
        >
          {choices.map((item) => {
            return (
              <AimSelectMenuItem key={item.id} value={item.id}>
                {item.isLateBreaker
                  ? `${item.name} (${i18n.wizard.subtitles.lateBreaker})`
                  : item.name}
              </AimSelectMenuItem>
            );
          })}
        </AimSelectForm>
      </Grid>
    </>
  );
};

export default InstructionsStep;
