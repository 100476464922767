import React, { useEffect, useState } from 'react';
import { ProductCardDivider, ProductGreyCard } from './styles';
import { codesGeneratorHelper } from '@aim/common';
import {
  AimTypography,
  AimIconAndTextButton,
  AimDialog,
  useAimMediaQuery,
  CustomIntl,
} from '@aim/components';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';
import { translations } from './translations';
import { useHistory } from 'react-router-dom';

const QR_CODE_SIZE = '125.5px';

const ServiceCard = ({
  cardData,
  qrCodeKey = '',
  imgLink = '',
  cardTitle = 'Info',
  serviceDetailLink = '',
}) => {
  const history = useHistory();
  const { isMobile, isMobileOnly } = useAimMediaQuery();
  const { generateQR } = codesGeneratorHelper;
  const intl = CustomIntl(useIntl());
  const i18nMyServices = translations(intl);
  const [qrCodeImg, setQrCodeImg] = useState('');
  const [dialogState, setDialogState] = useState({ isOpen: false });
  const rawDataObj = cardData.find((x) => x.rawData);
  const retryPaymentDataLink = cardData.find((x) => x.retryPaymentDataLink)
    ?.retryPaymentDataLink;

  useEffect(() => {
    const getQRCode = async (key) => {
      const value = Object.values(cardData.find((data) => data[key]))[0];
      const imageStr = await generateQR(value);
      if (imageStr) setQrCodeImg(imageStr);
    };
    if (qrCodeKey && cardData.find((data) => !!data[qrCodeKey]))
      getQRCode(qrCodeKey);
  }, [qrCodeKey]);

  return cardData?.length ? (
    <ProductGreyCard>
      <div
        style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '8.5px' }}
      >
        {cardTitle}
      </div>
      <ProductCardDivider style={{ marginBottom: '22px' }} />
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          alignItems: isMobile ? 'center' : 'flex-start',
          gap: '10px',
        }}
      >
        <Grid container>
          {cardData
            .filter((data) => !!data.value && !!data.label)
            .map((data) => (
              <Grid item key={data.id} xs={12} sm={6} md={6} lg={4}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingRight: '10px',
                  }}
                >
                  <AimTypography variant={'textBold'}>
                    {data.label}:
                  </AimTypography>{' '}
                  <AimTypography variant={'text'}>{data.value}</AimTypography>
                </div>
              </Grid>
            ))}
          {!retryPaymentDataLink && serviceDetailLink ? (
            <Grid item xs={12} style={{ marginTop: '15px' }}>
              <AimIconAndTextButton
                variant="primary"
                text={i18nMyServices.printBtn}
                onClick={() => history.push(serviceDetailLink)}
              />
            </Grid>
          ) : null}
          {retryPaymentDataLink ? (
            <Grid item xs={12} style={{ marginTop: '15px' }}>
              <AimIconAndTextButton
                variant="primary"
                text={i18nMyServices.buyBtn}
                onClick={() =>
                  history.push(retryPaymentDataLink, {
                    prevData: rawDataObj,
                  })
                }
              >
                <ShoppingCartIcon />
              </AimIconAndTextButton>
            </Grid>
          ) : null}
        </Grid>
        {qrCodeImg || imgLink ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '15px',
            }}
          >
            <img
              src={qrCodeImg || imgLink}
              style={{ width: QR_CODE_SIZE, height: QR_CODE_SIZE }}
            />
            {isMobile && !imgLink ? (
              <AimIconAndTextButton
                variant="primary"
                text={i18nMyServices.openBtn}
                onClick={() => setDialogState({ isOpen: true })}
                style={{ marginBottom: '15px' }}
              />
            ) : null}
          </div>
        ) : null}
      </div>
      <AimDialog
        title={cardTitle}
        open={dialogState.isOpen}
        onClose={() => setDialogState({ isOpen: false })}
        hideAgreeButton
        hideCancelButton
        style={{
          width: '85%',
          minWidth: '300px',
          margin: '0 auto',
        }}
        PaperProps={{
          style: {
            paddingRight: 30,
            paddingLeft: 30,
            paddingTop: 20,
            paddingBottom: 30,
            width: isMobileOnly ? '85%' : '60%',
          },
        }}
      >
        <img src={qrCodeImg} style={{ width: '100%' }} />
      </AimDialog>
    </ProductGreyCard>
  ) : (
    <></>
  );
};
export { ServiceCard };
