import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import {
  format,
  parseISO,
  areIntervalsOverlapping,
  differenceInMinutes,
  isWithinInterval,
  endOfDay,
} from 'date-fns';
import { groupBy } from 'lodash';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  AimTypography,
  AimTitleAndButtons,
  AimIconAndTextButton,
  CustomIntl,
  theme,
} from '@aim/components';
import { aws, constants, appState } from '@aim/common';

import AdditionalServiceCard from './additionalServices/AdditionalServiceCard';
import {
  listAdditionalServicesConfigs,
  getProductsByServiceIdServiceType,
} from './additionalServices/gqlHelper';
import useI18n from './additionalServices/i18n';
import { isMobileOnly } from 'react-device-detect';
import AdditionalServicesFilter from './additionalServices/AdditionalServicesFilter';

const useStyles = makeStyles(() => ({
  accordion: {
    '&:before': {
      display: 'none',
    },
  },
}));

const Cards = ({
  services,
  eventId,
  type,
  i18n,
  history,
  configRule,
  myAdditionalServiceConfigProduct,
  myAdditionalServiceProducts,
}) =>
  services.map((s) => {
    const userHasTicket = appState.user.getValue()?.userAndParticipation
      ?.participation?.hasTicket;
    const isRegistrationRequired = s.requiredRegistration && !userHasTicket;
    const isNotAvailable =
      s.hasConflictingPurchasedEvents ||
      configRule === constants.rules.ONLYONE.id
        ? myAdditionalServiceConfigProduct?.length
        : configRule === constants.rules.YESNO.id
        ? myAdditionalServiceProducts?.[s.id]?.length
        : false;
    return (
      <AdditionalServiceCard
        key={s.id}
        onClick={() =>
          s.availableSubscription > 0 &&
          !isNotAvailable &&
          !isRegistrationRequired &&
          history.push(`/events/${eventId}/additional-services/${type}/${s.id}`)
        }
        date={s.dateService && format(parseISO(s.dateService), 'dd MMMM yyyy')}
        time={s.startTime}
        title={s.title}
        image={s.image}
        i18n={i18n}
        availableSubscription={s.availableSubscription}
        isNotAvailable={isNotAvailable}
        isRegistrationRequired={isRegistrationRequired}
        tooltipText={
          isRegistrationRequired ? i18n.card.registrationRequiredMessage : ''
        }
      />
    );
  });

const AdditionalServicesBlocks = ({
  configs,
  myAdditionalServiceConfigProducts,
  myAdditionalServiceProducts,
  eventId,
  type,
  i18n,
  history,
}) => {
  const classes = useStyles();
  return configs.map((config) => (
    <>
      <Accordion
        classes={{
          root: classes.accordion,
        }}
        style={{
          borderRadius: 3,
          boxShadow: 'none',
          backgroundColor: theme.colors.greyScale.backgroundGrey,
          margin: '10px 0px',
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            '&:before': {
              display: 'none',
            },
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <AimTypography
              variant="h2"
              style={{ flex: 1 }}
              boxStyle={{
                marginTop: 0,
                marginBottom: 0,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              {config.name}
            </AimTypography>
            <AimTypography
              variant="text"
              style={{ flex: 1 }}
              boxStyle={{
                marginTop: 0,
                marginBottom: 0,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              {config.description}
            </AimTypography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Cards
              services={config.additionalServices}
              eventId={eventId}
              type={type}
              i18n={i18n}
              history={history}
              configRule={config.rule}
              myAdditionalServiceConfigProduct={
                myAdditionalServiceConfigProducts[config.id]
              }
              myAdditionalServiceProducts={myAdditionalServiceProducts}
            ></Cards>
            {/* </Grid> */}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}> */}
      {/* </Grid>
      <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}> */}
      {/* </Grid>
      <Grid item xs={12} style={{ marginTop: 0, marginBottom: 0 }}> */}
    </>
  ));
};

const checkConflictingPuchasedEvents = (
  allAdditionalServices,
  currentAdditionalService,
  groupedProducts,
  myId
) => {
  const myProducts = Object.values(groupedProducts)
    .filter((gp) => gp.some((p) => p.clientId === myId))
    .flat();

  const myAdditionalServices = myProducts
    .map((p) => allAdditionalServices.find((as) => as.id === p.productId))
    .filter((i) => i);

  const { startTime, endTime, dateService } = currentAdditionalService;

  if (dateService && startTime !== '' && endTime !== '') {
    const currentASInterval = {
      start: new Date(`${dateService}T${startTime}`),
      end: new Date(`${dateService}T${endTime}`),
    };

    const overlappingServices = myAdditionalServices
      .filter((myAS) => myAS && myAS.id !== currentAdditionalService.id)
      .some((myAS) => {
        // console.log('🔌 myAS, currAS ', myAS, currentAdditionalService);

        if (myAS.startTime) {
          if (myAS.endTime) {
            const myASInterval = {
              start: new Date(`${myAS.dateService}T${myAS.startTime}`),
              end: new Date(`${myAS.dateService}T${myAS.endTime}`),
            };
            return areIntervalsOverlapping(currentASInterval, myASInterval);
          } else {
            const myAsStart = new Date(`${myAS.dateService}T${myAS.startTime}`);
            const minutesBetweenDates = Math.abs(
              differenceInMinutes(currentASInterval.start, myAsStart)
            );
            return minutesBetweenDates < 30; // start dates are less than 30 minutes
          }
        }
      });

    // console.log('overlappingServices ', overlappingServices);

    return overlappingServices;
  }
  return false;
};

const mapAdditionalService = async (
  additionalServiceId,
  additionalServices,
  groupedProducts,
  eventId,
  myId,
  allAdditionalServices
) => {
  const currentAdditionalService = additionalServices?.items?.find(
    (i) => i.id === additionalServiceId && !i.isDeleted
  );

  if (!currentAdditionalService) return null;
  const availableSubscription = currentAdditionalService.maxSubscription
    ? (parseInt(currentAdditionalService.maxSubscription, 10) || 0) -
      (groupedProducts[currentAdditionalService.id]?.length || 0)
    : 0;

  const s3Folder = `events/${eventId}/additional-services/${currentAdditionalService.id}/gallery/`;
  const firstImg = currentAdditionalService?.images?.items[0];
  let image;
  if (firstImg) {
    image = await aws.Storage.get(
      `${s3Folder}${firstImg.id}${firstImg.extension}`
    );
  }
  return {
    ...currentAdditionalService,
    hasConflictingPurchasedEvents: checkConflictingPuchasedEvents(
      allAdditionalServices,
      currentAdditionalService,
      groupedProducts,
      myId
    ),
    image,
    availableSubscription,
  };
};

const AdditionalServices = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  const { eventId, type } = useParams();

  const [additionalServicesConfigs, setAdditionalServicesConfigs] = useState(
    []
  );
  const [
    displayAdditionalServicesConfigs,
    setDispalyAdditionalServicesConfigs,
  ] = useState([]);
  const [
    myAdditionalServiceConfigProducts,
    setMyAdditionalServiceConfigProducts,
  ] = useState();
  const [
    myAdditionalServiceProducts,
    setMyAdditionalServiceProducts,
  ] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const me = appState.user.getValue()?.userAndParticipation.participation;

      const res = await Promise.all([
        listAdditionalServicesConfigs(eventId),
        getProductsByServiceIdServiceType(
          eventId,
          constants.AdditionalServicesServiceTypology[type]?.productServiceType
        ),
      ]);

      const allAdditionalServices = res[0].additionalServicesConfigs?.items.flatMap(
        (asc) => asc.additionalServices?.items || []
      );

      const bracketExist = (additionalService) => {
        const bracketFound = additionalService.prices?.items?.find((price) => {
          const isWithinBracketInterval = isWithinInterval(new Date(), {
            start: new Date(price.start),
            end: endOfDay(new Date(price.end)),
          });
          return isWithinBracketInterval;
        });
        return bracketFound;
      };

      const sortedAdditionalServicesConfig = res[0].additionalServicesConfigs?.items
        .map((asc) => ({
          ...asc,
          additionalServices: {
            items: asc.additionalServices.items.filter(
              (as) =>
                (!as.reservedToProfiles ||
                  (me.profile &&
                    as.reservedToProfiles.includes(me.profile.id))) &&
                (!as.reservedToCluster ||
                  (me.cluster && as.reservedToCluster.includes(me.cluster))) &&
                (!as.reservedToTipologies ||
                  (me.typology &&
                    as.reservedToTipologies.includes(me.typology.id))) &&
                bracketExist(as)
            ),
          },
        }))
        ?.filter((asc) => asc.additionalServiceType === type)
        .sort((a, b) =>
          a.position > b.position ? 1 : b.position > a.position ? -1 : 0
        );

      const groupedAdditionalServiceProducts = groupBy(
        res[1],
        (product) => product.productId
      );
      const myAdditionalServiceConfigProducts = groupBy(
        res[1].filter((p) => p.clientId === me.id),
        (product) =>
          sortedAdditionalServicesConfig.find((c) =>
            c.additionalServices.items
              .map((as) => as.id)
              .includes(product.productId)
          )?.id
      );

      const myAdditionalServiceProducts = groupBy(
        res[1].filter((p) => p.clientId === me.id),
        (product) => product.productId
      );

      const nextAdditionalServicesConfig = await Promise.all(
        sortedAdditionalServicesConfig.map(
          async ({ additionalServices = [], ...config }) => {
            // fix for null order
            const promises = config?.additionalServicesOrder
              ? config?.additionalServicesOrder?.map(
                  async (el) =>
                    await mapAdditionalService(
                      el,
                      additionalServices,
                      groupedAdditionalServiceProducts,
                      eventId,
                      me.id,
                      allAdditionalServices
                    )
                )
              : additionalServices?.items
                  ?.map((as) => as.id)
                  .map(
                    async (el) =>
                      await mapAdditionalService(
                        el,
                        additionalServices,
                        groupedAdditionalServiceProducts,
                        eventId,
                        me.id,
                        allAdditionalServices
                      )
                  );
            const nextAdditionalServices = await Promise.all(promises);
            console.log('nextAdditionalServices', nextAdditionalServices);
            return {
              ...config,
              additionalServices: nextAdditionalServices.filter((as) => !!as),
            };
          }
        )
      );
      const filteredAdditionalServicesConfig = nextAdditionalServicesConfig.filter(
        (c) => c.additionalServices.length
      );
      setMyAdditionalServiceConfigProducts(myAdditionalServiceConfigProducts);
      setMyAdditionalServiceProducts(myAdditionalServiceProducts);
      setAdditionalServicesConfigs(filteredAdditionalServicesConfig);
      setDispalyAdditionalServicesConfigs(filteredAdditionalServicesConfig);
    };
    fetchData();
  }, []);

  const TitleAndButton = () => (
    <div style={{ marginBottom: 10 }}>
      <AimTitleAndButtons
        titleBoxStyle={{ marginLeft: 0 }}
        title={constants.AdditionalServicesServiceTypology?.[type]?.label(intl)}
      />
    </div>
  );

  return (
    <>
      <div
        style={{
          flex: 1,
          width: isMobileOnly ? '98%' : '70%',
        }}
      >
        <AimIconAndTextButton
          variant="none"
          text={intl.formatMessage({
            description: 'back',
            defaultMessage: 'back',
          })}
          style={{
            padding: 0,
            marginLeft: 0,
          }}
          onClick={() => history.push(`/events/${eventId}/landing`)}
        >
          <ArrowBack />
        </AimIconAndTextButton>
        <TitleAndButton />
        <Grid container>
          <Grid item xs={12}>
            <AdditionalServicesFilter
              i18n={i18n.filter}
              onFilterResult={(e) => {
                setDispalyAdditionalServicesConfigs(e);
              }}
              rows={additionalServicesConfigs}
              // sortByName={sortByName()}
            />
          </Grid>
        </Grid>

        {/* <Grid container spacing={2}> */}
        <AdditionalServicesBlocks
          configs={displayAdditionalServicesConfigs}
          myAdditionalServiceConfigProducts={myAdditionalServiceConfigProducts}
          myAdditionalServiceProducts={myAdditionalServiceProducts}
          eventId={eventId}
          type={type}
          i18n={i18n}
          history={history}
        />
        {/* </Grid> */}
      </div>
    </>
  );
};

export default AdditionalServices;
