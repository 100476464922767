import { aws, appState } from '@aim/common';
import { breakoutRoomAttendingSchema } from './breakoutRoomAttendingGqlHelper';
import { breakoutRoomInvitationUnregisteredSchema } from './breakoutRoomInvitationUnregisteredGqlHelper';
import { useBreakoutRoomSlots } from './breakoutRoomSlotGqlHelper';

const { API } = aws;
const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const sponsorSchema = `
id
name
`;

const breakoutRoomSlotsSchema = `
id
title
start
end
note
emails {
  items {
    ${breakoutRoomInvitationUnregisteredSchema}
  }
}
isOneToOne
invitations {
  items {
    ${breakoutRoomAttendingSchema}
  }  
}
`;

export const breakoutRoomSchema = `
id
date
start1
start2
end1
end2
duration
maxAttendants
purchasedHours
sponsor{
  id
  name
}
sponsorPackageItem {
  package {
    buyOperations {
      items {
        id
        purchase {
          id
          date
          paymentType
          payment {
            paymentIdMonetaOnLine
            paymentIdIngenico
            paymentIdGpWebpay
            paymentType
          }
        }
      }
    }
  }
}
buyOperation {
  id
  date
  unitaryPrice
  unitaryVatRate
  purchase {
    id
    date
    paymentType
    payment {
      paymentIdMonetaOnLine
      paymentIdIngenico
      paymentIdGpWebpay
      paymentType
    }
  }
} 
slots {
  items {
    ${breakoutRoomSlotsSchema}
  }
}
`;

const getEventQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      start
      end
    }
  }
`;

const getEventBreakoutRoomQuery = /* GraphQL */ `
  query GetBreakoutRoom($id: ID!) {
    getBreakoutRoom(id: $id) {
      ${breakoutRoomSchema}
      breakoutRoomService {
        id
        price
        vatRate
        maxAvailableHours
        isSponsorEditable
      }
    }
  }
`;

const createBreakoutRoomMutation = /* GraphQL */ `
  mutation CreateBreakoutRoom(
    $input: CreateBreakoutRoomInput!
    $condition: ModelBreakoutRoomConditionInput
  ) {
    createBreakoutRoom(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteBreakoutRoomMutation = /* GraphQL */ `
  mutation DeleteBreakoutRoom(
    $input: DeleteBreakoutRoomInput!
    $condition: ModelBreakoutRoomConditionInput
  ) {
    deleteBreakoutRoom(input: $input, condition: $condition) {
      id
      slots {
        items {
          id
        }
      }
    }
  }
`;

const updateBreakoutRoomMutation = /* GraphQL */ `
  mutation UpdateBreakoutRoom(
    $input: UpdateBreakoutRoomInput!
    $condition: ModelBreakoutRoomConditionInput
  ) {
    updateBreakoutRoom(input: $input, condition: $condition) {
      id
    }
  }
`;

export function useBreakoutRoom(useLoader = false) {
  const get = (id, useLoader = useLoader) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getEventBreakoutRoomQuery,
        variables: { id },
      })
        .then((response) => resolve(response.data.getBreakoutRoom))
        .catch((e) => {
          console.error('get-breakout-room', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const create = (input) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createBreakoutRoomMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.createBreakoutRoom))
        .catch((e) => {
          console.error('create-breakout-room', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const remove = (id) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: deleteBreakoutRoomMutation,
        variables: { input: { id } },
      })
        .then(async (response) => {
          const brSlotHelper = useBreakoutRoomSlots();

          const br = response.data.deleteBreakoutRoom;
          const promises = [];
          br.slots.items.map((slot) => {
            promises.push(brSlotHelper.remove(slot.id));
          });
          if (promises.length) {
            await Promise.all(promises);
          }
          resolve(response.data.deleteBreakoutRoom);
        })
        .catch((e) => {
          console.error('delete-breakout-room', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateBreakoutRoomMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.updateBreakoutRoom))
        .catch((e) => {
          console.error('update-breakout-room', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const getEvent = (id) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getEventQuery,
        variables: { id: id },
      })
        .then((response) => resolve(response.data.getEvent))
        .catch((e) => {
          console.error('get-event', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { get, create, remove, update, getEvent };
}
