import { appState, aws } from '@aim/common';

const getSymposiumServicesServiceQuery = `
query GetSymposiumServicesService($id: ID!) {
  getEvent(id: $id) {
    sponsorServiceBookingDuration
    symposiumServicesService {
      id
      description
      isBankTransferEnabled
      isCreditCardEnabled
      publicationDate
      name
      vatRate
      documents {
        items {
          id
          name: originalName
          size
          extension
        }
      }
      symposiumServicesItems {
        items {
          id
          code
          name
          price
          quantity
          purchase {
            items {
              id
              quantity
              buyOperation {
                id
                isDeleted
                isExpired
                purchase {
                  id
                  purchaseId
                  paymentType
                  payment {
                    paymentId
                    paymentIdMonetaOnLine
                    shasignIngenico
                    paymentIdGpWebpay
                    paymentType
                  }
                }
              }
              sponsorPackageItem {
                quantity
                package {
                  buyOperations {
                    items {
                      id
                      isDeleted
                      isExpired
                      purchase {
                        id
                        purchaseId
                        paymentType
                        payment {
                          paymentId
                          paymentIdMonetaOnLine
                          shasignIngenico
                          paymentIdGpWebpay
                          paymentType
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const getSymposiumServicesService = (eventId) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getSymposiumServicesServiceQuery,
      variables: { id: eventId },
    })
      .then(({ data }) => {
        resolve({
          ...data.getEvent.symposiumServicesService,
          sponsorServiceBookingDuration:
            data.getEvent.sponsorServiceBookingDuration,
        });
      })
      .catch((e) => {
        console.error('get-symposium-services-service', e);
        reject(e);
      })
      .finally(hideLoader);
  });

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);
