import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import {
  styled,
  AimIconAndTextButton,
  CustomIntl,
  AimTypography,
} from '@aim/components';
import { appState } from '@aim/common';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';

import { useIntl } from 'react-intl';
import useI18n from '../translation';

import BookingTable from '../../sponsorCart/BookingTable';
import { format } from 'date-fns';

const ContentContainer = styled('div')({
  padding: 20,
});

const ActionsContainer = styled('div')({
  padding: 20,
  display: 'flex',
  justifyContent: 'flex-end',
});

const CartPopover = ({
  cartItems,
  anchorEl,
  isOpen,
  handleClose,
  surcharge,
}) => {
  const history = useHistory();
  const { eventId } = useParams();
  const [visibility, setVisibility] = useState(true);

  const intl = CustomIntl(useIntl());
  const i18nSponsorCart = useI18n.sponsorCart(intl);
  const i18n = useI18n.cartPopover(intl);

  const participation = appState.user.getValue()?.userAndParticipation
    ?.participation;

  const sponsor = participation?.sponsor; // || participation?.sponsorStaff?.sponsor;

  const onCartButtonClick = () => {
    history.push(`/events/${eventId}/${sponsor?.id}/cart`);
    handleClose();
  };

  useEffect(() => {
    return () => {
      setVisibility(true);
    };
  }, []);

  return (
    <Popover
      open={isOpen}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ContentContainer>
        {surcharge && visibility && (
          <div
            style={{ width: '100%', padding: 8, backgroundColor: '#FFF7CC' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#7F550A',
                alignItems: 'center',
              }}
            >
              <AimTypography variant="h6" style={{ color: '#7F550A' }}>
                {`⚠️ On ${format(
                  new Date(surcharge.createdAt),
                  'dd-MM-yyyy'
                )} ${i18nSponsorCart.page.banner.surcharge} ${
                  surcharge.percentage
                }%`}
              </AimTypography>
              <AimIconAndTextButton
                variant="text"
                small
                onClick={() => setVisibility(false)}
              >
                <CloseIcon />
              </AimIconAndTextButton>
            </div>
          </div>
        )}
        <AimTypography variant="h1">{i18n.title}</AimTypography>
        <BookingTable
          bookings={cartItems}
          i18n={i18nSponsorCart}
          sinteticMode
        />
      </ContentContainer>
      <ActionsContainer>
        <AimIconAndTextButton
          onClick={onCartButtonClick}
          variant="yellow"
          text={i18n.goToCart}
        >
          <ShoppingCartIcon />
        </AimIconAndTextButton>
      </ActionsContainer>
    </Popover>
  );
};

export { CartPopover };
