import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useI18n from './paxAbstractsEdit/i18n';
import { useForm } from 'react-hook-form';

import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import {
  endOfDay,
  isAfter,
  parseISO,
  isWithinInterval,
  addDays,
} from 'date-fns';

import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Tooltip from '@material-ui/core/Tooltip';
import DialogContent from '@material-ui/core/DialogContent';
import { sortBy } from 'lodash';
import { aws, constants, appState, fileHelper, utilities } from '@aim/common';
import {
  styled,
  AimSnackbar,
  AimSnackbarSeverity,
  AimStepper,
  // StepDotTitle,
  AimTitleAndButtons,
  AimIconAndTextButton,
  CustomIntl,
  AbstractDetailPage,
  AimDialog,
  AimTypography,
  AimMegadraftToJSON,
} from '@aim/components';

import InstructionsStep from './paxAbstractsEdit/steps/InstructionsStep';
import TopicStep from './paxAbstractsEdit/steps/TopicStep';
import AgreementStep from './paxAbstractsEdit/steps/AgreementStep';
// import AifaData from './paxAbstractsEdit/steps/AifaData';
import ContentStep from './paxAbstractsEdit/steps/ContentStep';
import AffiliationsStep from './paxAbstractsEdit/steps/AffiliationsStep';
import AuthorsAndPresenterStep from './paxAbstractsEdit/steps/AuthorsAndPresenterStep';
import { getEvent } from './paxAbstractsEdit/gqlHelper';
import AdditionalData from './paxAbstractsEdit/steps/AdditionalData';
import { getPreTitles } from '../../../aim-event/src/pages/shared/preTitlesGqlHelper';

import {
  getAbstractDocumentDetail,
  getEventAbstractService,
  createAbstractDocument,
  updateAbstractDocument,
  createFieldValue,
  updateFieldValue,
  createAbstractAffiliation,
  updateAbstractAffiliation,
  deleteAbstractAffiliation,
  createAbstractAuthor,
  updateAbstractAuthor,
  deleteAbstractAuthor,
  getAbstractAuthor,
  // deleteFieldValue,
  deleteFile,
} from './shared/participationGqlHelper';

import { useWelcomePageModulesRedirects } from './../hooks/useWelcomePageModulesRedirects';

const CustomSnackbar = styled(AimSnackbar)({
  '& .MuiAlert-action': {
    alignItems: 'start',
  },
});

const PaxAbstractsEdit = () => {
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { eventId, abstractId } = useParams();
  const [abstractService, setAbstractService] = useState();
  const [eventType, setEventType] = useState();
  const [grants, setGrants] = useState();
  const [grantDialog, setGrantDialog] = useState({ isOpen: false });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });
  const [abstractDocument, setAbstractDocument] = useState();
  // const [participation, setParticipation] = useState();
  const [participation] = useState(appState.getCurrentParticipation());

  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [files, setFiles] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [titles, setTitles] = useState([]);
  const [preTitles, setPreTitles] = useState([]);
  const [standardFields, setStandardFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [registryValues, setRegistryValues] = useState([]);

  const { getServiceRedirectLinkEvent } = useWelcomePageModulesRedirects();

  const [instructionsElement, setInstructionsElement] = useState({});

  /*
   * Custom validation for authors array
   */
  const customValidation = (value, helpers) => {
    // Check and eventually get if there's a presenter in authors array
    const presenter = value.find((author) => author.isPresentingAuthor);
    // Array of authors
    const authors = value.filter((author) => !author.isPresentingAuthor);

    const mandatoryFieldsMap = {
      abstract_title: 'preTitle',
      abstract_name_extended: 'name',
      abstract_surname: 'surname',
      abstract_email: 'email',
      abstract_city: 'city',
      abstract_country: 'country',
    };

    // Check if selected presenter has complete data (name, surname, email)
    const mandatoryFieldsForAuthors = registryValues
      .filter((field) =>
        field.key === constants.StandardFieldAbstractEnum.PRE_TITLE.key
          ? preTitles.length > 0 && field.isMandatoryForAuthor
          : field.isMandatoryForAuthor
      )
      .map((field) => field.key);

    const mandatoryFieldsForPresenters = registryValues
      .filter((field) =>
        field.key === constants.StandardFieldAbstractEnum.PRE_TITLE.key
          ? preTitles.length > 0 && field.isManadatoryForPresenter
          : field.isManadatoryForPresenter
      )
      .map((field) => field.key);

    const areValidPresenterForAifaFields =
      !abstractService.isAifaEvent ||
      (presenter &&
        presenter.jobTitle !== '' &&
        presenter.speciality !== '' &&
        presenter.degree !== '');

    const isValidPresenter =
      mandatoryFieldsForPresenters.every(
        (key) => presenter && presenter[mandatoryFieldsMap[key]]
      ) && areValidPresenterForAifaFields;

    const areValidAuthorsForAifaFields =
      !abstractService.isAifaEvent ||
      abstractService.isRequestAifaForPresenter ||
      (!abstractService.isRequestAifaForPresenter &&
        authors.every(
          (author) =>
            author &&
            author.jobTitle !== '' &&
            author.speciality !== '' &&
            author.degree !== ''
        ));

    const areValidAuthors =
      authors
        .map((author) => {
          return mandatoryFieldsForAuthors.every(
            (key) =>
              author &&
              !!(typeof author[mandatoryFieldsMap[key]] === 'object'
                ? author[mandatoryFieldsMap[key]].value
                : author[mandatoryFieldsMap[key]])
          );
        })
        .every((value) => value === true) && areValidAuthorsForAifaFields;

    if (!presenter) {
      return helpers.error('any.required');
    }

    if (!isValidPresenter || !areValidAuthors) {
      return helpers.error('data.required');
    }

    return value;
  };

  const schema = ({ minNumberKeyword = 1 }) =>
    Joi.object({
      isAgreementChecked: Joi.boolean().valid(true).required().messages({
        // 'string.base': `"field" should be a type of 'text'`,
        // 'string.empty': `"field" cannot be an empty field`,
        // 'string.min': `"field" should have a minimum length of {#limit}`,
        'any.required': i18n.wizard.errors.snackbar.isAgreementChecked,
      }),
      // .label('is agreement checked'),
      title: Joi.string().required().messages({
        'any.required': i18n.wizard.errors.snackbar.title,
      }),
      authors: Joi.array()
        .custom(customValidation, 'custom validation for authors array')
        .messages({
          'any.required': i18n.wizard.errors.snackbar.presenterNeeded,
          'data.required': i18n.wizard.errors.snackbar.presenterNotComplete,
        }),
      isOwnerAPresentingAuthor: Joi.boolean(),
      presenterParticipation: Joi.object().optional(),
      presentationMode: Joi.string().required(),
      abstractSections: Joi.array(),
      tags: Joi.any().optional(),
      affiliations: Joi.array().optional(),
      additionalData: Joi.array().optional(),
      isReadyToReview: Joi.any().optional(),
      keywords: Joi.array()
        .items(Joi.string())
        .ruleset.min(minNumberKeyword)
        .rule({
          message: `${i18n.wizard.errors.snackbar.minNumberKeyword} ${minNumberKeyword}`,
        }),
      preferredExposureId: Joi.string().required(),
      category: Joi.string().required(),
      subCategory: Joi.string().optional(),
      standardDefaultValues: Joi.object().optional(),
      customDefaultValues: Joi.object().optional(),
      participant: Joi.object().optional(),
      updatedAt: Joi.string().optional(),
      createdAt: Joi.string().optional(),
      isDeleted: Joi.any().optional(),
      reviews: Joi.any().optional(),
      proposedPresentationTypology: Joi.any().optional(),
      acceptedPresentationTypology: Joi.any().optional(),
      processingStatus: Joi.any().optional(),
      status: Joi.string().optional(),
      id: Joi.string().optional(),
      code: Joi.string().optional(),
    });

  const {
    control,
    handleSubmit,
    register,
    errors,
    reset,
    watch,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      authors: [],
      abstractSections: [],
      affiliations: [],
    },
    shouldUnregister: false,
    resolver: joiResolver(
      schema({
        minNumberKeyword: !abstractService?.minNumberKeyword
          ? 0
          : abstractService?.minNumberKeyword,
      })
    ),
  });

  const fetchPreTitles = async () => {
    const fetchedPreTitles = await getPreTitles({ id: eventId });
    // This map should be useless since the titles should already be unique (in old events we still have dirty data)
    const titlesUniqueByKey = [
      ...new Map(fetchedPreTitles.map((item) => [item['key'], item])).values(),
    ];
    // Filter for only active and compiled titles
    const activeTitles = titlesUniqueByKey.filter(
      (title) => title.isActive && title.title
    );
    setPreTitles(activeTitles);
  };

  useEffect(() => {
    if (!eventId) return;

    getEvent(eventId, 'abstract').then((e) => {
      setEventType(e.type);
      const filteredGrants = e.grant?.items?.filter((grant) =>
        // !grant.closingDate ||
        isAfter(endOfDay(parseISO(grant.closingDate)), new Date())
      );
      setGrants(filteredGrants);
    });
    getEventAbstractService(eventId).then((resultAbstractService) => {
      // Parse registry values for determine which author and presenter fields are mandatory or not
      const defaultRegistryValues = resultAbstractService.registry
        ? JSON.parse(resultAbstractService.registry)
        : [];
      setRegistryValues(defaultRegistryValues);

      const innerAbsService = {
        ...resultAbstractService,
        templateFields: mapToContextsOfUse(
          resultAbstractService.event.services.items[0].customFields,
          constants.AbstractFieldContext.AbstractTemplate
        ),
        additionalDataFields: mapToContextsOfUseAbstract(
          resultAbstractService.event.standardFields,
          resultAbstractService.event.services.items[0].customFields,
          constants.AbstractFieldContext.Abstract
        ),
      };
      const { instructions, presentationTypologies } = innerAbsService;
      // DA RIVEDERE PERCHE' DA BACKOFFICE VIENE SALVATO "NULL" IN STRINGA
      const instructionsText =
        instructions !== 'null'
          ? JSON.parse(instructions).blocks.map((b) => b.text)
          : [];

      const presentationModes = presentationTypologies.items.reduce(
        (acc, curr) => {
          if (!acc.includes(curr.presentationMode)) {
            acc.push(curr.presentationMode);
          }
          return acc;
        },
        []
      );
      let presentationTypes = [];
      if (presentationModes.length === 1) {
        presentationTypes = presentationTypologies.items.filter(
          (pt) =>
            pt.presentationMode === presentationModes[0] &&
            isInIntervallDate(pt.validationDate, pt.expirationDate)
        );
      }

      setInstructionsElement({
        presentationModes,
        instructionsText,
        instructions,
      });

      const titles = [
        {
          title: i18n.wizard.instructions,
          isHidden:
            presentationModes.length === 1 &&
            instructionsText.join('').trim() === '' &&
            presentationTypes.length === 1,
        },
        // { title: i18n.wizard.presentationType, isHidden: false },
        {
          title: i18n.wizard.topics,
          isHidden: false,
        },
        { title: i18n.wizard.affiliations, isHidden: false },
        { title: i18n.wizard.authorsAndPresenter, isHidden: false },
        {
          title: i18n.wizard.additionalData,
          isHidden: !innerAbsService.additionalDataFields?.length,
        },
        { title: i18n.wizard.content, isHidden: false },
        { title: i18n.wizard.agreements, isHidden: false },
      ];

      setAbstractService(innerAbsService);
      setTitles(titles);
    });
    fetchPreTitles();
  }, []);

  const isInIntervallDate = (startDate, endDate) =>
    isWithinInterval(new Date(), {
      start: new Date(startDate),
      end: addDays(new Date(endDate), 1),
    });

  useEffect(() => {
    if (!abstractId || abstractId === 'create') {
      const innerDocument = {};
      if (abstractService) {
        innerDocument.abstractSections = mapToAbstractFields(
          [],
          abstractService.templateFields
        );
        // Add default empty author
        innerDocument.authors = [
          {
            affiliation: '',
            city: '',
            country: '',
            degree: '',
            email: '',
            isPresentingAuthor: false,
            jobTitle: '',
            name: '',
            speciality: '',
            surname: '',
            preTitle: '',
          },
        ];
        innerDocument.keywords = [];

        const presentationMode = instructionsElement.presentationModes?.[0];
        innerDocument.presentationMode = presentationMode;

        const { instructions, presentationTypologies } = abstractService;
        const choices = presentationTypologies.items.filter(
          (pt) =>
            presentationMode &&
            pt.presentationMode === presentationMode &&
            isInIntervallDate(pt.validationDate, pt.expirationDate)
        );
        if (choices.length === 1) {
          innerDocument.preferredExposureId = choices[0].id;
        }

        // DA RIVEDERE PERCHE' DA BACKOFFICE VIENE SALVATO "NULL" IN STRINGA
        const instructionsText =
          instructions !== 'null'
            ? JSON.parse(instructions).blocks.map((b) => b.text)
            : [];

        setInstructionsElement({
          ...instructionsElement,
          instructionsText,
          instructions,
        });

        setAbstractDocument(innerDocument);
        reset(innerDocument);
      }
    } else {
      if (abstractService) {
        getAbstractDocumentDetail(abstractId).then((graphqlDocument) => {
          const {
            preferredExposure,
            proposedPresentationTypology,
            ...document
          } = graphqlDocument;
          const innerDocument = {
            ...document,
            affiliations: document.affiliations.items.map((a) => ({
              ...a,
              city: { label: a.city, value: a.city },
              country: { label: a.country, value: a.country },
            })),
            category: document?.category?.id,
            subCategory: document?.subCategory?.id,
            presentationMode: preferredExposure,
            preferredExposureId: proposedPresentationTypology?.id,
            abstractSections: mapToAbstractFields(
              document.abstractSections.items.map((fieldValue) => ({
                ...fieldValue,
                value: fieldValue.value
                  ? JSON.parse(fieldValue.value)
                  : undefined,
              })),
              abstractService.templateFields
            ),
            additionalData: mapToAbstractFields(
              document.additionalData.items.map((fieldValue) => ({
                ...fieldValue,
                value: JSON.parse(fieldValue.value),
              })),
              abstractService.additionalDataFields
            ),
            authors: document.authors.items
              .map((author) => ({
                ...author,
                affiliation: author.affiliation?.id || '',
                city: { label: author.city, value: author.city },
                country: { label: author.country, value: author.country },
              }))
              .sort((a, b) => a.position - b.position),
            keywords: (!document.keywords && []) || document.keywords,
            isAgreementChecked: document.isAgreementChecked,
          };

          setAbstractDocument(innerDocument);
          reset(innerDocument);
        });
      }
    }
  }, [abstractId, abstractService, eventType]);

  const createOrUpdateFieldValues = async ({
    fieldValueConnectionIdObj,
    fields,
    abstractDocumentId,
    type,
  }) => {
    // TODO: da rivedere ora non avevo tempo di fare un refactoring
    const uploadFields = fields.filter(
      (field) =>
        field.fieldDefinition.controlType ===
        constants.FieldControlTypesEnum.UPLOAD.id
    );
    const allOtherFields = fields.filter(
      (field) =>
        field.fieldDefinition.controlType !==
        constants.FieldControlTypesEnum.UPLOAD.id
    );
    const oldUploadFields =
      abstractDocument?.[type]?.filter(
        (field) =>
          field.fieldDefinition.controlType ===
          constants.FieldControlTypesEnum.UPLOAD.id
      ) || [];
    const writedFiles = await uploadFiles(
      abstractDocumentId,
      uploadFields.map((f) => ({
        ...f,
        fieldDefinitionId: f.fieldDefinition.id,
      })),
      undefined // we won't have the abstract attachment connection
    );

    oldUploadFields?.length &&
      (await deleteFiles(oldUploadFields, uploadFields));

    const filesToDelete =
      oldUploadFields
        .flatMap((x) => x?.value || [])
        ?.filter(
          (f) =>
            !uploadFields
              .flatMap((x) => x?.value || [])
              .find((i) => i.id === f.id)
        ) || [];

    const uploadFieldsValueToSave = uploadFields.map((f) => ({
      ...f,
      value: [
        ...writedFiles.filter(
          (wf) => wf.fieldDefinitionId === f.fieldDefinition.id
        ),
        ...oldUploadFields
          .flatMap((x) => x?.value || [])
          .filter(
            (ouf) =>
              ouf.fieldDefinitionId === f.fieldDefinition.id &&
              !filesToDelete.find((tdf) => tdf.id === ouf.id)
          ),
      ],
    }));

    await Promise.all([
      [...allOtherFields, ...uploadFieldsValueToSave]
        .filter((field) => field.value)
        .map(async (field) => {
          if (!field.id) {
            return await createFieldValue(
              {
                ...fieldValueConnectionIdObj,
                value: JSON.stringify(field.value),
                fieldValueFieldDefinitionId: field.fieldDefinition.id,
              },
              false
            );
          } else {
            return await updateFieldValue(
              {
                id: field.id,
                ...fieldValueConnectionIdObj,
                value: JSON.stringify(field.value),
                fieldValueFieldDefinitionId: field.fieldDefinition.id,
              },
              false
            );
          }
        }),
    ]);
  };

  const createUpdateOrDeleteAffiliations = async (
    abstractDocumentId,
    affiliations
  ) => {
    return await Promise.all([
      ...(affiliations?.map(async ({ id, nanoId, ...affiliation }) => {
        let resultAffiliation = {};
        if (!id) {
          resultAffiliation = await createAbstractAffiliation(
            {
              ...affiliation,
              abstractDocumentAffiliationsId: abstractDocumentId,
            },
            false
          );
        } else {
          resultAffiliation = await updateAbstractAffiliation(
            {
              id,
              ...affiliation,
              abstractDocumentAffiliationsId: abstractDocumentId,
            },
            false
          );
        }
        resultAffiliation['nanoId'] = nanoId;
        return resultAffiliation;
      }) || []),
      ...(abstractDocument.affiliations
        ?.filter((dba) => !affiliations?.find((a) => a.id === dba.id))
        .map(async (affiliation) => {
          return await deleteAbstractAffiliation(affiliation.id, false);
        }) || []),
    ]);
  };

  const updateFiles = async (abstractId, abstractSections) =>
    Promise.all([
      ...abstractSections.map(async (section) => {
        await Promise.all([
          ...(section.addedFiles?.map(async (fileObj) => {
            const matchExtension = fileObj.file.name.match(/\.[0-9a-z]+$/i);
            const extension = matchExtension ? matchExtension[0] : '';

            const input = {
              originalName: fileObj.file.name,
              extension: extension,
              size: Number(fileObj.file.size) || 0,
            };

            const fileId = await fileHelper.createFile({
              dirPath: `events/${eventId}/abstracts/${abstractId}/`,
              fileData: input,
              localFile: fileObj.file,
              useLoader: false,
            });
            /* await aws.s3.putObject({
              key,
              file: fileObj.file,
              isPublic: true,
            }); */
            for (let i = 0; i < section?.value?.blocks?.length || 0; i++) {
              if (
                section?.value?.blocks[i]?.type === 'atomic' &&
                section?.value?.blocks[i]?.data?.src === fileObj.fileUrl
              ) {
                const link = fileHelper.getAwsS3Link(
                  `events/${eventId}/abstracts/${abstractId}/`,
                  {
                    id: fileId,
                    extension,
                  }
                );
                section.value.blocks[i].data.src = aws.s3.getS3ObjectUrl(link);
              }
            }
          }) || []),
          ...(section.deletedFiles?.map(async (fileObj) => {
            const fileId = fileObj.fileUrl.match(/([^/]+)\.[0-9a-z]+$/i);
            await deleteFile(fileId[1], false);
            return await aws.Storage.remove(
              aws.s3.getKeyFromS3ObjectUrl(fileObj.fileUrl)
            );
          }) || []),
        ]);
        return section;
      }),
    ]);

  const uploadFiles = async (
    abstractId,
    submittedFiles,
    abstractDocumentAttachmentsId
  ) => {
    const dirPath = `events/${eventId}/abstracts/${abstractId}/`;

    return await Promise.all(
      submittedFiles
        .flatMap((f) => (f.value || []).map((file) => ({ ...f, value: file })))
        .filter((f) => !f.value.id)
        .map(async (f) => {
          const originalName = f.value?.name || new Date().getTime().toString();

          const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
          const extension = matchExtension ? matchExtension[0] : '';

          const fileSize = f.value?.size;

          const input = {
            originalName: originalName,
            extension: extension,
            size: Number(fileSize) || 0,
            abstractDocumentAttachmentsId: abstractDocumentAttachmentsId
              ? abstractDocumentAttachmentsId
              : undefined,
          };

          const res = await fileHelper.createFile({
            dirPath,
            fileData: input,
            localFile: f.value,
            useLoader: false,
            returnFileObject: true,
          });
          return { fieldDefinitionId: f.fieldDefinitionId, ...res };
        })
    );
  };

  const deleteFiles = async (oldFiles, submittedFiles) => {
    // devo cancellare i files che sono su s3
    const dirPath = `events/${eventId}/abstracts/${abstractId}/`;
    const filesToDelete =
      oldFiles
        .flatMap((x) => x?.value || [])
        ?.filter(
          (f) =>
            !submittedFiles
              .flatMap((x) => x?.value || [])
              .find((i) => i.id === f.id)
        ) || [];
    if (filesToDelete.length > 0) {
      return await Promise.all(
        filesToDelete.map(
          async (f) =>
            await fileHelper.deleteFile({
              dirPath,
              fileData: f,
              useLoader: false,
            })
        )
      );
    }
  };

  const generateAbstractCode = async () => {
    const eventCode = appState.eventInfo.getValue().code;
    const abstractSequence = await utilities.getNextSequenceValue({
      eventCode,
      // eventId,
      sequenceName: 'abstractSequence',
    });
    return `${eventCode}-ABS-${abstractSequence}`;
  };

  const createOrUpdateOrDeleteAbstractAuthors = async (
    abstractId,
    authors,
    affiliations
  ) => {
    await Promise.all([
      ...(authors.map(async ({ affiliation, ...author }, index) => {
        let foundAffiliation = affiliations?.find((a) => a.id === affiliation);
        if (!foundAffiliation) {
          foundAffiliation = affiliations?.find(
            (c) => c.nanoId === affiliation
          );
        }
        // Devo sapere se l'utente loggato è già autore o no
        // in modo da sapere se devo fare una create o un'update dell'autore
        const abstractAuthorId = await getAbstractAuthor(
          appState.user.getValue()?.userAndParticipation?.participation?.id
        );

        // If new author
        if (
          !author.id ||
          (!abstractAuthorId &&
            author.id ===
              appState.user.getValue()?.userAndParticipation?.participation
                ?.id &&
            getValues('isOwnerAPresentingAuthor'))
        ) {
          // rmeove author affiliation
          delete author.affiliation;
          await createAbstractAuthor(
            {
              abstractDocumentAuthorsId: abstractId,
              abstractAuthorAffiliationId: foundAffiliation?.id,
              position: index,
              ...author,
            },
            false
          );
        } else {
          await updateAbstractAuthor(
            {
              ...author,
              abstractAuthorAffiliationId: foundAffiliation?.id,
              abstractDocumentAuthorsId: abstractId,
              position: index,
            },
            false
          );
        }
      }) || []),
      ...(abstractDocument.authors
        ?.filter((dba) => !authors?.find((a) => a.id === dba.id))
        .map(async (author) => {
          if (author.id) return await deleteAbstractAuthor(author.id, false);
        }) || []),
    ]);
  };

  const sendData = async (_values, status, historyLink) => {
    appState.isLoader.next(true);
    const values = {
      ..._values,
      affiliations: _values.affiliations?.map((x) => {
        return {
          ...x,
          city: x.city?.value,
          country: x.country?.value,
        };
      }),
      authors: _values.authors?.map((x) => {
        return {
          ...x,
          city: x.city?.value,
          country: x.country?.value,
        };
      }),
    };

    const {
      title,
      category,
      subCategory,
      preferredExposureId,
      presentationMode,
      tags,
      keywords,
      presenterParticipation,
      authors: rawAuthors,
      isAgreementChecked,
      isOwnerAPresentingAuthor,
    } = values;

    const abstractSections = values.abstractSections.map((s) => ({
      ...s,
      value: Array.isArray(s?.value?.blocks)
        ? s.value
        : s.value
        ? JSON.parse(AimMegadraftToJSON(s.value))
        : undefined,
    }));
    if (!isAgreementChecked && status !== constants.AbstractStatus.DRAFT.id)
      return;

    const input = {
      title,
      abstractDocumentPresenterId: presenterParticipation?.id,
      abstractDocumentParticipantId: participation.id,
      abstractDocumentAbstractServiceId: abstractService.id,
      abstractDocumentCategoryId: category,
      abstractDocumentSubCategoryId: subCategory,
      preferredExposure: presentationMode,
      abstractDocumentProposedPresentationTypologyId: preferredExposureId,
      status,
      tags,
      keywords,
      isOwnerAPresentingAuthor,
    };

    let promise = {};
    if (abstractId) {
      if (!abstractDocument.code) {
        input.code = await generateAbstractCode();
      }
      promise = updateAbstractDocument({ ...input, id: abstractId }, false);
    } else {
      input.code = await generateAbstractCode();
      promise = createAbstractDocument(input, false);
    }
    promise
      .then(async (document) => {
        const nextAbstractSections = await updateFiles(
          document.id,
          abstractSections
        );
        return { document, abstractSections: nextAbstractSections };
      })
      .then(async ({ document, abstractSections }) => {
        await createOrUpdateFieldValues({
          fieldValueConnectionIdObj: {
            abstractDocumentAbstractSectionsId: document.id,
          },
          fields: abstractSections,
          abstractDocumentId: document.id,
          type: 'abstractSections', //si deve chiamare come la proprietà
        });
        return document;
      })
      .then(async (document) => {
        await Promise.all([
          uploadFiles(document.id, [{ value: files }], document.id),
          deleteFiles(
            [{ value: abstractDocument.attachments?.items }],
            [{ value: files }]
          ),
        ]);
        return document;
      })
      .then(async (document) => {
        const affiliations = await createUpdateOrDeleteAffiliations(
          document.id,
          values.affiliations
        );
        return { document, affiliations: affiliations.filter((a) => !!a) };
      })
      .then(async ({ document, affiliations }) => {
        const authors = rawAuthors.map((author) => {
          // If author as nanoId key
          if (Object.keys(author).some((key) => key === 'nanoId')) {
            delete author.nanoId;
          }
          return author;
        });

        await createOrUpdateOrDeleteAbstractAuthors(
          document.id,
          authors,
          affiliations
        );
        return document;
      })
      .then(async (document) => {
        const standardCustomValues = {
          ...values.standardDefaultValues,
          ...values.customDefaultValues,
        };

        const nextData = values.additionalData
          ? mapAdditionalData(standardCustomValues, values.additionalData)
          : mapAdditionalData(
              standardCustomValues,
              abstractService.additionalDataFields.map((additionalData) => ({
                fieldDefinition: additionalData,
              }))
            );

        // nextData deve essere un array di oggetti tipo: { value: string, fieldDefinition: FieldDefinition }
        await createOrUpdateFieldValues({
          fieldValueConnectionIdObj: {
            abstractDocumentAdditionalDataId: document.id,
          },
          fields: nextData,
          abstractDocumentId: document.id,
          type: 'additionalData', //si deve chiamare come la proprietà
        });
        return document;
      })
      .then(
        (document) =>
          appState.isLoader.next(false) ||
          history.push(historyLink || `/events/${eventId}/abstracts`, {
            abstractId: document.id,
          })
      )
      .catch((err) => {
        appState.isLoader.next(false);
        console.error(err);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.page.sendDataError,
        });
      });
  };

  const mapAdditionalData = (values, data) => {
    const nextValues = Object.keys(values).map((key) => ({
      id: key,
      value: values[key],
    }));

    const updatedData = data.map((item) => {
      const match = nextValues.find(
        (valueItem) => valueItem.id === item.fieldDefinition.id
      );
      if (match) {
        return { ...item, value: match.value };
      }
      return item;
    });
    return updatedData;
  };

  // eslint-disable-next-line react/display-name
  const StepperControls = (errors) => ({
    previous,
    next,
    index,
    isLastStep,
  }) => (
    <div
      style={{
        display: 'flex',
        flex: 1,
        gap: 7,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <AimIconAndTextButton
        text={i18n.actions.back}
        variant="greyFill"
        onClick={previous}
        disabled={index === 0}
      >
        <ArrowBack />
      </AimIconAndTextButton>
      <div style={{ flex: 1 }} />
      {isLastStep ? (
        <>
          <AimIconAndTextButton
            text={i18n.actions.saveDraft}
            variant="greyFill"
            onClick={() => {
              sendData(getValues(), constants.AbstractStatus.DRAFT.id);
            }}
          >
            <ArrowForward />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            text={i18n.actions.saveAndExit}
            variant="greenFill"
            type="submit"
            disabled={Object.keys(errors).length}
          >
            <Check />
          </AimIconAndTextButton>
        </>
      ) : (
        <AimIconAndTextButton
          text={i18n.actions.forward}
          variant="greyFill"
          onClick={next}
        >
          <ArrowForward />
        </AimIconAndTextButton>
      )}
    </div>
  );

  const mapToContextsOfUse = (fields, filterKey) => {
    return sortBy(
      fields.items
        .map((field) => {
          const contextsOfUse = field.contextsOfUse.items.find(
            (fieldContext) => fieldContext.contextName === filterKey
          );
          return { ...field, contextsOfUse };
        })
        .filter(
          (field) => field.contextsOfUse && !field.contextsOfUse.isHidden
        ),
      ['contextsOfUse.position']
    );
  };

  const mapToContextsOfUseAbstract = (
    standardFields,
    customFields,
    filterKey
  ) => {
    const fields = [...standardFields.items, ...customFields.items];
    return sortBy(
      fields
        .map((field) => {
          const contextsOfUse = field.contextsOfUse.items.find(
            (fieldContext) => fieldContext.contextName === filterKey
          );
          return { ...field, contextsOfUse };
        })
        .filter((field) => field.contextsOfUse && field.contextsOfUse.isHidden),
      ['contextsOfUse.position']
    );
  };

  const mapToAbstractFields = (abstractFields, abstractServiceFields) => {
    return abstractServiceFields.map(
      (abstractServiceField) =>
        abstractFields.find(
          (abstractField) =>
            abstractField?.fieldDefinition?.id === abstractServiceField.id
        ) || {
          fieldDefinition: { ...abstractServiceField },
        }
    );
  };

  const downloadFile = (file) => {
    const dirPath = `events/${eventId}/abstracts/${abstractId}/`;
    if (file.id)
      fileHelper.downloadFromS3({
        dirPath,
        fileData: file,
        useLoader: false,
      });
    else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      link.download = file.name;
      link.click();
      link.remove();
    }
  };

  const affiliations = watch('affiliations');
  const formValues = getValues();

  const category = abstractService?.categories?.items?.find(
    (i) => i.id === formValues.category
  );
  const subCategory = category?.subCategories?.items?.find(
    (i) => i.id === formValues.subCategory
  );

  const handleConfim = async () => {
    if (grants.length) {
      setGrantDialog({
        values: confirmDialog.values,
        isOpen: true,
        status: constants.AbstractStatus.PUBLISHED.id,
      });
    } else {
      sendData(
        confirmDialog.values,
        constants.AbstractStatus.PUBLISHED.id,
        getServiceRedirectLinkEvent('SendAbstract')
      );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        maxHeight: 'calc(100vh - 210px)',
        overflow: 'auto',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          maxWidth: '80vw',
          minWidth: '80vw',
        }}
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tooltip title={i18n.page.backButton.tooltip}>
              <AimIconAndTextButton
                isUpperCase
                variant="none"
                style={{
                  padding: 0,
                }}
                onClick={() => history.push(`/events/${eventId}/abstracts`)}
                text={i18n.page.backText.backText}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
            <Tooltip title={i18n.actions.preview}>
              <AimIconAndTextButton
                isUpperCase
                variant="secondary"
                text={i18n.actions.preview}
                style={{ padding: 0 }}
                onClick={() => setShowPreview(true)}
              >
                {/* <ArrowForward /> */}
              </AimIconAndTextButton>
            </Tooltip>
          </div>
          <Typography
            variant="body1"
            component="h1"
            style={{ fontSize: '1.3125rem', fontWeight: '700' }}
          >
            {i18n.page.title}
          </Typography>
        </div>
        <div style={{ flex: 1 }}>
          <AimTitleAndButtons
            title={intl.formatMessage({
              description: 'send abstract title',
              defaultMessage: 'Send abstract',
            })}
          />
          <form
            onSubmit={handleSubmit(
              (values) => {
                setConfirmDialog({
                  isOpen: true,
                  values,
                });
              },
              (submitErrors) => {
                console.error('submit errors', submitErrors);
                setSnackbar({
                  isOpen: true,
                  autoHide: false,
                  severity: AimSnackbarSeverity.error,
                  message: Object.values(
                    submitErrors
                  ).map(({ message, index }) => (
                    <div key={index}>{message}</div>
                  )),
                });
              }
              // setGrantDialog({
              //   isOpen: true,
              //   values,
              //   status: constants.AbstractStatus.PUBLISHED.id,
              // })
            )}
          >
            {titles?.length > 0 && (
              <AimStepper
                titlesObject={titles}
                stepperControls={StepperControls(errors)}
                outsideStepperControls
                //fixedHeight
                slideStyle={{
                  maxHeight: 'calc(100vh - 210px)',
                  overflowY: 'auto',
                }}
                variant="grey"
              >
                <InstructionsStep
                  {...{
                    i18n,
                    title: titles[0].title,
                    control,
                    variant: 'white',
                    errors,
                    instructionsElement,
                    abstractService,
                    watch,
                    setValue,
                  }}
                />
                {/* <PresentationStep
                  {...{
                    variant: 'white',
                    control,
                    title: titles[1].title,
                    i18n,
                    errors,
                    abstractService,
                    getValues,
                    setValue,
                  }}
                /> */}
                <TopicStep
                  {...{
                    variant: 'white',
                    control,
                    title: titles[1].title,
                    i18n,
                    errors,
                    abstractService,
                    watch,
                    setValue,
                  }}
                />
                <AffiliationsStep
                  control={control}
                  register={register}
                  title={titles[2].title}
                  i18n={i18n}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  getValues={getValues}
                  watch={watch}
                />
                <AuthorsAndPresenterStep
                  abstractService={abstractService}
                  control={control}
                  register={register}
                  title={titles[3].title}
                  i18n={i18n}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  affiliations={affiliations}
                  participation={participation}
                  clearErrors={clearErrors}
                  titles={preTitles}
                  fieldArrayName={'authors'}
                  registryValues={registryValues}
                />
                <AdditionalData
                  title={titles[4].title}
                  control={control}
                  reset={reset}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                  clearErrors={clearErrors}
                  standardFields={standardFields}
                  setStandardFields={setStandardFields}
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                  abstractDocument={abstractDocument}
                />
                <ContentStep
                  variant="white"
                  control={control}
                  title={titles[5].title}
                  i18n={i18n}
                  errors={errors}
                  getValues={getValues}
                  setValue={setValue}
                  abstractService={abstractService}
                  abstractId={abstractId}
                  files={files}
                  setFiles={setFiles}
                  s3Folder={`events/${eventId}/abstracts/${abstractId}`}
                  watch={watch}
                />
                <AgreementStep
                  {...{
                    control,
                    title: titles[6].title,
                    i18n,
                    errors,
                    abstractService,
                  }}
                />
              </AimStepper>
            )}
          </form>
          <CustomSnackbar
            open={snackbar.isOpen}
            onClose={() => setSnackbar({ isOpen: false })}
            severity={snackbar.severity}
            autoHide={snackbar.autoHide}
          >
            {snackbar.message}
          </CustomSnackbar>
        </div>
        <AimDialog
          maxWidth={1300}
          open={showPreview}
          onClose={() => setShowPreview(false)}
          hideAgreeButton
          hideDivider
        >
          <DialogContent>
            <AbstractDetailPage
              intl={intl}
              abstractDocument={{
                ...formValues,
                category,
                subCategory,
                abstractSections: {
                  items: formValues.abstractSections?.map((as) => ({
                    ...as,
                    fieldDefinition: {
                      ...as.fieldDefinition,
                      contextsOfUse: {
                        items: [{ position: 0 }],
                      },
                    },
                    value: Array.isArray(as.value?.blocks)
                      ? JSON.stringify(as.value)
                      : AimMegadraftToJSON(as.value),
                  })),
                },
                authors: formValues.authors ? [...formValues.authors] : [],
                attachments: {
                  items: files,
                },
              }}
              hideReviewsFromPage
              printWithoutReviewsFrontOffice
              onDownloadAttachments={downloadFile}
              maxHeight={'100%'}
              preTitles={preTitles}
              affiliations={affiliations}
            />
          </DialogContent>
        </AimDialog>
        <AimDialog
          title={i18n.dialogs.grantDialog.title}
          open={grantDialog.isOpen}
          onClose={() => setGrantDialog({ isOpen: false })}
          onAgree={async () => {
            await sendData(
              grantDialog.values,
              grantDialog.status,
              `/events/${eventId}/grants/abstract`
            );
          }}
          onDisagree={() =>
            sendData(
              grantDialog.values,
              grantDialog.status,
              getServiceRedirectLinkEvent('SendAbstract')
            )
          }
          agreeText={i18n.dialogs.grantDialog.yesButton}
          disagreeText={i18n.dialogs.grantDialog.noButton}
          maxWidth="lg"
        >
          <AimTypography variant="text">
            {i18n.dialogs.grantDialog.description}
          </AimTypography>
        </AimDialog>
        <AimDialog
          title={i18n.dialogs.confirmDialog.title}
          open={confirmDialog.isOpen}
          onClose={() => setConfirmDialog({ isOpen: false })}
          onAgree={handleConfim}
          onDisagree={() => setConfirmDialog({ isOpen: false })}
          agreeText={i18n.dialogs.confirmDialog.submitButton}
          disagreeText={i18n.dialogs.confirmDialog.cancelButton}
        >
          {i18n.dialogs.confirmDialog.message}
          <AimTypography variant="text" boxStyle={{ margin: '10px 0px' }}>
            {abstractService?.assesment}
          </AimTypography>
        </AimDialog>
      </div>
    </div>
  );
};

export default PaxAbstractsEdit;
