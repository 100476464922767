import { aws, appState, constants, utilities } from '@aim/common';
import { CustomIntl } from '@aim/components';
import { useBreakoutRoom } from './breakoutRoomGqlHelper';
import { addMinutes, isAfter } from 'date-fns';
import { useDataHelper } from './dataHelper';
import { useIntl } from 'react-intl';
import useI18n from './translation';
const { API } = aws;
const {
  safeNum,
  encodeDbNumber,
  decodeDbNumber,
  roundTo2Decimal,
  vatCalc,
  isValidPurchase,
} = utilities;

import { customAlphabet } from 'nanoid';
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 8); // 1 id/hour --> 1% probability of collision in 27 years
const breakoutRoomHelper = useBreakoutRoom();

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const createBuyOperationMutation = /* GraphQL */ `
  mutation CreateBuyOperation(
    $input: CreateBuyOperationInput!
    $condition: ModelBuyOperationConditionInput
  ) {
    createBuyOperation(input: $input, condition: $condition) {
      id
      bookingId
      buyOperationSponsorId
    }
  }
`;

const deleteBuyOperationMutation = /* GraphQL */ `
  mutation DeleteBuyOperation(
    $input: DeleteBuyOperationInput!
    $condition: ModelBuyOperationConditionInput
  ) {
    deleteBuyOperation(input: $input, condition: $condition) {
      id
      buyOperationSponsorId
    }
  }
`;

const updateBuyOperationMutation = /* GraphQL */ `
  mutation UpdateBuyOperation(
    $input: UpdateBuyOperationInput!
    $condition: ModelBuyOperationConditionInput
  ) {
    updateBuyOperation(input: $input, condition: $condition) {
      id
      buyOperationSponsorId
    }
  }
`;

const listBuyOperationQuery = /* GraphQL */ `
  query GetBuyOperations($eventId: ID!, $sponsorId: ID!, $serviceKey: String!) {
    getEvent(id: $eventId) {
      IBAN
      symposiumService {
        vatRate
        vat {
          id
        }
      }
      bannerService {
        vatRate
        vat {
          id
        }
      }
      sponsorStaffService {
        vatRate
        vat {
          id
        }
      }
      sponsorListService {
        vatRate
        vat {
          id
        }
      }
      gateways(filter: { service: { eq: $serviceKey } }) {
        items {
          id
          service
          shop {
            id
            paymentType
            gatewayType
            iban
            ibanCausal
            addressedTo
          }
        }
      }
    }
    getSponsor(id: $sponsorId) {
      buyOperations(
        filter: { isDeleted: { eq: "false" }, isExpired: { eq: "false" } }
        limit: 600
      ) {
        items {
          id
          bookingId
          paymentType
          expiringDate
          date
          isExpired
          isDeleted
          symposium {
            id
            price
            agendaSession {
              name
            }
          }
          bannerArea {
            id
            name
            price
          }
          publicPage {
            id
            publicPageService {
              id
              netPrice
              vatRate
              vat {
                id
              }
            }
          }
          breakoutRoom {
            id
            breakoutRoomService {
              id
              price
              vatRate
              vat {
                id
              }
            }
          }
          physicalStandSpace {
            id
            name
            noVat
            extraPriceDisabled
            netPrice
            floor {
              physicalStandService {
                id
                vatRate
                vat {
                  id
                }
                extraPriceDate
                extraPriceRate
              }
            }
          }
          physicalStandSpaceItems {
            id
            amountPerQuantity
            quantity
            item {
              id
              name
              netPrice
              vatRate
              vat {
                id
              }
            }
          }
          sponsorVirtualStand {
            id
            virtualStand {
              id
              name
              netPrice
              virtualStandService {
                vatRate
                vat {
                  id
                }
              }
            }
          }
          sponsorStaffPurchase {
            id
            quantity
            netPrice
            participationMode
            feeDateRange {
              id
              label
            }
            sponsorStaff {
              id
            }
          }
          sponsorListPurchase {
            id
            quantity
            netPrice
            type
            vat {
              id
              amount
            }
            netPrice1
            vat1 {
              id
              amount
            }
            netPrice2
            vat2 {
              id
              amount
            }
            participationMode
            sponsorList {
              id
            }
            profile {
              id
              name
            }
            feeDateRange {
              id
              label
              start
              end
            }
            additionalService {
              id
              title
              participationType
            }
          }
          symposiumServicesPurchase {
            quantity
            id
            symposiumServicesItem {
              id
              name
              price
              quantity
              symposiumServicesService {
                id
                vatRate
                vat {
                  id
                }
              }
            }
          }
          otherSponsorizationsBookingItem {
            item {
              id
              name
              netPrice
              vatRate
              vat {
                id
              }
            }
            quantity
            amountPerQuantity
          }
          sponsorPackage {
            id
            name
            quantity
            services {
              items {
                id
                netPrice
                vatRate
                vat {
                  id
                }
                quantity
                otherSponsorizationsBookingItem {
                  id
                  item {
                    id
                    type
                    cost
                  }
                }
                physicalStandSpaceItem {
                  id
                  item {
                    id
                    type
                    cost
                  }
                }
                physicalStand {
                  id
                }
                publicPageService {
                  id
                }
                symposium {
                  id
                }
                symposiumServicesPurchase {
                  id
                  quantity
                  symposiumServicesItem {
                    id
                  }
                }
                virtualStand {
                  id
                }
                breakoutRoomService {
                  id
                }
                bannerArea {
                  id
                }
              }
            }
          }
          purchase {
            id
            createdAt
            failedPurchaseBuyOperationsData
            paymentType
            payment {
              paymentStatus
              id
              paymentId
              paymentIdMonetaOnLine
              paymentIdIngenico
              paymentIdGpWebpay
              paymentError
              date
              stepName
              stepNote
              paymentType
              amount
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;

const getBuyOperationQuery = /* GraphQL */ `
  query GetBuyOperation($id: ID!) {
    getBuyOperation(id: $id) {
      id
      purchase {
        id
      }
      symposium {
        id
        sponsor {
          id
        }
      }
      bannerArea {
        id
        sponsorAreas {
          items {
            id
            sponsor {
              id
            }
          }
        }
      }
      publicPage {
        id
      }
      breakoutRoom {
        id
      }
      physicalStandSpace {
        id
      }
      physicalStandSpaceItems {
        id
      }
      sponsorVirtualStand {
        id
      }
      sponsorStaffPurchase {
        id
      }
      sponsorListPurchase {
        id
      }
    }
  }
`;

const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      end
      id
    }
  }
`;

const getEventGatewayByServiceQuery = /* GraphQL */ `
  query GetEvent($id: ID!, $serviceId: String) {
    getEvent(id: $id) {
      id

      gateways(filter: { service: { eq: $serviceId } }) {
        items {
          id
          service
          shop {
            id
            paymentType
            gatewayType
            iban
          }
        }
      }
    }
  }
`;

const listSurchargesQuery = /* GraphQL */ `
  query ListSurcharges($eventId: String) {
    SurchargesByEventId(eventId: $eventId) {
      items {
        createdAt
        date
        eventId
        id
        percentage
        previousPrices
        updatedAt
      }
    }
  }
`;

const updateSymposiumServicesPurchaseMutation = /* GraphQL */ `
  mutation UpdateSymposiumServicesPurchase(
    $input: UpdateSymposiumServicesPurchaseInput!
    $condition: ModelSymposiumServicesPurchaseConditionInput
  ) {
    updateSymposiumServicesPurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

const onBuyOperationCRUDBySponsorId = /* GraphQL */ `
  subscription OnCreateUpdateDeleteBuyOperationBySponsorId(
    $buyOperationSponsorId: ID!
  ) {
    onCreateUpdateDeleteBuyOperationBySponsorId(
      buyOperationSponsorId: $buyOperationSponsorId
    ) {
      id
      buyOperationSponsorId
    }
  }
`;

export const useBuyOperations = () => {
  const { physicalStandSpacePriceCalc } = useDataHelper();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.sponsorCart(intl);

  const get = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getBuyOperationQuery,
        variables: { id },
      })
        .then((response) => resolve(response.data.getBuyOperation))
        .catch((e) => {
          console.error('get-buy-operation', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const list = (eventId, sponsorId, serviceKey, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: listBuyOperationQuery,
        variables: {
          eventId,
          sponsorId,
          serviceKey,
        },
      })
        .then((response) => {
          const nextResponse = {
            // IBAN: response.data.getEvent.IBAN,

            gateway: response.data.getEvent.gateways?.items[0] || '',
            // IBAN: response.data.getEvent.gateways?.items[0]?.shop?.iban || '',
            buyOperations: response.data.getSponsor.buyOperations.items.map(
              (bo) => {
                const injectedData = {};
                if (bo.symposium) {
                  injectedData.symposium = {
                    ...bo.symposium,
                    vatRate: response.data.getEvent.symposiumService.vatRate,
                    vat: response.data.getEvent.symposiumService?.vat,
                  };
                } else if (bo.bannerArea) {
                  injectedData.bannerArea = {
                    ...bo.bannerArea,
                    vatRate: response.data.getEvent.bannerService.vatRate,
                    vat: response.data.getEvent.bannerService?.vat,
                  };
                } else if (bo.sponsorStaffPurchase) {
                  injectedData.sponsorStaffPurchase = {
                    ...bo.sponsorStaffPurchase,
                    vatRate: response.data.getEvent.sponsorStaffService.vatRate,
                    vat: response.data.getEvent.sponsorStaffService?.vat,
                  };
                } else if (bo.sponsorListPurchase) {
                  injectedData.sponsorListPurchase = {
                    ...bo.sponsorListPurchase,
                    participationMode:
                      bo.sponsorListPurchase.participationMode ||
                      bo.sponsorListPurchase?.additionalService
                        ?.participationType,
                    vatRate: response.data.getEvent.sponsorListService.vatRate,
                    vat: response.data.getEvent.sponsorListService?.vat,
                  };
                }

                return { ...bo, ...injectedData };
              }
            ),
          };
          resolve(nextResponse);
        })
        .catch((e) => {
          console.error('list-buy-operation', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createBuyOperationMutation,
        variables: { input: { ...input, bookingId: nanoid() } },
      })
        .then((response) => resolve(response.data.createBuyOperation))
        .catch((e) => {
          console.error('create-buy-operation', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateBuyOperationMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.updateBuyOperation))
        .catch((e) => {
          console.error('update-buy-operation', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const remove = (input, useLoader = true) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      useLoader && showLoader();

      const bo = await get(input.id, false);
      if (bo?.breakoutRoom?.id) {
        await breakoutRoomHelper.remove(bo.breakoutRoom?.id);
      }

      API.graphql({
        query: deleteBuyOperationMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.deleteBuyOperation))
        .catch((e) => {
          console.error('delete-buy-operation', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const getEndDateOfEvent = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getEvent,
        variables: { id },
      })
        .then((response) => resolve(response.data.getEvent))
        .catch((e) => {
          console.error('get-end date of event-operation', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const getEventGatewayByService = (input) =>
    new Promise((resolve, reject) => {
      showLoader();
      aws.API.graphql({
        query: getEventGatewayByServiceQuery,
        variables: { ...input },
      })
        .then((response) => {
          const { data } = response;
          resolve(data.getEvent.gateways.items[0]);
        })
        .catch((e) => {
          console.error('get-event-gateway-service', e);
          reject();
        })
        .finally(hideLoader);
    });

  const updateSymposiumServicesPurchase = (input) =>
    new Promise((resolve, reject) => {
      showLoader();
      API.graphql({
        query: updateSymposiumServicesPurchaseMutation,
        variables: { input },
      })
        .then((response) => {
          resolve(response.data.updateSymposiumServicesPurchase);
        })
        .catch((e) => {
          console.error('update-symposium-services-purchase', e);
          reject();
        })
        .finally(hideLoader);
    });

  const onChangeBySponsorId = (sponsorId, callback) => {
    // N.B.: all involved mutations should include "buyOperationSponsorId" in response body for this to work
    // if sponsorId is null the client get subscribed to all changes without the filter
    // every field needed in the subscription response should be included in the mutation response
    try {
      return aws.standardAPI
        .graphql({
          query: onBuyOperationCRUDBySponsorId,
          variables: { buyOperationSponsorId: sponsorId },
        })
        .subscribe({
          next: ({ value }) => {
            console.log('onChangeBySponsorId value ', value);
            return callback(
              value.data.onCreateUpdateDeleteBuyOperationBySponsorId
            );
          },
          error: (e) => {
            console.error('on-change-buyoperation', e);
          },
        });
    } catch (e) {
      console.error('on-change-buyoperation', e);
    }
  };

  const bookingIsExpired = (date) => {
    const isExpired = isAfter(new Date(), addMinutes(new Date(date), 22));
    return isExpired;
  };

  const normalizeBookings = (bookings) =>
    bookings.map((b) => {
      let service;
      let gatewayService;
      if (b.symposium) {
        service = i18n.services.symposium;
      } else if (b.bannerArea) {
        service = i18n.services.banner;
      } else if (b.publicPage) {
        service = i18n.services.publicPage;
      } else if (b.breakoutRoom) {
        service = i18n.services.breakoutRoom;
      } else if (b.physicalStandSpace) {
        service = i18n.services.physicalStand;
      } else if (b.physicalStandSpaceItems) {
        service = i18n.services.otherStandServices;
      } else if (b.sponsorVirtualStand) {
        service = i18n.services.virtualStand;
      } else if (b.sponsorStaffPurchase) {
        service = i18n.services.sponsorStaff;
      } else if (b.sponsorListPurchase) {
        service = i18n.services.sponsorList;
      } else if (b.symposiumServicesPurchase) {
        service = i18n.services.symposiumServices;
      } else if (b.sponsorPackage) {
        service = i18n.services.sponsorPackages;
      } else if (b.otherSponsorizationsBookingItem) {
        service = i18n.services.otherSponsorizations;
      }

      let instance;
      if (b.bannerArea !== null) {
        instance = b?.bannerArea?.name;
      } else if (b.symposium !== null) {
        instance = b?.symposium?.agendaSession?.name;
      } else if (b.publicPage !== null) {
        instance = '-';
      } else if (b.breakoutRoom !== null) {
        instance = '-';
      } else if (b.physicalStandSpace) {
        instance = b?.physicalStandSpace?.name;
      } else if (b.physicalStandSpaceItems) {
        instance = b?.physicalStandSpaceItems?.item?.name;
      } else if (b.sponsorVirtualStand) {
        instance = b?.sponsorVirtualStand?.virtualStand?.name;
      } else if (b.sponsorStaffPurchase !== null) {
        instance = b?.sponsorStaffPurchase?.feeDateRange.label || '-';
      } else if (b.sponsorListPurchase !== null) {
        const type = b.sponsorListPurchase.type;

        if (type === constants.ProductTypes.REGISTRATION.key) {
          const ticketLabel = utilities.generateTicketLabel({
            feeDateRange: b.sponsorListPurchase.feeDateRange,
            profile: b.sponsorListPurchase.profile,
            participationMode: b.sponsorListPurchase.participationMode,
            intl,
          });
          instance = `${constants.ProductTypes.REGISTRATION.label(
            intl
          )}: ${ticketLabel}`;
        } else if (type === constants.ProductTypes.SCIENTIFIC_EVENT.key) {
          instance = `${constants.ProductTypes.SCIENTIFIC_EVENT.label(intl)}: ${
            b.sponsorListPurchase.additionalService.title
          }`;
        } else if (type === constants.ProductTypes.SOCIAL_EVENT.key) {
          instance = `${constants.ProductTypes.SOCIAL_EVENT.label(intl)}: ${
            b.sponsorListPurchase.additionalService.title
          }`;
        } else if (type === constants.ProductTypes.PENALTIES.key) {
          instance = constants.ProductTypes.PENALTIES.label(intl);
        } else {
          instance = '-';
        }
      } else if (b.symposiumServicesPurchase !== null) {
        instance = b.symposiumServicesPurchase.symposiumServicesItem.name;
      } else if (b.sponsorPackage !== null) {
        instance = b.sponsorPackage.name;
      } else if (b.otherSponsorizationsBookingItem) {
        instance = b.otherSponsorizationsBookingItem.item.name;
      }

      let netPrice;
      let vatRate;
      let vatId;
      let quantity;
      let totalVat;
      if (b.symposium) {
        netPrice = decodeDbNumber(b.symposium.price);
        vatRate = decodeDbNumber(b.symposium.vatRate);
        vatId = b.symposium.vat?.id;
        quantity = 1;
      } else if (b.bannerArea) {
        netPrice = decodeDbNumber(b.bannerArea.price);
        vatRate = decodeDbNumber(b.bannerArea.vatRate);
        vatId = b.bannerArea.vat?.id;
        quantity = 1;
      } else if (b.publicPage) {
        netPrice = decodeDbNumber(b.publicPage.publicPageService.netPrice);
        vatRate = decodeDbNumber(b.publicPage.publicPageService.vatRate);
        vatId = b.publicPage.publicPageService.vat?.id;
        quantity = 1;
      } else if (b.breakoutRoom) {
        netPrice = decodeDbNumber(b.breakoutRoom.breakoutRoomService.price);
        vatRate = decodeDbNumber(b.breakoutRoom.breakoutRoomService.vatRate);
        vatId = b.breakoutRoom.breakoutRoomService.vat?.id;
        quantity = 1;
      } else if (b.physicalStandSpace) {
        const priceData = physicalStandSpacePriceCalc({
          netPrice: decodeDbNumber(b.physicalStandSpace.netPrice),
          vatRate: decodeDbNumber(
            b.physicalStandSpace?.floor?.physicalStandService?.vatRate
          ),
          noVat: b.physicalStandSpace.noVat,
          extraPriceDisabled: b.physicalStandSpace.extraPriceDisabled,
          extraPriceRate: decodeDbNumber(
            b.physicalStandSpace?.floor?.physicalStandService?.extraPriceRate
          ),
          extraPriceDate:
            b.physicalStandSpace?.floor?.physicalStandService?.extraPriceDate,
        });
        netPrice = priceData.applyExtraPrice
          ? priceData.extraNetPrice
          : priceData.netPrice;
        vatRate = priceData.vatRate;
        vatId = b.physicalStandSpace?.floor?.physicalStandService.vat?.id;
        quantity = 1;
      } else if (b.physicalStandSpaceItems) {
        netPrice = decodeDbNumber(b.physicalStandSpaceItems.item.netPrice);
        vatRate = decodeDbNumber(b.physicalStandSpaceItems.item.vatRate);
        vatId = b.physicalStandSpaceItems.item.vat?.id;
        quantity =
          (b.physicalStandSpaceItems.quantity || 1) *
          (b.physicalStandSpaceItems.amountPerQuantity || 1);
      } else if (b.otherSponsorizationsBookingItem) {
        netPrice = decodeDbNumber(
          b.otherSponsorizationsBookingItem.item.netPrice
        );
        vatRate = decodeDbNumber(
          b.otherSponsorizationsBookingItem.item.vatRate
        );
        vatId = b.otherSponsorizationsBookingItem.item.vat?.id;
        quantity =
          (b.otherSponsorizationsBookingItem.quantity || 1) *
          (b.otherSponsorizationsBookingItem.amountPerQuantity || 1);
      } else if (b.sponsorVirtualStand) {
        netPrice = decodeDbNumber(b.sponsorVirtualStand.virtualStand.netPrice);
        vatRate = decodeDbNumber(
          b.sponsorVirtualStand.virtualStand.virtualStandService.vatRate
        );
        vatId = b.sponsorVirtualStand.virtualStand.virtualStandService.vat?.id;
        quantity = 1;
      } else if (b.sponsorStaffPurchase) {
        netPrice = decodeDbNumber(b.sponsorStaffPurchase?.netPrice);
        vatRate = decodeDbNumber(b.sponsorStaffPurchase.vatRate);
        vatId = b.sponsorStaffPurchase.vat?.id;
        quantity = b.sponsorStaffPurchase.quantity;
      } else if (b.sponsorListPurchase) {
        netPrice = decodeDbNumber(b.sponsorListPurchase?.netPrice);
        vatRate = decodeDbNumber(b.sponsorListPurchase.vatRate);
        vatId = b.sponsorListPurchase.vat?.id;
        quantity = b.sponsorListPurchase.quantity;
      } else if (b.symposiumServicesPurchase) {
        netPrice = decodeDbNumber(
          b.symposiumServicesPurchase?.symposiumServicesItem?.price
        );
        vatRate = decodeDbNumber(
          b.symposiumServicesPurchase?.symposiumServicesItem
            ?.symposiumServicesService?.vatRate
        );
        vatId =
          b.symposiumServicesPurchase?.symposiumServicesItem
            ?.symposiumServicesService?.vat?.id;
        quantity = b.symposiumServicesPurchase.quantity;
      } else if (b.sponsorPackage) {
        netPrice = safeNum(
          b.sponsorPackage.services.items.reduce(
            (prev, curr) => prev + safeNum(decodeDbNumber(curr.netPrice)),
            0
          )
        );
        const totalPrice = safeNum(
          b.sponsorPackage.services.items.reduce(
            (prev, curr) =>
              prev +
              vatCalc(
                decodeDbNumber(curr.netPrice),
                decodeDbNumber(curr.vatRate)
              ),
            0
          )
        );
        console.log('totalPrice,netPrice', totalPrice, netPrice);
        totalVat = safeNum(roundTo2Decimal(totalPrice - netPrice));

        console.log('totalVat', totalVat);
        quantity = 1;
      }

      const vatAmount =
        totalVat ||
        //to prevent null vat cases for sponsor packages
        (vatRate &&
          roundTo2Decimal(vatCalc(netPrice, safeNum(vatRate)) - netPrice)) ||
        0;
      const price = roundTo2Decimal(netPrice + vatAmount);

      return {
        id: b.id,
        bookingId: b.bookingId,
        service: service,
        gatewayService: gatewayService,
        instance: instance,
        date: b.date,
        expiringDate: b.expiringDate,
        quantity: quantity,
        price: price,
        netPrice: netPrice,
        vatRate: vatRate,
        vatId,
        totalVat: totalVat,
        vatAmount: vatAmount,
        paymentType: b.paymentType,
        symposium: b.symposium,
        bannerArea: b.bannerArea,
        publicPage: b.publicPage,
        breakoutRoom: b.breakoutRoom,
        physicalStandSpace: b.physicalStandSpace,
        physicalStandSpaceItems: b.physicalStandSpaceItems,
        sponsorVirtualStand: b.sponsorVirtualStand,
        sponsorStaffPurchase: b.sponsorStaffPurchase,
        sponsorListPurchase: b.sponsorListPurchase,
        symposiumServicesPurchase: b.symposiumServicesPurchase,
        sponsorPackage: b.sponsorPackage,
        otherSponsorizationsBookingItem: b.otherSponsorizationsBookingItem,
      };
    });

  const refreshSponsorCart = async (eventId, sponsorId) => {
    let gateway;
    let buyOperations;

    const response = await list(
      eventId,
      sponsorId,
      constants.GatewayServices().SPONSOR.key
    );
    gateway = response.gateway;
    buyOperations = response.buyOperations;

    // check buyoperations to detach from purchase
    const promises = [];
    buyOperations.map((buyOperation) => {
      if (
        buyOperation.purchase &&
        !isValidPurchase(buyOperation.purchase) &&
        buyOperation.purchase?.payment?.paymentType !==
          constants.PaymentTypes.BankTransfer &&
        bookingIsExpired(buyOperation.purchase?.createdAt)
      ) {
        // detach purchase
        const input = {
          id: buyOperation.id,
          buyOperationPurchaseId: null,
          vatId: null,
        };

        // get buy operations of failed purchase from failedPurchaseBuyOperationsData
        const buyOperationsOfFailedPurchase = buyOperation.purchase
          .failedPurchaseBuyOperationsData
          ? JSON.parse(buyOperation.purchase.failedPurchaseBuyOperationsData)
          : undefined;
        // find failed buy operation to restore original vat rate
        const failedBuyOperation = buyOperationsOfFailedPurchase?.find(
          (failedBuyOp) => failedBuyOp.id === buyOperation.id
        );
        if (failedBuyOperation) {
          input.unitaryVatRate = encodeDbNumber(failedBuyOperation.vatRate);
        }
        promises.push(update(input));
      }
    });
    const responseUpdateBuyOperations = await Promise.all(promises);
    // if buyoperations detached, reload cart
    if (responseUpdateBuyOperations.length > 0) {
      const response = await list(
        eventId,
        sponsorId,
        constants.GatewayServices().SPONSOR.key
      );
      gateway = response.gateway;
      buyOperations = response.buyOperations;
    }

    const filteredBookings = buyOperations.filter((i) => !i.purchase);
    const bookings = normalizeBookings(filteredBookings);

    appState.cart.next(bookings);
    appState.cartGateway.next(gateway);
  };

  const listSurcharges = (eventId, isLoader = true) =>
    new Promise((resolve, reject) => {
      isLoader && showLoader();

      aws.API.graphql({
        query: listSurchargesQuery,
        variables: { eventId },
      })
        .then((response) => {
          return resolve(response.data.SurchargesByEventId.items);
        })
        .catch((e) => {
          console.error('list-surcharges', e);
          reject();
        })
        .finally(() => isLoader && hideLoader());
    });

  return {
    list,
    create,
    update,
    remove,
    getEndDateOfEvent,
    getEventGatewayByService,
    updateSymposiumServicesPurchase,
    onChangeBySponsorId,
    refreshSponsorCart,
    listSurcharges,
  };
};
