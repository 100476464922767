import React, { useState, useEffect } from 'react';

import { styled } from './../styled';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { theme } from './../../theme';
import { appState, constants } from '@aim/common';
import { AimTypography } from './AimTypography';
import { sortBy } from 'lodash';

// export const AimSelectMenuItem = styled(MenuItem)({
//   display: 'flex !important',
//   flexDirection: 'column !important',
//   padding: '5px 10px !important',
//   alignItems: 'flex-start !important',
//   '&.MuiListItem-root.Mui-selected': {
//     backgroundColor: theme.colors.primary.yellow,
//   },
// });

export const AimSelectMenuItem = styled(({ paddingLeft, ...other }) => (
  <MenuItem {...other} style={{ paddingLeft }} />
))({
  display: 'flex !important',
  flexDirection: 'column !important',
  padding: '5px 10px',
  alignItems: 'flex-start !important',
  '&.MuiListItem-root.Mui-selected': {
    backgroundColor: theme.colors.primary.yellow,
  },
});

// eslint-disable-next-line unused-imports/no-unused-vars
const CustomFormControl = styled(({ formControlWidth, m, p, ...other }) => (
  <FormControl {...other} />
))({
  margin: (props) => theme.spacing(props.m || 0),
  padding: (props) => (props.p ? theme.spacing(props.p) : undefined),
  minWidth: 120,
  flex: 1,
  width: (props) => props.formControlWidth,
});

const CustomFormLabel = styled(FormLabel)(({ variantTemplate }) => ({
  marginBottom: 5,
  paddingBottom: 0,
  color: 'black',
  fontWeight: 'bold',
  fontSize: 14,
  fontFamily:
    variantTemplate === constants.Templates.FRONTEND ? 'hind' : 'Roboto',
  '&.MuiFormLabel-root.Mui-focused': {
    color: 'black',
  },
}));

const CustomSelect = styled(Select)(({ selectvariant }) => {
  return {
    minWidth: '120px',
    minHeight: 48, //40 + 8 to fit as textfields
    marginTop: '0px !important',

    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 4,
    ...selectvariant,
  };
});

const formControlLabelPlacementTop = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const formLabelLabelPlacementTop = {
  marginBottom: 0,
  paddingRight: 8,
};

const variantsBACKEND = {
  darkGrey: {
    backgroundColor: '#eceaf1',
  },
  grey: {
    backgroundColor: theme.colors.greyScale.backgroundGrey,
  },
  white: {
    backgroundColor: theme.colors.primary.white,
  },
};

const variantsFRONTEND = {
  primary: {
    backgroundColor: theme.colors.greyScale.backgroundGrey,
    borderRadius: '5px',
  },
  secondary: {
    backgroundColor: theme.colors.primary.white,
    borderRadius: '5px',
  },
};

const variants = {
  [constants.Templates.BACKEND]: variantsBACKEND,
  [constants.Templates.FRONTEND]: variantsFRONTEND,
};

const variantTemplate = appState.template.getValue();

// TODO delete when restyle is completed
const variantMap = {
  darkGrey: 'primary',
  grey: 'primary',
  white: 'secondary',
};

const getVariant = ({ variant }) => {
  if (variantTemplate === constants.Templates.BACKEND) {
    return variantsBACKEND[variant];
  } else {
    const template = variants[variantTemplate];
    return template[variant] || template[variantMap[variant]];
  }
};

const NonEmptySelect = ({ selectVariant, children, ...rest }) => (
  <CustomSelect
    disableUnderline
    variant="standard"
    selectvariant={getVariant({ variant: selectVariant })}
    {...rest}
  >
    {children}
  </CustomSelect>
);

const EmptySelect = ({
  selectVariant,
  children,
  selectPlaceholder,
  ...rest
}) => (
  <CustomSelect
    displayEmpty
    renderValue={(value) => (
      <Typography
        style={{ opacity: value && value?.length !== 0 ? 'inherit' : '0.5' }}
      >
        {selectPlaceholder}
      </Typography>
    )}
    disableUnderline
    variant="standard"
    selectvariant={getVariant({ variant: selectVariant })}
    {...rest}
    inputProps={{
      style: {
        marginTop: 8,
        marginBottom: 8,
      },
    }}
  >
    {children}
  </CustomSelect>
);

const getDefaultVariant = (eventConfiguration) =>
  variantTemplate === constants.Templates.BACKEND
    ? 'grey'
    : eventConfiguration.background === theme.colors.primary.white
    ? 'primary'
    : 'secondary';

// const getSortedChildren = (c) => {
//   if (!Array.isArray(c)) return c;
//   const isChildrenArray = Array.isArray(c?.[0]);

//   const sortedChildren = sortBy(isChildrenArray ? c?.[0] : c, [
//     'props.children',
//   ]);

//   return isChildrenArray ? [sortedChildren, c?.[1]] : sortedChildren;
// };

const getSortedChildren = (c, disableSorting) => {
  if (disableSorting || !Array.isArray(c)) return c;
  const isChildrenArray = Array.isArray(c?.[0]);

  const sortedChildren = sortBy(isChildrenArray ? c?.[0] : c, ['props.value']);

  return isChildrenArray ? [sortedChildren, c?.[1]] : sortedChildren;
};

export const AimSelect = ({
  label,
  children,
  displayLabel = true,
  selectVariant,
  formControlWidth,
  formControlStyle,
  labelPlacementLeft,
  selectPlaceholder = '',
  m = 1,
  p,
  hasNoneValue = false,
  customNoneValue = '',
  customNoneLabel = '-',
  hasAllValue = false,
  customAllValue = 'all',
  customAllLabel = 'All',
  addAsterisk = false,
  multiple,
  disableSorting = false,
  ...rest
}) => {
  const [eventConfiguration, setEventConfiguration] = useState({});

  const nextSelectVariant =
    selectVariant || getDefaultVariant(eventConfiguration);

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  const controlStyle = {
    ...formControlStyle,
    ...(labelPlacementLeft && formControlLabelPlacementTop),
  };
  // const sortedChildren = getSortedChildren(children);

  // Dall'interno del tuo componente AimSelect...
  const sortedChildren = getSortedChildren(children, disableSorting);

  const labelStyle = labelPlacementLeft ? formLabelLabelPlacementTop : {};
  return (
    <CustomFormControl
      formControlWidth={formControlWidth}
      m={m}
      p={p}
      style={controlStyle}
    >
      {displayLabel ? (
        <CustomFormLabel variantTemplate={variantTemplate} style={labelStyle}>
          {label}
          {addAsterisk && '*'}
        </CustomFormLabel>
      ) : (
        <></>
      )}
      {(multiple && hasAllValue) ||
      (rest.value !== undefined &&
        rest.value !== '' &&
        rest.value?.length !== 0) ? (
        <NonEmptySelect
          selectVariant={nextSelectVariant}
          multiple={multiple}
          autoWidth={
            variantTemplate === constants.Templates.BACKEND ? true : undefined
          }
          {...rest}
        >
          {hasNoneValue && (
            <AimSelectMenuItem value={customNoneValue}>
              {customNoneLabel}
            </AimSelectMenuItem>
          )}
          {hasAllValue && (
            <AimSelectMenuItem value={customAllValue}>
              {customAllLabel}
            </AimSelectMenuItem>
          )}
          {sortedChildren}
        </NonEmptySelect>
      ) : (
        <EmptySelect
          selectVariant={nextSelectVariant}
          selectPlaceholder={selectPlaceholder}
          multiple={multiple}
          autoWidth={
            variantTemplate === constants.Templates.BACKEND ? true : undefined
          }
          {...rest}
        >
          {hasNoneValue && (
            <AimSelectMenuItem value={customNoneValue}>
              {customNoneLabel}
            </AimSelectMenuItem>
          )}
          {hasAllValue && (
            <AimSelectMenuItem value={customAllValue}>
              {customAllLabel}
            </AimSelectMenuItem>
          )}
          {sortedChildren}
        </EmptySelect>
      )}
    </CustomFormControl>
  );
};

export const AimSelectForm = ({
  control,
  name,
  defaultValue,
  children,
  errors,
  type,
  isRequired,
  label,
  onChange,
  postOnChange,
  options,
  disableSortingByName = false,
  ...rest
}) => (
  <>
    <Controller
      control={control}
      name={name}
      defaultValue={
        defaultValue ? defaultValue : type === 'multiselect' ? [] : undefined
      }
      render={(props) => (
        <AimSelect
          label={label}
          value={
            type === 'multiselect' && !Array.isArray(props.value)
              ? [props.value]
              : props.value
          }
          multiple={type === 'multiselect'}
          onChange={(e) =>
            onChange
              ? props.onChange(onChange(e.target.value))
              : postOnChange
              ? props.onChange(e.target.value) || postOnChange(e.target.value)
              : props.onChange(e.target.value)
          }
          addAsterisk={isRequired}
          disableSortingByName={disableSortingByName}
          {...rest}
        >
          {options
            ? options.map((item) => {
                return (
                  <AimSelectMenuItem key={item.value} value={item.value}>
                    {item.label}
                  </AimSelectMenuItem>
                );
              })
            : children}
        </AimSelect>
      )}
      rules={{
        required: isRequired,
        ...(isRequired ? { validate: (value) => !!value } : {}),
      }}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <AimTypography variant="formError">
            {message || `${label} is required`}
          </AimTypography>
        )}
      />
    )}
  </>
);

export const AimSelectDynamic = ({
  type,
  control,
  errors,
  variant,
  formControlStyle,
  options,
  label,
  placeholder,
  name,
  disabled,
  isRequired,
  ...rest
}) => {
  const opts = typeof options === 'string' ? JSON.parse(options) : options;
  return (
    <AimSelectForm
      control={control}
      name={name}
      errors={errors}
      selectVariant={variant}
      label={label}
      selectPlaceholder={placeholder}
      formControlStyle={formControlStyle}
      disabled={disabled}
      isRequired={isRequired}
      type={type}
      {...rest}
    >
      {opts?.map(({ label, value }) => {
        return (
          <AimSelectMenuItem key={value} value={value}>
            {label}
          </AimSelectMenuItem>
        );
      })}
    </AimSelectForm>
  );
};
