import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { isAfter, endOfDay } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { useParams, useHistory } from 'react-router-dom';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.css';
// import './abstractDetail/Survey.css';

import translation from './shared/translation';

import {
  theme,
  AbstractDetailPage,
  CustomIntl,
  AimSnackbar,
  AimDialog,
  AimSnackbarSeverity,
  AimIconAndTextButton,
  AimTitleAndButtons,
} from '@aim/components';
import { appState, constants, fileHelper } from '@aim/common';

import { updateReview } from './../pages/shared/participationGqlHelper';
import { getAbstractDocument } from './../hooks/useAbstractDocument';

const buildButtonStyle = (variant, color, borderRadius) => {
  switch (variant) {
    case constants.TemplateButtonVariants.OUTLINED:
      return {
        'border-radius': borderRadius,
        background: 'transparent',
        border: '2px solid ' + color,
        color: theme.colors.primary.black,
      };
    case constants.TemplateButtonVariants.CONTAINED:
      return {
        'border-radius': borderRadius,
        background: color,
        color: theme.colors.primary.white,
      };
    case constants.TemplateButtonVariants.TEXT:
      return {
        background: 'transparent !important',
        color: color,
        border: 'none !important',
      };
  }
};

const stylesByEventConfiguration = () => {
  const sdBody = document.querySelector('body');
  sdBody?.style?.setProperty(
    '--primary',
    appState.eventConfiguration.getValue().primaryColor
  );

  const primaryBuildButtonStyle = () =>
    buildButtonStyle(
      appState.eventConfiguration.getValue().primaryButtonVariant,
      appState.eventConfiguration.getValue().primaryColor,
      appState.eventConfiguration.getValue().primaryButtonBorderRadius
    );
  const secondaryBuildButtonStyle = () =>
    buildButtonStyle(
      appState.eventConfiguration.getValue().secondaryButtonVariant,
      appState.eventConfiguration.getValue().secondaryColor,
      appState.eventConfiguration.getValue().secondaryButtonBorderRadius
    );

  // buttons
  const svButton = document.querySelector('.sv-action input');
  const cssStyleObject = secondaryBuildButtonStyle();

  const inlineStyleString = Object.keys(cssStyleObject).reduce(
    (serialized, key) => {
      serialized += `${key}: ${cssStyleObject[key]};`;
      return serialized;
    },
    ''
  );
  if (svButton) svButton.setAttribute('style', inlineStyleString);
};

export default function AbstractDetail({ from }) {
  const intl = CustomIntl(useIntl());
  const i18n = translation.abstractDetail(intl);

  const { eventId, abstractId } = useParams();
  const history = useHistory();

  const [abstractDocument, setAbstractDocument] = useState();
  const [review, setReview] = useState();
  const [model, setModel] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [dialog, setDialog] = useState({ isOpen: false });

  const [abstractFiles, setAbstractFiles] = useState([]);

  // styles from survey documentation
  // --primary: '#ff0000',
  // --background: #ffffff;
  // --background-dim: #f3f3f3;
  // --background-dim-light: #f9f9f9;
  // --primary-foreground: #ffffff;
  // --foreground: #161616;
  // --base-unit: 8px;
  // },

  StylesManager.applyTheme('defaultV2');
  stylesByEventConfiguration();

  // var myCss = {
  //   question: {
  //     content: 'sd-question__content question_content_custom',
  //     titleOnAnswer: 'question-title-answered',
  //   },
  // };

  useEffect(() => {
    fetchData({});
  }, []);

  const fetchData = async () => {
    // Fetching AbstractDocument
    const abstractRes = await getAbstractDocument(abstractId);
    if (
      abstractRes?.abstractService?.blindReviewProcedure ||
      abstractRes?.abstractService?.blindReviewProcedure === null
    ) {
      delete abstractRes.affiliations;
      delete abstractRes.authors;
      delete abstractRes.presenter;
    }
    // Getting Review
    const reviewRes = abstractRes?.reviews?.items.find(
      (review) =>
        review.reviewer.participant.id ===
        appState.user.getValue()?.userAndParticipation?.participation?.id
    );
    if (
      !reviewRes?.reviewResponse &&
      abstractRes?.abstractService?.reviewCriteriaModel
    ) {
      // No response submitted, create an empty model
      setModel(new Model(abstractRes.abstractService.reviewCriteriaModel));
    } else if (abstractRes?.abstractService?.reviewCriteriaModel) {
      // Handling survey in ReadOnly mode
      const survey = new Model(
        JSON.parse(abstractRes.abstractService.reviewCriteriaModel)
      );
      survey.data = JSON.parse(reviewRes.reviewResponse);

      if (
        isAfter(
          new Date(),
          endOfDay(new Date(abstractRes?.abstractService?.reviewTerm))
        )
        //||
        //reviewRes.status === constants.reviewStatus.PROCESSED
      ) {
        survey.mode = 'display';
      }

      setModel(survey);
    } else {
      setModel();
    }

    setAbstractDocument(abstractRes);
    setReview(reviewRes);
  };

  useEffect(() => {
    if (abstractDocument) {
      const files = [];
      if (abstractDocument.aifaDisclosure) {
        files.push(abstractDocument.aifaDisclosure);
      }
      if (abstractDocument.aifaEuropeanCv) {
        files.push(abstractDocument.aifaEuropeanCv);
      }
      setAbstractFiles(files);
    }
  }, [abstractDocument]);

  const onClickBack = () => {
    switch (from) {
      case 'list':
        history.push(`/events/${eventId}/abstracts-reviewers`);
        break;

      default:
        history.push(`/events/${eventId}/abstracts`);
        break;
    }
  };

  const onComplete = async (survey, options) => {
    console.log('SURVEY:: ', JSON.stringify(survey.data));
    //setting score
    let countingScore = 0;
    if (survey.valuesHash) {
      Object.values(survey.valuesHash).map((value) => {
        if (!isNaN(parseFloat(value))) {
          countingScore += parseFloat(value);
        }
      });
    }

    const data = {
      id: review.id,
      reviewAbstractDocumentId: abstractId,
      reviewResponse: JSON.stringify(survey.data),
      reviewReviewerId: review.reviewer.id,
      scores: countingScore,
      status: constants.reviewStatus.PROCESSED,
    };
    try {
      await updateReview(data);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.page.review.messages.saveSuccess,
      });
    } catch (error) {
      console.log('ERR:: ', error);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.review.messages.saveFail,
      });
    }
  };

  const downloadFile = (file) => {
    const dirPath = `events/${eventId}/abstracts/${abstractId}/`;
    if (file.id)
      fileHelper.downloadFromS3({
        dirPath,
        fileData: { ...file, originalName: file.name },
        useLoader: false,
      });
    else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      link.download = file.name;
      link.click();
      link.remove();
    }
  };

  const handleOnDecline = async () => {
    try {
      const data = {
        id: review.id,
        status: constants.reviewStatus.REJECTED,
      };
      await updateReview(data);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.page.review.messages.declineSuccess,
      });
      setReview((prev) => ({
        ...prev,
        status: constants.reviewStatus.REJECTED,
      }));
    } catch (error) {
      console.log('ERR:: ', error);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.review.messages.declineFail,
      });
    }
  };

  return (
    /* TODO maxHeight: 0px  capire se si può fare meglio */
    <Grid container style={{ width: '90%' }}>
      <Grid item xs={12}>
        <div style={{ flex: 1, marginTop: 30 }}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <Tooltip title={i18n.page.back.tooltip}>
              <AimIconAndTextButton
                style={{ padding: 8 }}
                variant="none"
                text={i18n.page.back.label}
                onClick={() => onClickBack()}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
          </div>
          <AimTitleAndButtons title={abstractDocument?.title || ''} />
        </div>
        <AbstractDetailPage
          intl={intl}
          abstractDocument={abstractDocument}
          abstractDocumentId={abstractDocument?.id}
          onDownloadAttachments={downloadFile}
          maxHeight={'unset'}
          hideReviewsFromPage
          isReviewable
          criteriaModel={model?.pages?.[0]?.elements}
          abstractFiles={abstractFiles}
          eventId={eventId}
          setDialog={setDialog}
          review={review}
          printWithoutReviewsFrontOffice
          hideFilesFromPage
        />
      </Grid>
      {review && review.status !== constants.reviewStatus.REJECTED ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            marginBottom: 30,
          }}
        >
          <div style={{ width: '100%' }}>
            {/* css={myCss} */}
            {model ? <Survey model={model} onComplete={onComplete} /> : <></>}
          </div>
        </div>
      ) : null}
      <AimDialog
        title={dialog.title}
        open={dialog.isOpen}
        onClose={() => setDialog({ isOpen: false })}
        onAgree={handleOnDecline}
      >
        {dialog.message}
      </AimDialog>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </Grid>
  );
}
