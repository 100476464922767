import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { AimIconButton } from '@aim/components';
import { appState, utilities } from '@aim/common';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';

import { useBuyOperations } from '../buyOperationsGqlHelper';
import { CartPopover } from './CartPopover';

const CartButton = () => {
  const { eventId } = useParams();
  const [cartItems, setCartItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [surcharge, setSurcharge] = useState();

  const {
    listSurcharges,
    onChangeBySponsorId,
    refreshSponsorCart,
  } = useBuyOperations();

  const participation = appState.user.getValue()?.userAndParticipation
    ?.participation;

  const sponsor = participation?.sponsor; // || participation?.sponsorStaff?.sponsor;

  useEffect(() => {
    const subscription = appState.cart.subscribe(setCartItems);
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    let subscription;
    if (sponsor?.id) {
      subscription = onChangeBySponsorId(
        sponsor.id,
        onChangeBySponsorIdCallback
      );
    }

    return () => subscription?.unsubscribe?.();
  }, [sponsor?.id, onChangeBySponsorIdCallback]);

  useEffect(() => {
    if (eventId) fetchSurcharges(eventId);
  }, [eventId]);
  useEffect(() => {
    onChangeBySponsorIdCallback();
  }, [onChangeBySponsorIdCallback]);

  const onChangeBySponsorIdCallback = useCallback(() => {
    eventId && sponsor?.id && refreshSponsorCart(eventId, sponsor.id);
  }, [eventId, sponsor?.id]);

  const onCartClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const fetchSurcharges = async (eventId) => {
    const fetchedSurcharges = await listSurcharges(eventId);
    const actualSurcharge = fetchedSurcharges
      .map((surcharge) => ({
        id: surcharge.id,
        createdAt: surcharge.createdAt,
        percentage: utilities.decodeDbNumber(surcharge.percentage),
      }))
      .sort(function (a, b) {
        // Sort rollbacks by createdAt from newest to oldest
        return new Date(b.createdAt) - new Date(a.createdAt);
      })[0];
    setSurcharge(actualSurcharge);
  };
  const popoverOpen = Boolean(anchorEl);

  return (
    <>
      <AimIconButton variant="text" onClick={onCartClick}>
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={cartItems.length}
          color="secondary"
        >
          <ShoppingCartIcon fontSize="large" style={{ color: 'black' }} />
        </Badge>
      </AimIconButton>
      <CartPopover
        isOpen={popoverOpen}
        anchorEl={anchorEl}
        handleClose={handlePopoverClose}
        cartItems={cartItems}
        surcharge={surcharge}
      />
    </>
  );
};

export { CartButton };
